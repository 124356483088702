import { StateAndCountryAutocomplete } from "../StateAndCountryAutocomplete";
import { withFormField } from "../withFormField";

/**
 * CountryFormField provides support for a StateAndCountryAutocomplete component to be used in a Form.
 */

export const CountryFormField = withFormField(StateAndCountryAutocomplete, {
  useController: true,
});
