import PropTypes from "prop-types";
import React from "react";

import { useDeviceInfo } from "@swa-ui/browser";
import { Link } from "@swa-ui/core";
import { classNames } from "@swa-ui/string";

import { applyOverrides } from "../../applyOverrides";
import styles from "./LinkedImageItem.module.scss";

export const LinkedImageItem = (props) => {
  const { altText, linkTarget, newWindow, text } = props;
  const { position = [] } = text;
  const { screenSize } = useDeviceInfo();

  return (
    <>
      <div className={styles.container}>
        <Link {...getLinkProps()}>
          {!!position.length && renderText()}
          <img {...getImageProps()} />
        </Link>
      </div>
      {!position.length && renderText()}
    </>
  );

  function renderText() {
    return <span {...getTextProps()}>{text.value}</span>;
  }

  function getTextProps() {
    const { color, fontSize, fontWeight } = text;

    return {
      className: classNames({
        [styles.insideImage]: !!position.length,
        [styles.textBottom]: position.includes("bottom"),
        [styles.textCenter]: position.includes("center"),
        [styles.textLeft]: position.includes("left"),
        [styles.textRight]: position.includes("right"),
        [styles.textTop]: position.includes("top"),
      }),
      style: {
        color,
        fontSize,
        fontWeight,
      },
    };
  }

  function getLinkProps() {
    return {
      className: styles.link,
      href: linkTarget,
      newWindow,
    };
  }

  function getImageProps() {
    const { image } = applyOverrides(props, screenSize);

    return {
      alt: altText,
      className: styles.image,
      src: image,
    };
  }
};

LinkedImageItem.propTypes = {
  /** Placement image alt text. */
  altText: PropTypes.string.isRequired,

  /** Placement image url. */
  image: PropTypes.string,

  /** URL to use as link target. */
  linkTarget: PropTypes.string,

  /** Flag to determine if link should open on a new window. */
  newWindow: PropTypes.bool,

  /** Override for all properties by device size. */
  overrides: PropTypes.object,

  /** Placement text. */
  text: PropTypes.shape({
    /** Color of the text. */
    color: PropTypes.string,

    /** Font size of the text. */
    fontSize: PropTypes.string,

    /** Font weight of the text. */
    fontWeight: PropTypes.string,

    /** Tells the placement where the text should be rendered. */
    position: PropTypes.arrayOf(PropTypes.string),

    /** Text to show in the placements. */
    value: PropTypes.string,
  }),
};
