import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { useDeviceInfo } from "@swa-ui/browser";
import { createQueryParams, useDebounceEffect } from "@swa-ui/browser";
import { Autocomplete, autocompletePropTypes } from "@swa-ui/core";
import { isResponseOk, useFetch } from "@swa-ui/fetch";
import { HighlightSubstring } from "@swa-ui/string";

import styles from "./AutocompleteVacationDestinations.module.scss";

const DELAY_FOR_BROWSER = 16;
const DELAY_FOR_USER_SELECT_INPUT = 200;
const MINIMUM_CHARS_TO_SEARCH = 3;
const ALPHA_PATTERN = /^[a-zA-Z]+$/;
const LOCATION_ENDPOINT = `/api/content-delivery/v1/content-delivery/query/ojt-locations-filter`;
const LOCATION_TYPE = {
  AIRPORT: "airport",
  CITY: "city",
  NEIGHBOURHOOD: "nbhood",
};

/**
 * AutocompleteVacationDestinations facilitates selecting a vacation destination by filter selectable destinations according to user entry
 * matches.
 *
 * Note that the calling application must set up bootstrap so destination data is available. Primarily this requires
 * setDataKeyPrefix be called with application's prefix key.
 */

export const AutocompleteVacationDestinations = React.forwardRef((props, ref) => {
  const {
    className,
    defaultValue,
    disabled,
    error,
    id,
    maxItemsToDisplay,
    minimumListWidth,
    noMatchFoundText,
    onBlur,
    onChange,
    option,
    required,
    searchResultsLimit,
    softEdge,
    value,
  } = props;
  const { isTouchDevice } = useDeviceInfo();
  const [currentList, setCurrentList] = useState([]);
  const [currentValue, setCurrentValue] = useState(value);
  const [searchTerm, setSearchTerm] = useState(value);
  const [location, setLocation] = useState("hidden");
  const states = getBootstrapData("states-data");
  const countries = getBootstrapData("countries-data");
  const destinationCode = useRef("");

  const { get, response } = useFetch();

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useDebounceEffect(
    () => {
      if (searchTerm) {
        (async () => {
          const list = await getList(searchTerm);

          setCurrentList(list);

          if (list.length !== currentList.length && !isTouchDevice) {
            refreshFlyout();
          }
        })();
      }
    },
    [searchTerm],
    100
  );

  return <Autocomplete {...getProps()} />;

  function refreshFlyout() {
    setLocation("hidden");
    setTimeout(() => setLocation("below"), DELAY_FOR_BROWSER);
  }

  function getProps() {
    return {
      "aria-describedby": props["aria-describedby"],
      "aria-required": required,
      className,
      "data-test": props["data-test"],
      defaultValue,
      disabled,
      error,
      id,
      inputMode: "text",
      list: currentList,
      location,
      maxItemsToDisplay,
      minimumListWidth,
      noMatchFoundText,
      onBlur: handleBlur,
      onChange: handleChange,
      onInputChange: handleInputChange,
      option,
      readOnly: false,
      ref,
      required,
      showArrow: false,
      searchResultsLimit,
      softEdge,
      value: currentValue,
    };
  }

  function handleBlur(event) {
    // TODO: PHX-1101 remove setTimeout once the core component is fixed
    setTimeout(() => {
      const destinationDetails = getDestinationDetails();

      onBlur?.({ target: { ...event.target, ...destinationDetails } });
    }, DELAY_FOR_USER_SELECT_INPUT);
  }

  function handleChange(event) {
    destinationCode.current = event.target.value;
    const destinationDetails = getDestinationDetails();

    setCurrentValue(event.target.value);
    onChange?.({ target: { ...event.target, ...destinationDetails } });
  }

  function getDestinationDetails() {
    const destination = currentList?.find((item) => item.value === destinationCode.current);
    let result = { isValid: false, name: "" };

    if (destination) {
      result = {
        isValid: true,
        name: destination.displayValue,
      };
    }

    return result;
  }

  function handleInputChange(event) {
    setSearchTerm(event.target.value);
  }

  async function getList(newValue) {
    let list = [];

    if (newValue?.length >= MINIMUM_CHARS_TO_SEARCH && ALPHA_PATTERN.test(newValue)) {
      newValue = newValue.toUpperCase();
      list = await getDestinationsList(newValue);

      if (!list.length) {
        list = [
          {
            disabled: true,
            label: <i className={styles.noMatchFound}>{noMatchFoundText}</i>,
            value: noMatchFoundText,
          },
        ];
      }
    }

    return list;
  }

  async function getDestinationsList(searchCriteria) {
    const resultList = await getLocationMatch(searchCriteria);

    return resultList.map((resultListItem) => ({
      displayValue: resultListItem.name,
      label: getListItemLabel(resultListItem, searchCriteria),
      value: resultListItem.id,
    }));
  }

  async function getLocationMatch(searchCriteria) {
    const vacationLocations = await getLocationData(searchCriteria);

    return vacationLocations.filter((vacationLocation) =>
      vacationLocation.ajaxString.toUpperCase().includes(searchCriteria.toUpperCase())
    );
  }

  async function getLocationData(searchCriteria) {
    let data = [];
    const params = createQueryParams({ filterStr: searchCriteria, limit: searchResultsLimit });
    const body = await get(`${LOCATION_ENDPOINT}?${params}`);

    if (isResponseOk(response) && body?.success) {
      data = body.results ?? [];
    }

    return data;
  }

  function getListItemLabel(item, searchCriteria) {
    return (
      <div className={styles.listItemContainer}>
        {getListItemLabelTitle(item, searchCriteria)}
        {getListItemLabelDescription(item)}
      </div>
    );
  }

  function getListItemLabelTitle(item, searchCriteria) {
    const iataCodeLabel = item.type === LOCATION_TYPE.AIRPORT && ` (${item.iataCode})`;

    return (
      <div>
        <HighlightSubstring substring={searchCriteria} value={item.name} />
        <HighlightSubstring substring={searchCriteria} value={iataCodeLabel || ""} />
      </div>
    );
  }

  function getListItemLabelDescription(item) {
    const locationTypeLabel =
      item.type === LOCATION_TYPE.NEIGHBOURHOOD ? "NEIGHBOURHOOD" : item.type?.toUpperCase();
    const stateLabel = item.countryCode === "US" && `${getFullStateName(item.stateCode)}`;
    const countryLabel = getFullCountryName(item.countryCode);
    const descriptionLabel = `${locationTypeLabel} - ${stateLabel}, ${countryLabel}`;

    return <div className={styles.listItemDescription}>{descriptionLabel}</div>;
  }

  function getFullStateName(stateCode) {
    return states[0]?.statesData
      ?.find((stateData) => stateData?.code === stateCode)
      ?.stateName?.toUpperCase();
  }

  function getFullCountryName(countryCode) {
    return countries
      ?.find((country) => country.countryCode === countryCode)
      ?.fullName?.toUpperCase();
  }
});

AutocompleteVacationDestinations.propTypes = {
  /** aria-describedby id to element which provides additional accessibility description of input element. */
  "aria-describedby": PropTypes.string,

  /** Additional class for the component to provide a background color. */
  className: PropTypes.string,

  /**
   * Initial values to select in list. Each value should be separated by a pipe character. For example:
   * "option one|option three".
   */
  defaultValue: PropTypes.string,

  /** Indicates Input should apply disabled styling and ignore mouse and key events. */
  disabled: autocompletePropTypes.disabled,

  /**
   * Indicates Dropdown should apply error styling and apply aria-invalid attribute -- in essence, Input will
   * provide error styling.
   */
  error: autocompletePropTypes.error,

  /** ID to be added to input element. */
  id: autocompletePropTypes.id,

  /** Total number of items visible. If more items available than can be displayed, they can be scrolled into view. */
  maxItemsToDisplay: autocompletePropTypes.maxItemsToDisplay,

  /** Width of dropdown. See autocompletePropTypes/DropDown for more info. */
  minimumListWidth: autocompletePropTypes.minimumListWidth,

  /** Required text to display when no match is found. */
  noMatchFoundText: PropTypes.string.isRequired,

  /** Optional event handler to learn when focus is lost from input element. */
  onBlur: autocompletePropTypes.onBlur,

  /**
   * Optional callback to learn of selection changes. The values of the selected items will be returned as pipe-
   * separated strings. See defaultValue prop description for an example.
   */
  onChange: autocompletePropTypes.onChange,

  /** Option button to appear on left side of Input. See Input for more details. */
  option: autocompletePropTypes.option,

  /** Indicates Input should apply aria-required attribute. */
  required: PropTypes.bool,

  /** To limit the number of search results retrieved from search API */
  searchResultsLimit: PropTypes.number,

  /** Indicates if the dropdown list requires soft edges */
  softEdge: PropTypes.bool,

  /** To allow the application to "control" the Input's value, the value prop may be given. */
  value: PropTypes.string,
};

AutocompleteVacationDestinations.defaultProps = {
  minimumListWidth: "large",
  searchResultsLimit: 10,
  softEdge: false,
};
