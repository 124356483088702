import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { useScreenSize } from "@swa-ui/browser";

import { Caption } from "../Caption";
import { keyCodes } from "../defines/keyCodes";
import { FormattedDate } from "../FormattedDate";
import { Heading } from "../Heading";
import { inputPropTypes } from "../Input";
import { Link } from "../Link";
import { Calendar } from "./Calendar";
import { CalendarSecondary } from "./CalendarSecondary";
import styles from "./DateSelector.module.scss";

const DELAY_FOR_BROWSER = 16;
const MIN_WINDOW_WIDTH_FOR_HORIZONTAL_CALENDARS = 600;

/**
 * DateSelector uses FormattedDate and a flyout calendar to facilitate date entry. When DateSelector receives focus,
 * it will display a flyout with a calendar where the traveler can select a date, or the traveler can enter a date using
 * the keyboard using the FormattedDate component.
 */

export const DateSelector = React.forwardRef((props, ref) => {
  const {
    adjunctContent,
    className,
    closeContent,
    dateEnd,
    dateStart,
    daysOfWeekInitials,
    disabled,
    error,
    focusField,
    format,
    id,
    lastBookableDate,
    location,
    name,
    numberCalendars,
    numberDates,
    onChange,
    onSelectEnd,
    onSelectStart,
    required,
    suffixIcon,
    title,
  } = props;
  const [captionState, setCaptionState] = useState("hidden");
  const [currentValue, setCurrentValue] = useState(focusField === "end" ? dateEnd : dateStart);
  const { screenSize } = useScreenSize();

  useEffect(() => {
    setCurrentValue(focusField === "end" ? dateEnd : dateStart);
  }, [dateStart, dateEnd]);

  useEffect(() => {
    if (captionState !== "hidden") {
      setLocation();
    }
  }, [screenSize]);

  return (
    <Caption {...getCaptionProps()}>
      <FormattedDate {...getFormattedDateProps()} />
    </Caption>
  );

  function renderCalendar() {
    return (
      <div className={styles.dateSelector}>
        <Heading className={styles.title} styleLevel={4}>
          <div className={styles.titleContent}>{title}</div>
        </Heading>
        <Calendar {...getCalendarProps()} />
        <div className={styles.adjunct}>{adjunctContent}</div>
      </div>
    );
  }

  function renderSmallCalendar() {
    return (
      <div className={styles.dateSelector}>
        <Heading className={styles.title} styleLevel={4}>
          <div className={styles.titleContent}>{title}</div>
          <Link {...getDoneProps()}>{closeContent}</Link>
        </Heading>
        <div className={styles.adjunctSmall}>{adjunctContent}</div>
        <CalendarSecondary {...getCalendarProps()} />
      </div>
    );
  }

  function getCaptionProps() {
    const horizontalLayout = shouldUseHorizontalLayout();

    return {
      adjoiningContent: horizontalLayout ? renderCalendar() : renderSmallCalendar(),
      alignment: "center",
      bestFit: false,
      className,
      constrainFocus: false,
      location: disabled ? "hidden" : captionState,
      pointerAlignment: "center",
      showPointer: horizontalLayout,
    };
  }

  function getFormattedDateProps() {
    return {
      "aria-describedby": props["aria-describedby"],
      "aria-label": props["aria-label"],
      "aria-required": required,
      disabled,
      error,
      format,
      id,
      inputMode: "none",
      name,
      onBlur: handleBlur,
      onChange: handleInputChange,
      onKeyDown: handleKeyDown,
      onMouseDown: setLocation,
      ref,
      required,
      suffixIcon: suffixIcon || { name: "Calendar" },
      value: currentValue,
    };
  }

  function getCalendarProps() {
    return {
      dateEnd: fillInPlaceholders(focusField === "end" ? currentValue : dateEnd),
      dateStart: fillInPlaceholders(
        focusField === "start" || !focusField ? currentValue : dateStart
      ),
      daysOfWeekInitials,
      focusField,
      lastBookableDate,
      numberCalendars,
      numberDates,
      onClick: handleClick,
      revealed: captionState === "full-screen",
    };
  }

  function getDoneProps() {
    return {
      className: styles.close,
      emphasis: false,
      onClick: () => {
        setCaptionState("hidden");
      },
      size: "fontSize12",
      styleType: "link-inline",
    };
  }

  function handleBlur() {
    let date = appendYear(currentValue);

    if (!isValidDate() || isEmptyDate()) {
      date = "";
    }

    if (focusField === "end") {
      onSelectEnd(date);
    } else {
      onSelectStart(date);
    }

    onChange && onChange({ target: { value: date } });

    setTimeout(() => {
      setCaptionState("hidden");
    }, DELAY_FOR_BROWSER);
  }

  function handleClick(date) {
    processClickSelection(date);
  }

  function handleInputChange(event) {
    if (captionState === "hidden") {
      setLocation();
    }

    setCurrentValue(event.target.value);
  }

  function handleKeyDown(event) {
    if (
      (captionState !== "hidden" && event.key === keyCodes.KEY_ENTER) ||
      event.key === keyCodes.KEY_ESCAPE
    ) {
      event.stopPropagation();
      event.preventDefault();
      processClickSelection(currentValue);
    }
  }

  function isValidDate() {
    const date = new Date(appendYear(currentValue));

    return date instanceof Date && !isNaN(date);
  }

  function isEmptyDate() {
    return currentValue.replace(/\//g, "").length === 0;
  }

  function processClickSelection(currentDate) {
    const dateWithYear = appendYear(currentDate);
    let shouldLeaveOpen;

    if (focusField === "end") {
      shouldLeaveOpen = onSelectEnd(dateWithYear);
    } else {
      shouldLeaveOpen = onSelectStart(dateWithYear);
    }

    if (!shouldLeaveOpen) {
      setCaptionState("hidden");
      setCurrentValue(currentDate);
    }

    onChange?.({ target: { value: dateWithYear } });
  }

  function fillInPlaceholders(value) {
    let day = "";
    let month = "";
    let year = "";

    if (value) {
      const parts = value.split("/");

      if (format === "MM/DD/YYYY") {
        [month, day, year] = parts;

        if (month) {
          if (day.length === 0) {
            day = "01";
          }

          if (year.length < 4) {
            year = getFillInYear(month);
          }
        }
      } else {
        [month, day] = parts;

        if (month) {
          if (day.length === 0) {
            day = "01";
          }

          year = getFillInYear(month);
        }
      }
    }

    return `${month}/${day}/${year}`;
  }

  function getFillInYear(month) {
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    const nextYear = currentYear + 1;

    return parseInt(month) >= currentMonth ? currentYear : nextYear;
  }

  function appendYear(value) {
    let date = value;

    if (format === "MM/DD" && value) {
      const parts = value.split("/");
      const day = parts[1];
      const month = parts[0];
      let year = parts[2];

      if (!year) {
        const today = new Date();
        const todayMonth = today.getMonth() + 1;
        const todayYear = today.getFullYear();

        year = parseInt(month) >= todayMonth ? todayYear : todayYear + 1;
      }

      date = `${month}/${day}/${year}`;
    }

    return date;
  }

  function setLocation() {
    setCaptionState(shouldUseHorizontalLayout() ? location : "full-screen");
  }

  function shouldUseHorizontalLayout() {
    return window.innerWidth > MIN_WINDOW_WIDTH_FOR_HORIZONTAL_CALENDARS;
  }
});

DateSelector.propTypes = {
  /**
   * Content to display at the bottom of the calender - or the top of the calendar on small devices.
   * This content should formatted as desired by the calling component.
   */
  adjunctContent: PropTypes.node,

  /**
   * aria-describedby id to element which provides additional accessibility description of input
   * element.
   */
  "aria-describedby": PropTypes.string,

  /** aria-label text to provide additional accessibility description of input element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Text to be displayed for the close option. This option is only displayed for the small/mobile
   * style.
   */
  closeContent: PropTypes.node.isRequired,

  /**
   * Date shown in FormattedDate input and that will be selected in the flyout. This date should be
   * formatted like 05/19/1982. dateEnd will be the end of a range when a start and end range is
   * needed (like for round trips).
   */
  dateEnd: PropTypes.string,

  /**
   * Date shown in FormattedDate input and that will be selected in the flyout. This date should be
   * formatted like 05/19/1982. If there is only a single date (for one-way trips), then it should
   * be given here.
   */
  dateStart: PropTypes.string,

  /** Initial letters of each day of the week: Sunday, Monday. See default value below. */
  daysOfWeekInitials: PropTypes.string,

  /** Indicates FormattedDate should be disabled. */
  disabled: PropTypes.bool,

  /** Indicates Input should apply error styling and apply aria-invalid attribute. */
  error: PropTypes.bool,

  /** Indicates which date the traveler is selecting. */
  focusField: PropTypes.oneOf(["end", "start"]),

  /** There are three supported formats that can be used. */
  format: PropTypes.oneOf(["MM/DD", "MM/DD/YYYY"]),

  /** ID to be added to input element. */
  id: PropTypes.string,

  /** Last date that can be booked. Should be in this format: MM/DD/YYYY. */
  lastBookableDate: PropTypes.string,

  /** Defines which where the calendar flyout will be displayed. */
  location: PropTypes.oneOf(["above", "below"]),

  /** Unique name in the form to represent this field. */
  name: PropTypes.string,

  /** Number of calendars displayed at one time. This value can be 1 or 2. */
  numberCalendars: PropTypes.number,

  /** One or two dates can be selected so the calendar will allow for start and end dates. */
  numberDates: PropTypes.number,

  /**
   * Event handler to learn when input the start date is given. This call will be made when
   * focusField is "end". The callback function can return true if it is desired to leave the
   * calendar open when an item is clicked.
   */
  onSelectEnd: PropTypes.func,

  /**
   * Event handler to learn when input the end date is given. This call will be made when focusField
   * is "start" or undefined. The callback function can return true if it is desired to leave the
   * calendar open when an item is clicked.
   */
  onSelectStart: PropTypes.func,

  /** Indicates Input should apply aria-required attribute. */
  required: PropTypes.bool,

  /** Icon that can be placed on right side of Input. See Icon for more details. */
  suffixIcon: inputPropTypes.suffixIcon,

  /** Heading to appear at top of flyout. */
  title: PropTypes.string.isRequired,
};

DateSelector.defaultProps = {
  format: "MM/DD/YYYY",
  location: "below",
  numberDates: 1,
};
