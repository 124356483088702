import PropTypes from "prop-types";
import React, { useState } from "react";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { Button, Icon, Input } from "@swa-ui/core";
import { keyCodes } from "@swa-ui/core/defines";
import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import { getEnvironmentTier } from "../DotcomApp";
import styles from "./Search.module.scss";

/**
 * This components provides the content for the GlobalHeader Special Offers and Rapid Rewards flyouts.
 */

const Search = (props) => {
  const { className, onSearch } = props;
  const [value, setValue] = useState("");
  const disabled = value.length <= 0;

  return (
    <div className={classNames(className, styles.search)}>
      <div className={styles.label}>
        <label>{i18n("Search__TEXT")}</label>
        <Input {...getInputProps()} />
      </div>
      <Button {...getButtonProps()}>
        <Icon {...getIconProps()} />
      </Button>
    </div>
  );

  function getInputProps() {
    return {
      onChange: handleInputChange,
      onKeyDown: handleKeyDown,
      styleType: "secondary",
    };
  }

  function getButtonProps() {
    return {
      disabled,
      onClick: handleSearchClick,
      styleType: "primary",
      size: "small",
      value,
    };
  }

  function getIconProps() {
    return {
      disabled,
      name: "Search",
      size: "size30",
    };
  }

  function handleKeyDown(event) {
    event.key === keyCodes.KEY_ENTER && handleSearchClick();
  }

  function handleInputChange(event) {
    setValue(event.target.value);
  }

  function handleSearchClick() {
    const { searchFunnelbackUrl, searchFunnelbackQueryUrl } =
      getBootstrapData("funnelback-environments");
    let environment = getEnvironmentTier();

    if (environment === "local") {
      environment = "dev";
    }

    window.location.href = `${
      searchFunnelbackUrl[environment.toUpperCase()]
    }${searchFunnelbackQueryUrl}${value}`;

    onSearch && onSearch(value);
  }
};

Search.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Callback which informs caller when search button is click, and passes the search criteria as the only argument.
   */
  onSearch: PropTypes.func,
};

export { Search };
