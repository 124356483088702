import PropTypes from "prop-types";
import React, { useState } from "react";

import { FormattedInput } from "../FormattedInput";
import { Input, inputPropTypes } from "../Input";

/**
 * Provides a way to obfuscate traveler input when a field doesn't have focus.
 */

export const MaskedInput = React.forwardRef((props, ref) => {
  const {
    acceptableCharacters,
    autoComplete,
    className,
    defaultValue,
    disabled,
    error,
    formatTemplate,
    id,
    maskCharacter,
    maxLength,
    name,
    onBlur,
    onChange,
    onClick,
    onFocus,
    placeholder,
    readOnly,
    required,
    showAriaLabel,
    showLabel,
    size,
    styleType,
    suffixIcon,
    svg,
    unmaskedLength,
    value,
  } = props;
  const [currentValue, setCurrentValue] = useState(defaultValue ?? "");
  const [fieldHasFocus, setFieldHasFocus] = useState(false);

  return formatTemplate ? (
    <FormattedInput {...getProps()} formatTemplate={formatTemplate} />
  ) : (
    <Input {...getProps()} />
  );

  function getProps() {
    return {
      acceptableCharacters,
      "aria-describedby": props["aria-describedby"],
      "aria-disabled": disabled,
      "aria-label": props["aria-label"],
      "aria-labelledby": props["aria-labelledby"],
      "aria-required": required,
      autoComplete,
      className,
      "data-test": props["data-test"],
      disabled,
      error,
      id,
      maxLength,
      name,
      onBlur: handleBlur,
      onChange: handleChange,
      onFocus: handleFocus,
      option: getOptionProps(),
      placeholder,
      readOnly,
      ref,
      required,
      size,
      styleType,
      suffixIcon,
      svg,
      type: "text",
      value: getCurrentValue(),
    };
  }

  function getOptionProps() {
    return showLabel
      ? {
          "aria-label": showAriaLabel,
          onClick,
          text: fieldHasFocus || !currentValue?.length ? "" : showLabel,
        }
      : undefined;
  }

  function handleBlur(event) {
    setFieldHasFocus(false);
    onBlur && onBlur(event);
  }

  function handleChange(event) {
    setCurrentValue(event.target.value);
    onChange?.(event);
  }

  function handleFocus(event) {
    setFieldHasFocus(true);
    onFocus && onFocus(event);
  }

  function getCurrentValue() {
    const inputValue = value ?? currentValue;

    return fieldHasFocus || error ? inputValue : getMaskedValue(inputValue);
  }

  function getMaskedValue(inputValue) {
    const maskedLength = inputValue.length - getUnmaskedLength(inputValue);
    let result = inputValue;

    if (maskedLength > 0) {
      const maskingText = maskCharacter.repeat(maskedLength);
      const suffix = inputValue.substring(maskedLength);

      result = `${maskingText}${suffix}`;
    }

    return result;
  }

  function getUnmaskedLength({ length: inputLength }) {
    let length = unmaskedLength === "none" ? 0 : unmaskedLength;

    if (unmaskedLength === "all") {
      length = inputLength;
    }

    return length;
  }
});

export const maskedInputPropTypes = {
  /**
   * List of keys that will be accepted as valid input. This array should be single characters strings
   * like: '0123456789'. For the sake of simplicity, there is no logic to determine if "invalid"
   * characters are pasted into the field.
   */
  acceptableCharacters: PropTypes.string,

  /**
   * aria-describedby id to element which provides additional accessibility description of input
   * element.
   */
  "aria-describedby": PropTypes.string,

  /** aria-label text to provide additional accessibility description of input element. */
  "aria-label": PropTypes.string,

  /**
   * aria-labelledby id to element which provides additional accessibility description of input
   * element.
   */
  "aria-labelledby": PropTypes.string,

  /** HTML input element attribute for autoComplete. */
  autoComplete: PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Default value to be added to input element */
  defaultValue: PropTypes.string,

  /** Indicates Input should apply disabled styling and ignore mouse and key events. */
  disabled: PropTypes.bool,

  /** Indicates Input should apply error styling and apply aria-invalid attribute. */
  error: PropTypes.bool,

  /**
   * String with "stationary" and "replacement" characters to automatically format user input. See
   * FormattedInput for more information.
   */
  formatTemplate: PropTypes.string,

  /** ID to be added to input element. */
  id: PropTypes.string,

  /** Character to use as a mask to obfuscate field input when blurred. */
  maskCharacter: PropTypes.string,

  /** Maximum number of characters allowed in input element. */
  maxLength: PropTypes.number,

  /** Name to be added to input element. */
  name: PropTypes.string,

  /** Optional event handler to learn when focus is lost from input element. */
  onBlur: PropTypes.func,

  /** Optional event handler to learn when input element's content is changed. */
  onChange: PropTypes.func,

  /** Optional event handler to learn when focus is given to input element. */
  onFocus: PropTypes.func,

  /** Optional value to show when field is empty. */
  placeholder: PropTypes.string,

  /** Indicates Input should be immutable and ignore mouse and key events. */
  readOnly: PropTypes.bool,

  /** Indicates Input should apply aria-required attribute. */
  required: PropTypes.bool,

  /** aria-label text to provide additional accessibility description of show label button. */
  showAriaLabel: PropTypes.string,

  /** Label to display on the Input's option button to "unmask" the traveler's entry. */
  showLabel: PropTypes.string,

  /** Indicates the height and padding of the input element. */
  size: inputPropTypes.size,

  /** Indicates the style of the input element. */
  styleType: inputPropTypes.styleType,

  /** Icon that can be placed on right side of Input. See Input and Icon for more details. */
  suffixIcon: inputPropTypes.suffixIcon,

  /** SVG that can be placed on right side of Input. See Input and Svg for more details. */
  svg: inputPropTypes.svg,

  /**
   *  Number of characters to show at end of input value, or if "none" is given, the entire input
   *  value will be masked, which is the same as zero. "all" is the same as the length of the field.
   */
  unmaskedLength: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(["all", "none"])]),

  /** Value to be maintained by caller. This will define MaskedInput as a controlled component. */
  value: PropTypes.string,
};

MaskedInput.propTypes = maskedInputPropTypes;

MaskedInput.defaultProps = {
  autoComplete: "off",
  maskCharacter: "X",
  unmaskedLength: "none",
};
