import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { classNames } from "@swa-ui/string";

import { Digit } from "../Digit";
import styles from "./Timer.module.scss";

const DEFAULT_FONT_SIZE = 30;
const FONT_SIZES = ["large", "medium", "small", "xlarge", "xsmall"];
const MINUTE = 60;
const HOUR = MINUTE * 60;
const MAX_DURATION = HOUR * 24;
const SECOND = 1000;
const STEP_INTERVAL = 250;
const TEN_HOURS = HOUR * 10;
const TEN_MINUTES = MINUTE * 10;

/**
 * Timer shows an animated timer which counts down to zero.
 */

export const Timer = (props) => {
  const { className, dangerThreshold, fontSize, onDangerThreshold, onTimerEnd, timerDuration } =
    props;
  const [secondsRemaining, setSecondsRemaining] = useState(getMaxDuration());
  const dangerFired = useRef(false);
  const expirationTime = useRef(getExpirationTime());

  useEffect(() => {
    let timer;

    if (secondsRemaining) {
      timer = setInterval(() => {
        const seconds = getSecondsRemaining();

        setSecondsRemaining(seconds);

        if (seconds === dangerThreshold - 1 && onDangerThreshold && !dangerFired.current) {
          dangerFired.current = true;
          onDangerThreshold();
        }
      }, STEP_INTERVAL);
    } else {
      onTimerEnd && onTimerEnd();
    }

    return () => clearInterval(timer);
  }, [secondsRemaining]);

  return (
    <div className={getClass()} style={getStyle()}>
      {renderHours()}
      {renderMinutes()}
      <div className={styles.separator}>:</div>
      {renderSeconds()}
    </div>
  );

  function renderHours() {
    return (
      <>
        {isDurationAtLeast(HOUR) && (
          <>
            {isDurationAtLeast(TEN_HOURS) && <Digit {...getDigitProps(getHours() / 10)} />}
            <Digit {...getDigitProps(getHours() % 10)} />
            <div className={styles.separator}>:</div>
          </>
        )}
      </>
    );
  }

  function renderMinutes() {
    return (
      <>
        {isDurationAtLeast(TEN_MINUTES) && <Digit {...getDigitProps(getMinutes() / 10, 5)} />}
        <Digit {...getDigitProps(getMinutes() % 10)} />
      </>
    );
  }

  function renderSeconds() {
    return (
      <>
        <Digit {...getDigitProps(getSeconds() / 10, 5)} />
        <Digit {...getDigitProps(getSeconds() % 10)} />
      </>
    );
  }

  function getDigitProps(number, maxNumber) {
    return {
      fontSize: getFontSize(),
      maxNumber,
      number: Math.floor(number),
    };
  }

  function getClass() {
    return classNames(className, styles.timer, {
      [styles.danger]: secondsRemaining < dangerThreshold,
    });
  }

  function getStyle() {
    return { "--font-size": getFontSize() };
  }

  function getFontSize() {
    return FONT_SIZES.indexOf(fontSize) === -1
      ? fontSize
      : parseInt(getCustomValue("number-selector-font-size", DEFAULT_FONT_SIZE));
  }

  function getCustomValue(propertyName, defaultValue) {
    return (
      window.getComputedStyle(document.body).getPropertyValue(`--${propertyName}`) || defaultValue
    );
  }

  function getExpirationTime() {
    const timeToNearestSecond = new Date(getTimeToNearestSecond());

    return new Date(
      timeToNearestSecond.setSeconds(timeToNearestSecond.getSeconds() + getMaxDuration())
    ).getTime();
  }

  function getMaxDuration() {
    return Math.min(timerDuration, MAX_DURATION);
  }

  function getSecondsRemaining() {
    return (expirationTime.current - getTimeToNearestSecond()) / SECOND;
  }

  function getTimeToNearestSecond() {
    const date = new Date();

    date.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), 0);

    return date.getTime();
  }

  function isDurationAtLeast(duration) {
    return timerDuration >= duration;
  }

  function getHours() {
    return secondsRemaining / HOUR;
  }

  function getMinutes() {
    return (secondsRemaining - parseInt(`${getHours()}`) * HOUR) / MINUTE;
  }

  function getSeconds() {
    return secondsRemaining % MINUTE;
  }
};

Timer.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Number of seconds before timer expires when "danger" styling will be displayed to alert the
   * traveler timer is about to expire.
   */
  dangerThreshold: PropTypes.number,

  /** Font size for timer values. */
  fontSize: PropTypes.oneOfType([PropTypes.oneOf(FONT_SIZES), PropTypes.number]),

  /** Function called when the timer is nearly expired. */
  onDangerThreshold: PropTypes.func,

  /** Function called when the timer is complete. */
  onTimerEnd: PropTypes.func,

  /** Duration of count down in seconds. */
  timerDuration: PropTypes.number.isRequired,
};

Timer.defaultProps = {
  dangerThreshold: 30,
  fontSize: "medium",
};
