import React, { useContext, useEffect, useState } from "react";

import { Card, Checkbox, CloseButton, Input, Transform } from "@swa-ui/core";
import i18n from "@swa-ui/locale";

import { ContextVisualContext } from "../ContextVisualProvider";
import { ContextVisual } from "./ContextVisual";
import styles from "./ContextVisualizer.module.scss";

/**
 * ContextVisualizer reveals a list of all of an application's context values in a readable format.
 * This tools is hidden from users of applications until the keys Shift + F7 are pressed.
 */

export const ContextVisualizer = () => {
  const [revealed, setRevealed] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [filterKeys, setFilterKeys] = useState("");
  const { getContexts } = useContext(ContextVisualContext) ?? {};
  const contexts = getContexts?.() ?? [];
  const sortedContexts = contexts.sort((contextA, contextB) =>
    contextA.displayName > contextB.displayName ? 1 : -1
  );
  const filteredContexts = sortedContexts.filter((context) => {
    const normalizedFilterKeys = filterKeys.toLowerCase();
    const normalizedDisplayName = context.displayName.toLowerCase();

    return normalizedFilterKeys.length === 0 || normalizedDisplayName.includes(filterKeys);
  });

  useEffect(() => {
    window.addEventListener("keydown", handleOnShiftF7); // NOSONAR

    return () => {
      window.removeEventListener("keydown", handleOnShiftF7); // NOSONAR
    };
  });

  return (
    <Transform {...getTransformProps()}>
      <Card {...getCardProps()}>
        <div className={styles.options}>
          <Input {...getFilterProps()} />
          <Checkbox
            label="Show All"
            name="ContextVisualizerShowAll"
            onChange={handleCheckboxChange}
          />
        </div>
        {filteredContexts.map((context, index) => (
          <ContextVisual key={index} context={context} revealed={expandAll} />
        ))}
      </Card>
    </Transform>
  );

  function getCardProps() {
    return {
      animate: false,
      className: styles.card,
      description: revealed && <CloseButton {...getCloseButtonProps()} />,
      headingProps: {
        children: "Context Helper",
        styleLevel: 4,
      },
    };
  }

  function getCloseButtonProps() {
    return {
      "aria-label": i18n("ContextVisualizer__CLOSE_BUTTON_ARIA_LABEL"),
      onClick: () => setRevealed(false),
    };
  }

  function getFilterProps() {
    return {
      className: styles.filter,
      onChange: (event) => {
        setFilterKeys(event.target.value);
      },
      placeholder: "Search",
      size: "small",
    };
  }

  function getTransformProps() {
    return {
      className: styles.container,
      transformations: [
        {
          action: "width",
          amount: revealed ? "500px" : "0px",
        },
      ],
    };
  }

  function handleCheckboxChange() {
    setExpandAll(!expandAll);
  }

  function handleOnShiftF7(event) {
    if (event.keyCode === 118 && event.shiftKey) {
      setRevealed(!revealed);
    }
  }
};
