import PropTypes from "prop-types";
import React, { createRef, useEffect, useRef, useState } from "react";

import { classNames } from "@swa-ui/string";

import { Button, buttonPropTypes } from "../Button";
import { keyCodes } from "../defines/keyCodes";
import { HorizontalGestureScroller } from "../HorizontalGestureScroller";
import styles from "./ButtonGroup.module.scss";

const FINISH_SCROLL_BEFORE_FOCUS = 250;

/**
 * ButtonGroup presents a horizontal list of buttons where only one can be selected at a time. When
 * all given buttons cannot be shown, the buttons can be scrolled with a mouse wheel or touchscreen
 * gesture.
 *
 * See ButtonGroup.technotes.mdx for implementation discussion.
 */

export const ButtonGroup = (props) => {
  const {
    animate,
    buttonList,
    className,
    defaultSelectedIndex,
    descriptionLeft,
    descriptionRight,
    enableKeyboardSupport,
    equalSize,
    focusOnSelectionChange,
    moreContentIndicator,
    onClick,
    renderToken,
    selectedIndex,
    showSelected,
    size,
    spaceBetween,
    styleType,
  } = props;
  const [, setForceUpdate] = useState(9999);
  const [indexOfFocusedTab, setIndexOfFocusedTab] = useState(-1);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(
    selectedIndex || defaultSelectedIndex
  );
  const buttonRefs = useRef(initializeRefsToMeasureButtons());
  const container = useRef();
  const scrollPosition = useRef(0);

  useEffect(() => {
    selectedButtonIndex !== -1 && ensureButtonIsFullyVisible(selectedButtonIndex);
  }, []);

  useEffect(() => {
    if (selectedIndex && selectedIndex !== -1) {
      setSelectedButtonIndex(selectedIndex);
      ensureButtonIsFullyVisible(selectedIndex);
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (selectedIndex !== undefined) {
      setSelectedButtonIndex(selectedIndex);

      if (focusOnSelectionChange && buttonRefs?.current?.[selectedIndex]?.current) {
        buttonRefs.current[selectedIndex].current.focus();
      }
    } else {
      setSelectedButtonIndex(defaultSelectedIndex);
    }
  }, [selectedIndex, renderToken]);

  return buttonList ? (
    <div {...getProps()}>
      {descriptionLeft}
      <div {...getFirstGuardrailProps()} />
      <HorizontalGestureScroller {...getScrollerProps()}>
        {buttonList.map(renderButton)}
      </HorizontalGestureScroller>
      <div {...getLastGuardrailProps()} />
      {descriptionRight}
    </div>
  ) : null;

  function renderButton(buttonInfo, index) {
    return <Button {...getButtonProps(buttonInfo, index)}>{buttonInfo.label}</Button>;
  }

  function getProps() {
    return {
      className: classNames(className, styles.buttonGroup),
      onKeyDown: enableKeyboardSupport ? handleKeyDown : handleTab,
    };
  }

  function getFirstGuardrailProps() {
    return {
      onFocus: handleFocusFirstGuardrail,
      tabIndex: enableKeyboardSupport || indexOfFocusedTab === 0 ? "-1" : "0",
    };
  }

  function getLastGuardrailProps() {
    return {
      onFocus: handleFocusLastGuardrail,
      tabIndex: enableKeyboardSupport || indexOfFocusedTab === getLastButtonIndex() ? "-1" : "0",
    };
  }

  function getButtonProps(buttonInfo, index) {
    const buttonInfoProps = buttonInfo.props ?? {};
    const isButtonFocused = selectedButtonIndex === index;

    return {
      ...buttonInfoProps,
      animate: moreContentIndicator ? false : animate,
      "aria-expanded": enableKeyboardSupport && isButtonFocused,
      className: getButtonClass(buttonInfoProps, index),
      key: index,
      onBlur: handleBlur,
      onClick: () => handleClick(index),
      onFocus: () => (enableKeyboardSupport ? undefined : handleFocus(index)),
      ref: buttonRefs.current[index],
      selected: showSelected && index === selectedButtonIndex,
      size,
      styleType: buttonInfoProps.styleType || styleType,
      tabIndex: enableKeyboardSupport && isButtonFocused ? 0 : -1,
    };
  }

  function getScrollerProps() {
    const isButtonStyleTypeTab = buttonList.some(
      (buttonInfoProps) => buttonInfoProps.styleType === "tab"
    );

    return {
      className: classNames({ [styles.equalSizeScroller]: equalSize }),
      fadeEdge: !equalSize,
      moreContentIndicator,
      onMove: handleMove,
      ref: container,
      role: isButtonStyleTypeTab || styleType === "tab" ? "tablist" : undefined,
      scrollPosition: scrollPosition.current,
    };
  }

  function getButtonClass(buttonInfoProps, index) {
    const lastButton = index === buttonList.length - 1;

    return classNames(buttonInfoProps.className, {
      [styles.option]: equalSize,
      [styles.spaceLarge]: spaceBetween === "large" && !lastButton,
      [styles.spaceSmall]: spaceBetween === "small" && !lastButton,
    });
  }

  function handleKeyDown(event) {
    const { key } = event;
    const buttonCount = buttonList.length;
    let newIndex;

    if (key === keyCodes.KEY_END) {
      newIndex = buttonCount - 1;
    } else if (key === keyCodes.KEY_HOME) {
      newIndex = 0;
    } else if (key === keyCodes.KEY_LEFT) {
      newIndex = (selectedButtonIndex + buttonCount - 1) % buttonCount;
    } else if (key === keyCodes.KEY_RIGHT) {
      newIndex = (selectedButtonIndex + 1) % buttonCount;
    }

    if (newIndex !== undefined) {
      event.preventDefault();
      event.stopPropagation();
      setFocus(newIndex);
      updateButtonState(newIndex);
    }
  }

  function handleTab(event) {
    const { key, shiftKey } = event;

    if (key === keyCodes.KEY_TAB) {
      const currentIndexFocusTab = getIndexOfFocusedTab();
      const newIndexFocusTab = currentIndexFocusTab + (shiftKey ? -1 : 1);

      if (newIndexFocusTab >= 0 && newIndexFocusTab <= getLastButtonIndex()) {
        ensureButtonIsFullyVisible(newIndexFocusTab);
        setFocus(newIndexFocusTab);
      }
    }
  }

  function handleBlur() {
    setIndexOfFocusedTab(-2);
  }

  function handleClick(index) {
    updateButtonState(index);
  }

  function handleFocus(index) {
    setIndexOfFocusedTab(index);
  }

  function handleFocusFirstGuardrail(event) {
    if (getIndexOfPreviousFocusedTab(event) === -1) {
      ensureButtonIsFullyVisible(0);
      setFocus(0);
    }
  }

  function handleFocusLastGuardrail(event) {
    const lastButtonIndex = getLastButtonIndex();

    if (getIndexOfPreviousFocusedTab(event) === -1) {
      ensureButtonIsFullyVisible(lastButtonIndex);
      setFocus(lastButtonIndex);
    }
  }

  function setFocus(index) {
    setTimeout(() => {
      buttonRefs.current[index]?.current?.focus();
    }, FINISH_SCROLL_BEFORE_FOCUS);
  }

  function getLastButtonIndex() {
    return buttonRefs.current.length - 1;
  }

  function handleMove(scrollOffset) {
    scrollPosition.current = scrollOffset;
    setForceUpdate(scrollPosition.current);
  }

  function initializeRefsToMeasureButtons() {
    return buttonList?.map(() => createRef());
  }

  function getIndexOfPreviousFocusedTab(event) {
    return buttonRefs.current.findIndex((button) => button.current === event.relatedTarget);
  }

  function getIndexOfFocusedTab() {
    return buttonRefs.current.findIndex((button) => button.current === document.activeElement);
  }

  function updateButtonState(index) {
    const buttonInfoProps = buttonList[index].props ?? {};
    const { onClick: buttonOnClick } = buttonInfoProps;

    ensureButtonIsFullyVisible(index);
    setSelectedButtonIndex(index);
    buttonOnClick && buttonOnClick();
    onClick && onClick(index);
  }

  function ensureButtonIsFullyVisible(index) {
    const buttonClientRect = buttonRefs.current[index].current.getBoundingClientRect();
    const containerClientRect = container.current.getBoundingClientRect();
    const offsetOfButton = buttonClientRect.x - containerClientRect.x;
    const rightEdgeOfButton = Math.round(buttonClientRect.x + buttonClientRect.width);
    const rightEdgeOfContainer = Math.round(containerClientRect.x + containerClientRect.width);

    if (offsetOfButton < 0) {
      scrollPosition.current = Math.ceil(
        containerClientRect.x - buttonClientRect.x + scrollPosition.current
      );

      setForceUpdate(scrollPosition.current);
    } else if (rightEdgeOfButton > rightEdgeOfContainer) {
      scrollPosition.current = Math.floor(
        scrollPosition.current - (rightEdgeOfButton - rightEdgeOfContainer)
      );

      setForceUpdate(scrollPosition.current);
    }
  }
};

export const buttonGroupPropTypes = {
  /** Button content changes will animate, using TransitionBlock, unless turned off. */
  animate: PropTypes.bool,

  /** Buttons to display. */
  buttonList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      props: PropTypes.shape(buttonPropTypes),
    })
  ),

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Initial index of selected button for "uncontrolled" components. */
  defaultSelectedIndex: PropTypes.number,

  /** Option JSX content to be displayed to the left of the button group. */
  descriptionLeft: PropTypes.node,

  /** Option JSX content to be displayed to the right of the button group. */
  descriptionRight: PropTypes.node,

  /**
   * Enables keyboard navigation. With this enabled, arrows/page keys will move highlight between
   * buttons. If not enabled, tab will allow focus to move between buttons.
   */
  enableKeyboardSupport: PropTypes.bool,

  /** Setting this prop to true will set all buttons to the same width. */
  equalSize: PropTypes.bool,

  /** When set, ButtonGroup will always set focus on a newly selected item. */
  focusOnSelectionChange: PropTypes.bool,

  /** Denotes if a "faded edge" will be rendered to indicate more content is scrollable. */
  moreContentIndicator: PropTypes.bool,

  /**
   * Callback function to learn when a button is clicked. The only parameter returned is the index
   * of the selected button.
   */
  onClick: PropTypes.func,

  /**
   * renderToken provides a way to force a render. Typically ButtonGroup will re-render when
   * selectedIndex changes, but if renderToken changes since the last render, ButtonGroup will
   * re-render and set focus to the selected item (provided that focusOnSelectionChange is set)
   * even if selectedIndex doesn't change.
   */
  renderToken: PropTypes.string,

  /**
   * Index of selected button for "controlled" components. If set to negative one, no selected state
   * will be shown.
   */
  selectedIndex: PropTypes.number,

  /**
   * The currently selected button can show, or not show, its selected state. When ButtonGroup is
   * used to represent a radio button group, the selected button is typically denoted, but when
   * ButtonGroup is used as a list of buttons/links, showing the current selection may not desired
   * and can be turned off.
   */
  showSelected: PropTypes.bool,

  /**
   * Button size can be set to change the size and width of each button. See button component for
   * more info.
   */
  size: buttonPropTypes.size,

  /** Distance between buttons. */
  spaceBetween: PropTypes.oneOf(["large", "none", "small"]),

  /** Button style to be used for all buttons in the buttonList. */
  styleType: buttonPropTypes.styleType,
};

ButtonGroup.propTypes = buttonGroupPropTypes;

ButtonGroup.defaultProps = {
  defaultSelectedIndex: 0,
  equalSize: false,
  focusOnSelectionChange: true,
  moreContentIndicator: true,
  showSelected: true,
  size: "large",
  spaceBetween: "none",
  styleType: "capsule",
};
