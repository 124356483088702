import { getItem, setItem } from "@swa-ui/persistence";

const SWAV_BOOKING_DATA_KEY = "swavBookingData";
const TTL = 30;

export const getPersistedVacationsBookingData = () => getItem(SWAV_BOOKING_DATA_KEY, {});

export const setPersistedVacationsBookingData = (swavBookingData) => {
  setItem(SWAV_BOOKING_DATA_KEY, swavBookingData, {
    timeToLiveInMinutes: TTL,
  });
};
