import PropTypes from "prop-types";
import React, { useContext } from "react";

import { Dialog, Layout, useDialog } from "@swa-ui/core";

import { RESTRICTION_NONE, RESTRICTION_OTP_REQUIRED } from "../../defines/restrictions";
import { MfaContext } from "../../MfaProvider";
import { MfaAuthorizer } from "../MfaAuthorizer";
import styles from "./MfaProtected.module.scss";

/**
 * MfaProtected will MFA verify the user before rendering the children.
 */
export const MfaProtected = (props) => {
  const { children, onAuthorized, onClose, restoreFocus, type } = props;
  const { mfaAuthenticated, mfaRestrictions = {} } = useContext(MfaContext);

  const restriction = mfaRestrictions?.[type] ?? RESTRICTION_NONE;
  const { hideDialog, isDialogVisible } = useDialog(
    "center",
    shouldShowDialog() ? "above" : "hidden",
    undefined,
    restoreFocus
  );

  return (
    <div>
      {isDialogVisible && (
        <Dialog {...getDialogProps()}>
          <Layout top="none" left="medium" right="medium">
            <MfaAuthorizer onAuthorized={handleAuthorized} />
          </Layout>
        </Dialog>
      )}
      {!isDialogVisible && children}
    </div>
  );

  function shouldShowDialog() {
    return !mfaAuthenticated && restriction === RESTRICTION_OTP_REQUIRED;
  }

  function getDialogProps() {
    return {
      className: styles.dialog,
      onClose: handleClose,
      portal: true,
      revealed: isDialogVisible,
      showClose: true,
      width: "small",
    };
  }

  function handleClose() {
    hideDialog();
    onClose?.();
  }

  function handleAuthorized() {
    onAuthorized?.();
    hideDialog();
  }
};

MfaProtected.propTypes = {
  /** Content that will be rendered. */
  children: PropTypes.node.isRequired,
};
