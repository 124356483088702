/**
 * focus provides some utility methods to set focus on an element inside a container.
 */

/**
 * focusFirstElement will set focus to the first focusable element in a container.
 * @param scopeSelector css selector of the container. This must resolve to a unique result or first matching result
 * @param doc           by default this is the global document object, but can be overridden to support iFrames
 * @return              undefined
 */

export const focusFirstElement = (scopeSelector, doc = document) => {
  const focusableItemsList = getFocusableItems(scopeSelector, doc);

  if (focusableItemsList.length && !document.activeElement === focusableItemsList[0]) {
    focusableItemsList[0].focus();
  }
};

/**
 * focusLastElement will set focus to the last focusable element in a container.
 * @param scopeSelector css selector of the container. This must resolve to a unique result or first matching result.
 * @param doc           By default this is the global document object, but can be overridden to support iFrames.
 * @return              undefined.
 */

export const focusLastElement = (scopeSelector, doc = document) => {
  const focusableItemsList = getFocusableItems(scopeSelector, doc);

  if (
    focusableItemsList.length &&
    !document.activeElement === focusableItemsList[focusableItemsList.length - 1]
  ) {
    focusableItemsList[focusableItemsList.length - 1].focus();
  }
};

/**
 * getFocusableItems returns the number of focusable items in the "selection" area.
 * @param scopeSelector css selector of the container. This must resolve to a unique result or first matching result.
 * @param doc           By default this is the global document object, but can be overridden to support iFrames.
 * @return              count of focusable items.
 */

export function getFocusableItems(scopeSelector, doc = document) {
  const scopeElement = doc.querySelector(scopeSelector, doc);
  let focusableItemsList = [];

  if (!isAnyAncestorHidden(scopeElement)) {
    focusableItemsList = getAllFocusableItems(scopeSelector);
    focusableItemsList = filterHiddenInputs(focusableItemsList);
    focusableItemsList = filterItemsRemovedFromTabOrder(focusableItemsList);
    focusableItemsList = filterHiddenItems(focusableItemsList);
    focusableItemsList = filterAriaHiddenItems(focusableItemsList);
    focusableItemsList = filterDisabledItems(focusableItemsList);
  }

  return focusableItemsList;
}

/**
 * getFocusElement returns the DOM element that current has focus.
 * @param doc           By default this is the global document object, but can be overridden to support iFrames.
 * @return              reference to DOM element.
 */

export function getFocusElement(doc = document) {
  return doc.activeElement;
}

/**
 * setFocusElement will set focus to the given DOM element.
 * @param element       DOM element to receive focus
 * @return              undefined.
 */

export function setFocusElement(element) {
  return element?.focus?.();
}

function getAllFocusableItems(scopeSelector) {
  const selectors = `${scopeSelector} a[href], ${scopeSelector} button, ${scopeSelector} [tabIndex="0"], 
        ${scopeSelector} input, ${scopeSelector} select, ${scopeSelector} textarea`;

  return Array.from(document.querySelectorAll(selectors));
}

function filterHiddenInputs(itemsList) {
  return itemsList.filter((item) => item.getAttribute("type") !== "hidden");
}

function filterItemsRemovedFromTabOrder(itemsList) {
  return itemsList.filter((item) => item.tabIndex !== -1);
}

function filterHiddenItems(itemsList) {
  return itemsList.filter((item) => !isAnyAncestorHidden(item));
}

function isAnyAncestorHidden(workingElement) {
  const ancestors = getAncestors(workingElement);

  return ancestors.some(
    (ancestor) =>
      window.getComputedStyle(ancestor).display === "none" ||
      window.getComputedStyle(ancestor).visibility === "hidden"
  );
}

function getAncestors(element) {
  const ancestors = [];
  let currentElement = element;

  while (currentElement && currentElement !== document) {
    ancestors.push(currentElement);
    currentElement = currentElement.parentNode;
  }

  return ancestors;
}

function filterAriaHiddenItems(itemsList) {
  return itemsList.filter((item) => item.getAttribute("aria-hidden") !== "true");
}

function filterDisabledItems(itemsList) {
  return itemsList.filter((item) => !item.disabled);
}
