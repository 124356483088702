import * as yup from "yup";

export const SCHEMA_FIELD_NAMES = {
  PHONE_COUNTRY_CODE: "countryCode",
  PHONE_NUMBER: "phoneNumber",
};

const REGEX = {
  domesticPhone: /^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/,
  internationalPhone: /^[0-9]{0,10}$/,
};

const phoneSchema = (label = "") =>
  yup.object({
    [SCHEMA_FIELD_NAMES.PHONE_COUNTRY_CODE]: yup.number().required(),
    [SCHEMA_FIELD_NAMES.PHONE_NUMBER]: yup
      .string()
      .when(SCHEMA_FIELD_NAMES.PHONE_COUNTRY_CODE, {
        is: (value) => value === 1,
        otherwise: yup.string().trim().min(4).max(12).matches(REGEX.internationalPhone).required(),
        then: yup.string().trim().matches(REGEX.domesticPhone).required(),
      })
      .trim()
      .label(label),
  });

export const getPhoneFormFieldSchema = (label) => ({ phones: yup.array().of(phoneSchema(label)) });
