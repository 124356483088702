export const gradientConfig = {
  "bold-blue": [
    {
      designToken: "--cmp-core-color-gradients-bold-blue-stop-1",
      percentage: 0,
    },
    {
      designToken: "--cmp-core-color-gradients-bold-blue-stop-2",
      percentage: 40,
    },
    {
      designToken: "--cmp-core-color-gradients-bold-blue-stop-3",
      percentage: 100,
    },
  ],
  "summit-silver": [
    {
      designToken: "--cmp-core-color-gradients-summit-silver-stop-1",
      percentage: 0,
    },
    {
      designToken: "--cmp-core-color-gradients-summit-silver-stop-2",
      percentage: 100,
    },
  ],
};
