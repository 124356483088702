import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import "../../generated/platforms/scss/vacations-dark.module.scss";
import "../../generated/platforms/scss/vacations.module.scss";
import { DesignTokensThemeProvider } from "../DesignTokensThemeProvider";

/**
 * Theme for SWA Vacations dark styles.
 */

export const VacationsDarkTheme = (props) => {
  const { children } = props;

  return <DesignTokensThemeProvider {...getProps()}>{children}</DesignTokensThemeProvider>;

  function getProps() {
    return {
      className: classNames("vacations-dark-theme", "vacations-theme"),
      themeName: "vacations",
    };
  }
};

VacationsDarkTheme.propTypes = {
  /** Content that will be rendered on the button. */
  children: PropTypes.node,
};
