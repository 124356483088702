import PropTypes from "prop-types";
import React from "react";

import { swaDate } from "@swa-ui/date";

import { ItineraryDate } from "../ItineraryDate";
import { itinerarySegmentPropTypes } from "../ItinerarySegment";

/**
 * ItineraryDateRange renders a date within a span element something like this: Jan 10 - 22.
 */

export const ItineraryDateRange = (props) => {
  const { className, endDate, startDate, styleSetting } = props;

  return (
    <span className={className}>
      {endDate ? renderDateRange() : <ItineraryDate {...getDateProps(startDate)} />}
    </span>
  );

  function renderDateRange() {
    const returnDateFormat = swaDate(startDate).isSame(swaDate(endDate), "month") ? "DD" : "MMM DD";

    return (
      <>
        <ItineraryDate {...getDateProps(startDate)} /> -{" "}
        <ItineraryDate {...getDateProps(endDate, returnDateFormat)} />
      </>
    );
  }

  function getDateProps(date, format = "MMM DD") {
    return {
      date,
      format,
      styleSetting,
    };
  }
};

ItineraryDateRange.propTypes = {
  /** Additional classes for positioning the component. */
  className: PropTypes.string,

  /** Used to render the end date. */
  endDate: PropTypes.string,

  /** Used to render the start date. */
  startDate: PropTypes.string.isRequired,

  /** Options to use to style rendered date. */
  styleSetting: PropTypes.shape(itinerarySegmentPropTypes),
};
