import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { window } from "../window";

/**
 * The ScrollToTop component is used to ensure that the user is looking at the top of the
 * browser window whenever the pathname changes based on user interaction.
 */

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
    });
  }, [pathname]);

  return null;
};
