import PropTypes from "prop-types";
import React from "react";

import { useDeviceInfo } from "@swa-ui/browser";
import { Background, Heading, Layout } from "@swa-ui/core";
import { classNames } from "@swa-ui/string";

import { applyOverrides } from "../applyOverrides";
import { backgroundPropType } from "../Banner/BannerUtils";
import styles from "./HeroImagePlacement.module.scss";

/**
 * This component is intended to be used for a Hero Image shot with an optional heading,
 * usually placed on top of the page.
 *
 * @param {*} props
 * @returns {JSX.Element}
 */
export const HeroImagePlacement = (props) => {
  const { placement } = props;
  const { background, title = {} } = placement;
  const { below, center, value: headingValue } = title;
  const { screenSize } = useDeviceInfo();
  const headingIsInside = !below;

  return (
    <>
      {renderBanner()}
      {!headingIsInside && renderHeading()}
    </>
  );

  function renderBanner() {
    const content = headingIsInside ? renderHeading() : null;

    return (
      <div {...getProps()}>
        <Background {...getBackgroundProps()}>{content}</Background>
      </div>
    );
  }

  function renderHeading() {
    return (
      headingValue && (
        <Layout
          className={styles.layoutWrapper}
          sides={headingIsInside && !center}
          top={!headingIsInside ? "large" : "none"}
        >
          <Heading {...getHeadingProps()}>
            <span style={{ ...title }}>{title?.value}</span>
          </Heading>
        </Layout>
      )
    );
  }

  function getProps() {
    const { height = "medium" } = placement;

    return {
      className: classNames({
        [styles.container]: true,
        [styles[height]]: true,
      }),
    };
  }

  function getBackgroundProps() {
    const { imagePath } = applyOverrides(background, screenSize);

    return {
      backgroundAttachment: "local",
      imageUrl: imagePath,
    };
  }

  function getHeadingProps() {
    return {
      className: classNames({
        [styles.center]: center,
        [styles.heading]: true,
        [styles.inside]: headingIsInside,
      }),
      headingLevel: 1,
      styleLevel: 1,
    };
  }
};

HeroImagePlacement.propTypes = {
  placement: PropTypes.shape({
    background: backgroundPropType,
    height: PropTypes.oneOf(["small", "medium", "large"]),
    title: PropTypes.shape({
      below: PropTypes.bool,
      center: PropTypes.bool,
      color: PropTypes.string,
      fontSize: PropTypes.string,
      fontWeight: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
};
