import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import styles from "./LineClamp.module.scss";

/**
 * Provides a way to restrict number of lines contained in a div element. Content that won't fit
 * in given number of lines will be truncated and represented by ellipsis.
 */

export const LineClamp = React.forwardRef((props, ref) => {
  const { children, className, lineCount } = props;

  return (
    <div {...getProps()} ref={ref}>
      {children}
    </div>
  );

  function getProps() {
    return {
      "aria-describedby": props["aria-describedby"],
      "aria-label": props["aria-label"],
      "aria-labelledby": props["aria-labelledby"],
      className: classNames(className, styles.lineClamp),
      style: getStyle(),
    };
  }

  function getStyle() {
    return { "--line-clamp": lineCount, "--word-break": lineCount === 1 ? "break-all" : "normal" };
  }
});

LineClamp.propTypes = {
  /**
   * aria-describedby id to element which provides additional accessibility description of input
   * element.
   */
  "aria-describedby": PropTypes.string,

  /** aria-label text to provide additional accessibility description of button element. */
  "aria-label": PropTypes.string,

  /**
   * aria-labelledby id to element which provides additional accessibility description of input
   * element.
   */
  "aria-labelledby": PropTypes.string,

  /** Content that will be rendered on the button. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Number of lines to restrict content. */
  lineCount: PropTypes.number,
};

LineClamp.defaultProps = {
  lineCount: 3,
};
