import PropTypes from "prop-types";
import React from "react";

import { usePlacement } from "@swa-ui/placements";

import { DotcomHeaderDesktop } from "./DotcomHeaderDesktop";
import { DotcomHeaderMobile } from "./DotcomHeaderMobile";
import { DotcomHeaderMobileWithGlobalNav } from "./DotcomHeaderMobileWithGlobalNav";

const HEADER_CONTROL_PORTAL_ID = "headerControlPortal";

/**
 * DotcomHeader will render the appropriate header component base on props.size: either the primary or secondary
 * global header.
 */

export const DotcomHeader = (props) => {
  const {
    control,
    light,
    menuItemSeparator,
    onSearch,
    pageTitle,
    persona,
    type,
    uppercaseMenuItems,
  } = props;
  const { placement: navTop } = usePlacement("navTop");

  return type === "desktop" ? <DotcomHeaderDesktop {...getDesktopProps()} /> : renderMobileHeader();

  function renderMobileHeader() {
    return navTop !== undefined ? (
      <DotcomHeaderMobile {...getMobileProps()} />
    ) : (
      <DotcomHeaderMobileWithGlobalNav {...getMobileWithGlobalNavProps()} />
    );
  }

  function getDesktopProps() {
    return {
      control,
      light,
      menuItemSeparator,
      onSearch,
      uppercaseMenuItems,
    };
  }

  function getMobileProps() {
    return {
      control:
        control &&
        React.cloneElement(control, {
          headerControlPortalId: HEADER_CONTROL_PORTAL_ID,
        }),
      headerControlPortalId: HEADER_CONTROL_PORTAL_ID,
      pageTitle,
      persona,
    };
  }

  function getMobileWithGlobalNavProps() {
    return {
      control,
      pageTitle,
      persona,
      uppercaseMenuItems,
    };
  }
};

DotcomHeader.propTypes = {
  /**
   * Header control component used to control items like login and locale switching
   */
  control: PropTypes.node,

  /** Indicates whether the app is using a dark Header background, to be passed to elements for styling. */
  light: PropTypes.bool,

  /** Adds the value of the prop as a separator between the menu links. */
  menuItemSeparator: PropTypes.string,

  /** Calllback that will be used when traveler performs a search. */
  onSearch: PropTypes.func,

  /** Name of current page. */
  pageTitle: PropTypes.string,

  /** Current persona that is currently only used to display a different logo. */
  persona: PropTypes.string,

  /** <code>size</code> determines which DotcomHeader is rendered. */
  type: PropTypes.oneOf(["desktop", "mobile"]).isRequired,

  /** Transform menu item links into uppercase. Default: true */
  uppercaseMenuItems: PropTypes.bool,
};
