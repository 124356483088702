import PropTypes from "prop-types";
import React, { useState } from "react";

import { personas } from "../defines";
import { siteToPersona } from "../defines/siteToPersona";

export const PersonasContext = React.createContext(personas.leisure);

/** PersonasProvider is a simple context that reveals the "persona" of the user navigating the app. */
export const PersonasProvider = (props) => {
  const { children, persona } = props;
  const [storedPersona, setStoredPersona] = useState(getInitialPersona());

  return <PersonasContext.Provider value={getContextValue()}>{children}</PersonasContext.Provider>;

  function getContextValue() {
    const personaValidators = getPersonaValidators();

    return {
      persona: storedPersona,
      setPersona: setStoredPersona,
      ...personaValidators,
    };
  }

  function getInitialPersona() {
    return (
      personas[persona] ?? persona ?? personas[siteToPersona[process.env.SITE]] ?? personas.leisure
    );
  }

  function getPersonaValidators() {
    return Object.keys(personas).reduce((accumulatedValidators, personaKey) => {
      const capitalizedPersona = personaKey.charAt(0).toUpperCase() + personaKey.slice(1);
      const personaValidatorName = `is${capitalizedPersona}Persona`;
      const personaValidatorValue = storedPersona === personas[personaKey];

      return {
        ...accumulatedValidators,
        [personaValidatorName]: personaValidatorValue,
      };
    }, {});
  }
};

PersonasContext.displayName = "PersonasContext";
PersonasProvider.propTypes = {
  /** Content that will be rendered. */
  children: PropTypes.node,
};
