import PropTypes from "prop-types";
import React, { createContext } from "react";
import { useLocation } from "react-router-dom";

import { getBootstrapData } from "@swa-ui/bootstrap";

import { getPageId } from "../getPageId";

/**
 * AppSettingsProvider provides all default application and bootstrap configuration settings in a React context so the
 * configuration settings is available to components in an app's render tree.
 *
 * appSettings - Default settings configured in SPA's main app component.
 * getAppSettingForPathname - Method to retrieve app's settings including bootstrap data.
 */

export const AppSettingsContext = createContext();
export const AppSettingsProvider = (props) => {
  const { appId, children, defaultAppSettings } = props;
  const { pathname } = useLocation();

  return (
    <AppSettingsContext.Provider
      value={{
        appSettings: getAppSettingsForCurrentPathname(),
        getAppSettingForPathname,
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  );

  function getAppSettingsForCurrentPathname() {
    return getAppSettingsForPathname(pathname);
  }

  function getAppSettingForPathname(path, key, defaultValue) {
    return getAppSettingsForPathname(path)?.[key] ?? defaultValue;
  }

  function getAppSettingsForPathname(path) {
    const bootstrapAppSettings = getBootstrapData(`app-settings/${appId}-app-settings`);
    const pageId = getPageId(path);

    return {
      ...defaultAppSettings?.default,
      ...defaultAppSettings?.[pageId],
      ...bootstrapAppSettings?.default,
      ...bootstrapAppSettings?.[pageId],
    };
  }
};

AppSettingsContext.displayName = "AppSettingsContext";
AppSettingsProvider.propTypes = {
  /**
   * String that identifies the app for when making requests. The value, something like: 'gift-card', is added to
   * request headers.
   */
  appId: PropTypes.string.isRequired,

  /** Current page's content to be rendered inside this AppSettingsProvider wrapper. */
  children: PropTypes.node.isRequired,

  /** Application defined configuration settings. */
  defaultAppSettings: PropTypes.object,
};
