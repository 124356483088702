import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { AriaLive } from "@swa-ui/core";

import { getPageId } from "../getPageId";

export const TitleUpdater = (props) => {
  const { announceTitle, appId } = props;
  const { pathname } = useLocation();
  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    setPageTitle(getTitles()[getPageId(pathname)]);
  }, [pathname]);

  useEffect(() => {
    pageTitle && (document.title = pageTitle);
  }, [pageTitle]);

  return announceTitle ? <AriaLive ariaLiveSetting="assertive">{pageTitle}</AriaLive> : null;

  function getTitles() {
    const metaTags = getBootstrapData("meta-tags")?.[appId] ?? [];
    const titleTag = metaTags.find((metaTag) => metaTag.tag === "title") ?? {
      attributes: [],
    };

    return titleTag.attributes.reduce((titles, title) => {
      titles[title.key] = title.value;

      return titles;
    }, {});
  }
};

TitleUpdater.propTypes = {
  /**
   * Renders the aria component for announcing title text if set to true.
   */
  announceTitle: PropTypes.bool,

  /**
   * String that identifies the app for when making requests. The value, something like: 'gift-card', is added to
   * request headers.
   */
  appId: PropTypes.string.isRequired,
};
