import PropTypes from "prop-types";
import React, { useContext } from "react";

import { ErrorBoundary } from "@swa-ui/error";
import { map } from "@swa-ui/object";
import { classNames } from "@swa-ui/string";
import { interpolate } from "@swa-util/string";

import { PlacementsContext } from "../PlacementsContext";
import { usePlacement } from "../usePlacement";
import { ErrorPlacement } from "./ErrorPlacement";
import styles from "./PlacementFactory.module.scss";

/**
 * PlacementFactory renders the placement using the placement ID.
 */

export const PlacementFactory = (props) => {
  const {
    additionalProps,
    alwaysVisible = false,
    className,
    componentRenderers,
    id,
    options,
    shouldWait,
  } = props;
  const { isComplete, isPlacementLoading, placement, placementMetadata } = usePlacement(id, {
    ...options,
    additionalProps,
  });
  const { placementComponents, placementsConfig = {} } = useContext(PlacementsContext);
  const Placement = placementComponents[placement?.displayType];

  let content = null;

  if (shouldWait) {
    content = isComplete && Placement && placement ? renderPlacement() : null;
  } else {
    content =
      (!isPlacementLoading && alwaysVisible) || (Placement && placement) ? renderPlacement() : null;
  }

  return content;

  function renderPlacement() {
    return (
      <ErrorBoundary FallbackComponent={ErrorPlacement}>
        <div className={getClass()} id={id}>
          <Placement {...getPlacementProps()} />
        </div>
      </ErrorBoundary>
    );
  }

  function getPlacementProps() {
    const placementDataCollection = {
      ...placement,
      ...placementsConfig,
      additionalProps,
      componentRenderers,
    };
    const { templateData = {} } = additionalProps ?? {};
    const { templateKeys } = placementDataCollection;
    const interpolationData = templateKeys?.reduce(
      (accumulatedData, key) => ({ ...accumulatedData, [key]: templateData[key] || "" }),
      {}
    );
    let placementProps = placementDataCollection;

    if (templateData && templateKeys?.length > 0) {
      placementProps = map(placementProps, getInterpolatedPlacementValues);
    }

    return placementProps;

    function getInterpolatedPlacementValues(placementValue) {
      let newPlacementValue = placementValue;

      if (typeof newPlacementValue === "object") {
        if (Array.isArray(newPlacementValue)) {
          newPlacementValue = newPlacementValue.map(getInterpolatedPlacementValues);
        } else {
          newPlacementValue = map(newPlacementValue, getInterpolatedPlacementValues);
        }
      } else {
        newPlacementValue = interpolate(newPlacementValue, interpolationData);
      }

      return newPlacementValue;
    }
  }

  function getClass() {
    return classNames(
      { [styles.scaleFont]: placement.placementData?.scaleFont },
      className,
      styles.placementContainer,
      `placement_${id}`,
      `placementInstance_${placementMetadata?.id}`
    );
  }
};

PlacementFactory.propTypes = {
  /**
   * Set to true if the placement should always be visible, regardless of error.
   */
  alwaysVisible: PropTypes.bool,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Placement ID to use to identify content to render.
   */
  id: PropTypes.string.isRequired,

  /**
   * Object to pass options to usePlacement.
   */
  options: PropTypes.object,

  /**
   * Boolean to control if component should wait until placement call is done.
   */
  shouldWait: PropTypes.bool,
};

PlacementFactory.defaultProps = {
  shouldWait: true,
};
