import React, { useEffect, useState } from "react";

import { Accordion } from "@swa-ui/core";

export const ErrorDetails = (props) => {
  const { error, errorInfo } = props;
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    window.addEventListener("keyup", displayOnShiftF10); // NOSONAR

    return () => {
      window.removeEventListener("keyup", displayOnShiftF10); // NOSONAR
    };
  }, []);

  return hidden ? null : (
    <Accordion revealed label="Details" name="ErrorDetails">
      <pre style={{ whiteSpace: "pre-wrap" }}>
        {error && error.toString()}
        {errorInfo?.componentStack}
      </pre>
    </Accordion>
  );

  function displayOnShiftF10(event) {
    if (event.keyCode === 121 && event.shiftKey) {
      setHidden((isHidden) => !isHidden);
    }
  }
};
