import PropTypes from "prop-types";
import React, { useContext } from "react";

import {
  AppFooter,
  ApplicationContext,
  Maintenance,
  NoServerSideRendering,
  Page,
  SuppressForNative,
} from "@swa-ui/application";
import { AuthResponseDialog } from "@swa-ui/authentication";
import { getBootstrapData } from "@swa-ui/bootstrap";
import { useDeviceInfo } from "@swa-ui/browser";
import { Grid, Layout } from "@swa-ui/core";
import { CustomerLightTheme } from "@swa-ui/design-tokens";
import { usePlacement } from "@swa-ui/placements";
import { classNames } from "@swa-ui/string";

import { DesktopHeaderControl } from "../DesktopHeaderControl";
import { DotcomFooter } from "../DotcomFooter";
import { DotcomHeader } from "../DotcomHeader";
import { DotcomMessages } from "../DotcomMessages";
import { DotcomSystemMessages } from "../DotcomSystemMessages";
import { MobileHeaderControl } from "../MobileHeaderControl";
import { StartupMessage } from "../StartupMessage";
import styles from "./DotcomPageLayout.module.scss";

export const DotcomPageLayout = (props) => {
  const {
    appHeader,
    children,
    headerControl,
    HeaderTheme,
    light,
    menuItemSeparator,
    messagesLocation,
    PageTheme,
    pageTitle,
    showDotcomMessages,
    uppercaseMenuItems,
  } = props;

  const { basePath, experienceId, footer, header, maintenanceCode, persona, site } =
    useContext(ApplicationContext);

  const { isLargeOrXlarge } = useDeviceInfo();

  const { placement: globalNavHeader } = usePlacement("globalNavHeader");

  return (
    <>
      {messagesLocation === "above" && renderMessages()}
      <PageTheme>
        <Page {...getPageProps()}>
          <div className={styles.pageContent}>
            <Maintenance>
              {messagesLocation === "below" && renderMessages()}
              <AuthResponseDialog />
              <StartupMessage />
              {children}
            </Maintenance>
          </div>
          <AppFooter />
        </Page>
      </PageTheme>
    </>
  );

  function getPageProps() {
    return {
      basePath,
      className: classNames(styles.minDesktopWidth, styles.background),
      header: renderHeader(),
      light,
      experienceId,
      footer: renderFooter(),
      suppressMessageForTrackingCodes: getTrackingCodesToSuppress().map(String),
    };
  }

  function renderHeader() {
    return (
      <HeaderTheme>
        <header className={styles.header}>
          <NoServerSideRendering>
            <SuppressForNative>
              {header ? header : <DotcomHeader {...getHeaderProps()} />}
            </SuppressForNative>
          </NoServerSideRendering>
          {appHeader}
        </header>
      </HeaderTheme>
    );
  }

  function renderFooter() {
    return (
      <NoServerSideRendering>
        <SuppressForNative>
          {footer ? footer : <DotcomFooter {...getFooterProps()} />}
        </SuppressForNative>
      </NoServerSideRendering>
    );
  }

  function renderMessages() {
    return (
      showDotcomMessages && (
        <>
          <Grid>
            <DotcomSystemMessages
              className={classNames(styles.minDesktopWidth, styles.systemMessages)}
            />
          </Grid>
          <div className={styles.messages}>
            <DotcomMessages />
          </div>
        </>
      )
    );
  }

  function getTrackingCodesToSuppress() {
    const { errorsTriggeringReauthentication, errorsTriggeringRelogin, errorsTriggeringRetry } =
      getBootstrapData("api-gateway-errors") ?? {};

    return [
      maintenanceCode,
      ...errorsTriggeringReauthentication,
      ...errorsTriggeringRelogin,
      ...errorsTriggeringRetry,
    ];
  }

  function getHeaderProps() {
    const type = getNavigationType();

    return {
      appHeader,
      control:
        headerControl ??
        (type === "desktop" ? (
          <DesktopHeaderControl light={light} />
        ) : (
          <MobileHeaderControl site={site} />
        )),
      light,
      menuItemSeparator:
        globalNavHeader?.primaryLinksOptions?.menuItemSeparator ?? menuItemSeparator,
      pageTitle,
      persona,
      type,
      uppercaseMenuItems:
        globalNavHeader?.primaryLinksOptions?.uppercaseMenuItems ?? uppercaseMenuItems,
    };
  }

  function getFooterProps() {
    const type = getNavigationType();

    return {
      type,
    };
  }

  function getNavigationType() {
    return isLargeOrXlarge ? "desktop" : "mobile";
  }
};

DotcomPageLayout.propTypes = {
  /** Header component passed from SPA. */
  appHeader: PropTypes.node,

  /** Content to be rendered on the page. */
  children: PropTypes.node.isRequired,

  /** Header theme to be displayed, such as customer or vacations. */
  HeaderTheme: PropTypes.func,

  /** Indicates whether the app is using a dark Header background, to be passed to Link component for styling. */
  light: PropTypes.bool,

  /** Adds the value of the prop as a separator between the menu links. */
  menuItemSeparator: PropTypes.string,

  /** Changes the place where the DotcomMessages is rendered
     (above or below the header). Default: "below". */
  messagesLocation: PropTypes.oneOf(["above", "below"]),

  /** Page theme to be displayed, such as customer or vacations. */
  PageTheme: PropTypes.func,

  /** Page heading to appear in the global header. */
  pageTitle: PropTypes.string,

  /** Indicates whether to show the dotcom messages */
  showDotcomMessages: PropTypes.bool,

  /** Transform menu item links into uppercase. Default: true */
  uppercaseMenuItems: PropTypes.bool,
};

DotcomPageLayout.defaultProps = {
  HeaderTheme: CustomerLightTheme,
  PageTheme: CustomerLightTheme,
  showDotcomMessages: true,
  messagesLocation: "below",
};
