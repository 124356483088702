import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { classNames } from "@swa-ui/string";

import { Button } from "../Button";
import { Icon } from "../Icon";
import { Input } from "../Input";
import styles from "./Paginator.module.scss";

const PAGINATOR_DISABLED_DESIGN_TOKEN = "cmp-core-color-paginator-disabled";
const PAGINATOR_FG_DESIGN_TOKEN = "cmp-core-color-paginator-icon-fg";

/**
 * Paginator provides a pagination "control panel" to allow items to be displayed in "chunks".
 */

export const Paginator = (props) => {
  const {
    ariaLabelInput,
    ariaLabelNext,
    ariaLabelPrevious,
    className,
    defaultPageNumber,
    disabled,
    maxItemsToDisplay,
    numberOfItems,
    onPageChange,
    pageLabel,
    pageNumber,
    separatorLabel,
  } = props;
  const [currentInputPageNumber, setCurrentInputPageNumber] = useState(
    `${Math.min(pageNumber || defaultPageNumber, getMaxPageNumber())}`
  );
  const [currentPageNumber, setCurrentPageNumber] = useState(
    Math.min(pageNumber || defaultPageNumber, getMaxPageNumber())
  );
  const previousPageNumber = useRef(pageNumber);

  useEffect(() => {
    const newPageNumber = Math.min(pageNumber, getMaxPageNumber());

    previousPageNumber.current = currentPageNumber;
    updatePageNumber(newPageNumber);
  }, [pageNumber]);

  return (
    <div className={classNames(className, styles.controls)}>
      <Button {...getLeftButtonProps()}>
        <Icon
          actions={["rotate270"]}
          name="ArrowThin"
          color={isLeftDisabled() ? PAGINATOR_DISABLED_DESIGN_TOKEN : PAGINATOR_FG_DESIGN_TOKEN}
        />
      </Button>
      <div className={getLabelClass()}>{pageLabel}</div>
      <div className={getInputClass()}>
        <Input {...getInputProps()} />
      </div>
      <div className={getSeparatorLabelClass()}>{separatorLabel}</div>
      <div className={getLabelClass()}>{getMaxPageNumber()}</div>
      <Button {...getRightButtonProps()}>
        <Icon
          actions={["rotate90"]}
          name="ArrowThin"
          color={isRightDisabled() ? PAGINATOR_DISABLED_DESIGN_TOKEN : PAGINATOR_FG_DESIGN_TOKEN}
        />
      </Button>
    </div>
  );

  function getInputProps() {
    return {
      acceptableCharacters: "0123456789",
      ["aria-label"]: ariaLabelInput,
      disabled: isDisabled(),
      onBlur: handleBlur,
      onChange: handleInputChange,
      size: "small",
      textAlign: "center",
      value: currentInputPageNumber,
    };
  }

  function getLeftButtonProps() {
    const shouldBeDisabled = isLeftDisabled();

    return {
      ["aria-label"]: ariaLabelPrevious,
      className: classNames(styles.arrowOption, styles.previousOption, {
        [styles.disabled]: shouldBeDisabled,
      }),
      disabled: shouldBeDisabled,
      onClick: handleClickLeft,
      styleType: "no-style",
    };
  }

  function getRightButtonProps() {
    const shouldBeDisabled = isRightDisabled();

    return {
      ["aria-label"]: ariaLabelNext,
      className: classNames(styles.arrowOption, styles.nextOption, {
        [styles.disabled]: shouldBeDisabled,
      }),
      disabled: shouldBeDisabled,
      onClick: handleClickRight,
      styleType: "no-style",
    };
  }

  function getLabelClass() {
    return classNames({ [styles.disabled]: isDisabled() });
  }

  function getInputClass() {
    return classNames(styles.input, { [styles.wide]: getMaxPageNumber() > 99 });
  }

  function getSeparatorLabelClass() {
    return classNames(styles.label, { [styles.disabled]: isDisabled() });
  }

  function handleClickLeft() {
    const newPageNumber = currentPageNumber - 1;

    updatePageNumber(newPageNumber);
    onPageChange && onPageChange(newPageNumber);
  }

  function handleClickRight() {
    const newPageNumber = currentPageNumber + 1;

    updatePageNumber(newPageNumber);
    onPageChange && onPageChange(newPageNumber);
  }

  function handleBlur(event) {
    const { value } = event.target;

    if (!value.length) {
      updatePageNumber(previousPageNumber.current);
    }
  }

  function handleInputChange(event) {
    const { value } = event.target;

    if (!value.length || value === "0") {
      setCurrentInputPageNumber("");
      setCurrentPageNumber(1);
    } else {
      const newPageNumber = Math.min(parseInt(value), getMaxPageNumber());

      updatePageNumber(newPageNumber);
      onPageChange && onPageChange(newPageNumber);
    }
  }

  function isLeftDisabled() {
    return isDisabled() || currentPageNumber <= 1;
  }

  function isRightDisabled() {
    return isDisabled() || currentPageNumber >= getMaxPageNumber();
  }

  function isDisabled() {
    return disabled || getMaxPageNumber() <= 1;
  }

  function getMaxPageNumber() {
    return (
      Math.floor(numberOfItems / maxItemsToDisplay) + (numberOfItems % maxItemsToDisplay ? 1 : 0)
    );
  }

  function updatePageNumber(newPageNumber) {
    setCurrentInputPageNumber(`${newPageNumber}`);
    setCurrentPageNumber(newPageNumber);
  }
};

export const paginatorPropTypes = {
  /** aria-label text to provide additional accessibility description of input element. */
  ariaLabelInput: PropTypes.string.isRequired,

  /** aria-label text to be applied to next button element. */
  ariaLabelNext: PropTypes.string.isRequired,

  /** aria-label text to be applied to previous button element. */
  ariaLabelPrevious: PropTypes.string.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Page that will be initially displayed. */
  defaultPageNumber: PropTypes.number,

  /** If true, the arrows and input field will be disabled, and the text labels will be grayed. */
  disabled: PropTypes.bool,

  /** Total number of items visible at a time. */
  maxItemsToDisplay: PropTypes.number,

  /**
   * Complete list of items to be rendered. The items rendered at a given time is determined by the current page
   * number and maxItemsToDisplay.
   */
  numberOfItems: PropTypes.number.isRequired,

  /**
   * Optional callback to be informed when a new page is displayed. The new page number will be the only returned
   * argument.
   */
  onPageChange: PropTypes.func,

  /** Label to indicate page number. Currently it is used as the text for "Page" in this label: "Page xx of yy". */
  pageLabel: PropTypes.string.isRequired,

  /**
   * Optional page number that can be controlled by the caller. If not given, Paginator will use its own internal
   * page number.
   */
  pageNumber: PropTypes.number,

  /**
   * Label to help denote the current page number and total number of pages. Currently it is used as the text for "of"
   * in this label: "Page xx of yy".
   */
  separatorLabel: PropTypes.string.isRequired,
};
Paginator.propTypes = paginatorPropTypes;

Paginator.defaultProps = {
  defaultPageNumber: 1,
  maxItemsToDisplay: 10,
};
