import PropTypes from "prop-types";
import React, { useState } from "react";

import { classNames } from "@swa-ui/string";

import { Background, backgroundPropTypes } from "../Background";
import { Transform } from "../Transform";
import styles from "./Loading.module.scss";

const ANIMATE = "animate";
const ANIMATION_DURATION = 333;
const DELAY_BETWEEN_RENDERS = 2000;
const FIRST = "first";
const INIT = "init";
const LIGHTENING_AMOUNT = 200;
const RESET = "reset";

/**
 * Loading renders a div with a "shimmer" animation to imply content will be returned. Loading "boxes",
 * of defined shapes and heights, can be used as an interstitial while content is being loaded.
 */
export const Loading = (props) => {
  const { className, color, height, shape, style } = props;
  const [renderToken, setRenderToken] = useState(INIT);

  return (
    <Background {...getProps()}>
      <Transform {...getTransformProps()}>
        <Background {...getBackgroundProps()} />
      </Transform>
    </Background>
  );

  function getProps() {
    const colors = {
      "neutral-500": "secondary",
      "neutral-700": "tertiary",
    };

    return {
      className: classNames(styles.loading, className),
      color: colors[color],
      height,
      shape,
      style,
    };
  }

  function getTransformProps() {
    const animate = {
      delay: renderToken === FIRST ? 0 : DELAY_BETWEEN_RENDERS,
      duration: ANIMATION_DURATION,
      onTransformationEnd: handleTransformationEnd,
      transformations: ["slideRight"],
    };
    const reset = {
      duration: 0.001,
      onTransformationEnd: handleTransformationEnd,
      transformations: ["slideLeft"],
    };

    return renderToken === ANIMATE || renderToken === FIRST ? animate : reset;
  }

  function getBackgroundProps() {
    return {
      gradient: `linear-gradient(90deg, var(--${color}), var(--${getLightenedColor()}))`,
      height,
      shape,
    };
  }

  function handleTransformationEnd() {
    setRenderToken(renderToken === INIT ? FIRST : renderToken === RESET ? ANIMATE : RESET);
  }

  function getLightenedColor() {
    return `neutral-${parseInt(color.split("-")[1]) + LIGHTENING_AMOUNT}`;
  }
};

export const loadingPropTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Background and "shimmer" color to apply to loading shape. */
  // TODO: refactor with semantic colors once Background has been tokenized
  // color: backgroundPropTypes.color,
  color: PropTypes.string,

  /** Height of shape to render from list of predefined heights. */
  height: backgroundPropTypes.height,

  /** Shape to render from list of predefined shapes. */
  shape: backgroundPropTypes.shape,

  /**
   * CSS styles to be applied to Loading's outer element. This prop is simply passed down to
   * Background.
   */
  style: PropTypes.object,
};

Loading.propTypes = loadingPropTypes;
Loading.defaultProps = {
  color: "neutral-700",
};
