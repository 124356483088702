import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { classNames } from "@swa-ui/string";

import { Area } from "../Area";
import { Button } from "../Button";
import { Icon } from "../Icon";
import styles from "./Accordion.module.scss";

/**
 * The Accordion component delivers large amounts of content in a small space through progress disclosure. The header
 * title gives the traveler a high level overview of the content allowing the traveler to decide which sections to read.

 * Accordions can make information processing and discovering more effective. However, it does hide content from
 * travelers and it's important to account for a traveler not noticing or reading all the included content.

 * When to use
 * To organize related information.
 * To shorten pages and reduce scrolling when all content is not crucial to read in full.
 * When space is at a premium and long content cannot be displayed all at once, like on a mobile interface or in a side
 * panel.
 */

export const Accordion = (props) => {
  const {
    children,
    className,
    disabled,
    fullWidthContent,
    indicatorClassName,
    label,
    labelBorder,
    name,
    onChange,
    revealed,
    styleType,
    type,
  } = props;
  const [reveal, setReveal] = useState(revealed);

  useEffect(() => {
    setReveal(revealed);
  }, [revealed]);

  return (
    <div className={getClass()}>
      <div aria-level={props["aria-level"]} role={"heading"}>
        <Button {...getButtonProps()}>
          {styleType === "no-style" ? label : renderStandardLabel()}
        </Button>
      </div>
      <Area {...getContentProps()}>{children}</Area>
    </div>
  );

  function renderStandardLabel() {
    return (
      <>
        {label}
        <Icon {...getIndicatorProps()} />
      </>
    );
  }

  function getButtonProps() {
    return {
      alignment: "left",
      "aria-controls": name,
      "aria-expanded": reveal,
      className: classNames(styles.label, {
        [styles.labelBorder]: labelBorder,
      }),
      disabled,
      onClick: handleClickReveal,
      styleType: "no-style",
    };
  }

  function getContentProps() {
    return {
      className: classNames({ [styles.contentContainer]: !fullWidthContent }),
      id: name,
      revealed: reveal,
    };
  }

  function getIndicatorProps() {
    const color = disabled
      ? "standard-disabled-foreground"
      : getStyleType() === "primary"
      ? "primary-accordion-label"
      : "secondary-accordion-label";
    const key = reveal ? "collapse" : "expand";

    return {
      "aria-label": props[`aria-label-${key}`],
      className: classNames(styles.indicator, indicatorClassName),
      color,
      custom: { type: reveal ? "inactive" : "active" },
      name: "ArrowThin",
    };
  }

  function getClass() {
    return classNames(className, {
      [styles.disabled]: disabled,
      [styles.primary]: getStyleType() === "primary",
      [styles.secondary]: getStyleType() === "secondary",
      [styles.tertiary]: getStyleType() === "tertiary",
    });
  }

  function handleClickReveal() {
    const newRevealState = !reveal;

    setReveal(newRevealState);
    onChange && onChange(newRevealState);
  }

  function getStyleType() {
    return type || styleType;
  }
};

Accordion.propTypes = {
  /** Aria level for the heading. The value should be a number between 1 and 6. Defaults to 2. */
  "aria-level": PropTypes.number,

  /** Content that will be rendered when the accordion is expanded. */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Indicates if a click to open/close should be ignored and if gray styling should be applied. Only text color will
   * be set for the label, which will be sufficient for pure text, but if JSX is given for label, the calling
   * application will need to provide disabled styling.
   */
  disabled: PropTypes.bool,

  /** Indicates if the rendered content should have any default padding or margin applied */
  fullWidthContent: PropTypes.bool,

  /** Additional classes for styling the indicator. */
  indicatorClassName: PropTypes.string,

  /* Content that will be rendered in the clickable area of Accordion that is always visible. */
  label: PropTypes.node.isRequired,

  /** Indicates if the label will display a border color. Different border color
   *  is set based on the styleType.  */
  labelBorder: PropTypes.bool,

  /**
   * <code>name</code> will be used to tie the clickable area with Accordion's content. The given name is used for
   * the aria-controls attribute to be A11Y compliant.
   */
  name: PropTypes.string.isRequired,

  /**
   * Function to be called when the accordion is expanded or collapsed.
   * @return {bool} true/false if accordion is open/closed
   */
  onChange: PropTypes.func,

  /** Indicates if the accordion is revealed or concealed. */
  revealed: PropTypes.bool,

  /**
   * styleType controls how the accordion is rendered with regards to the color scheme and amount of spacing.
   */
  styleType: PropTypes.oneOf(["no-style", "primary", "secondary", "tertiary"]),

  /**
   * DEPRECATED: type controls how the accordion is rendered with regards to the color scheme and amount of spacing.
   */
  type: PropTypes.oneOf(["primary", "secondary"]),
};

Accordion.defaultProps = {
  "aria-level": 2,
  fullWidthContent: false,
  labelBorder: true,
  revealed: false,
  styleType: "primary",
};
