import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import { Icon, iconPropTypes } from "../Icon";
import { List } from "../List";
import { Svg, svgPropTypes } from "../Svg";
import styles from "./BulletList.module.scss";

/**
 * BulletList uses List component to render a list of bulleted items.
 */

export const BulletList = (props) => {
  const { animateInitialRender, className, icon, itemClassName, items, spacing, svg } = props;

  return <List {...getListProps()} />;

  function renderItems() {
    return items.map((item) => {
      const { content } = item;

      return (
        <>
          {renderGraphic(item)}
          {content}
        </>
      );
    });
  }

  function renderGraphic(item) {
    let content;

    if (item.icon || (icon && !item.svg)) {
      content = <Icon {...getIconProps(item)} />;
    } else if (item.svg || (svg && !item.icon)) {
      content = <Svg {...getSvgProps(item)} />;
    }

    return content;
  }

  function getListProps() {
    return {
      animateInitialRender,
      className,
      itemClassName: getClass(),
      items: renderItems(),
    };
  }

  function getIconProps(item) {
    return {
      ...(item.icon ?? icon),
      className: styles.icon,
    };
  }

  function getSvgProps(item) {
    return {
      ...(item.svg ?? svg),
      className: styles.svg,
    };
  }

  function getClass() {
    return classNames(styles.item, itemClassName, {
      [styles.spacingMedium]: spacing === "medium",
      [styles.spacingLarge]: spacing === "large",
    });
  }
};

BulletList.propTypes = {
  /** Indicator that the list will be shown via transition when first rendered. */
  animateInitialRender: PropTypes.bool,

  /** Class name that can be added to the outermost container. */
  className: PropTypes.string,

  /**
   * Icon that can be placed on left side of each item. This icon will be applied to all items, but
   * each item can specify an icon or SVG to be applied to override the graphic given here. See Icon
   * for more details on these values.
   */
  icon: PropTypes.shape({
    actions: iconPropTypes.actions,
    background: iconPropTypes.background,
    border: iconPropTypes.border,
    color: iconPropTypes.color,
    custom: iconPropTypes.custom,
    name: iconPropTypes.name,
    shrink: iconPropTypes.shrink,
    size: iconPropTypes.size,
  }),

  /** Class to be added to each item's container. */
  itemClassName: PropTypes.string,

  /** List of items to be displayed. */
  items: PropTypes.arrayOf(PropTypes.object),

  /** spacing defines the line height for each item. */
  spacing: PropTypes.oneOf(["large", "medium", "small"]),

  /**
   * SVG that can be placed on left side of each item. This dsvg will be applied to all items, but
   * each item can specify an icon or svg to be applied to override the graphic given here. See SVG
   * for more details on these values.
   */
  svg: PropTypes.shape({
    actions: svgPropTypes.actions,
    background: svgPropTypes.background,
    border: svgPropTypes.border,
    name: svgPropTypes.name,
    size: svgPropTypes.size,
  }),
};

BulletList.defaultProps = {
  animateInitialRender: true,
  spacing: "medium",
};
