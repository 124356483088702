import { useContext, useEffect, useMemo } from "react";

import { MessageContext, useAppSetting } from "@swa-ui/application";
import { getSearchParam } from "@swa-ui/browser";
import i18n, { updateI18n } from "@swa-ui/locale";
import { logger } from "@swa-ui/log";

const DEFAULT_MESSAGE_TYPE = "information";

/**
 * StartupMessage processes query parameters on initial page load to display a message over the page, should
 * the message be an approved message by the application.
 */
export const StartupMessage = () => {
  const { displayMessage } = useContext(MessageContext);
  const approvedStartupMessages = useAppSetting("startupMessages", {});
  const messageI18nKey = useMemo(() => getSearchParam("startupMessage"));
  const messageType = useMemo(() => getSearchParam("startupMessageType") ?? DEFAULT_MESSAGE_TYPE);

  useEffect(() => {
    if (isApprovedStartupMessage(messageI18nKey)) {
      updateI18n(approvedStartupMessages);
      displayMessage(
        messageType,
        i18n(messageI18nKey),
        null,
        "center-to-window",
        undefined,
        true,
        false
      );
    } else {
      logger.error(`Invalid startup message: ${messageI18nKey}`, { component: "StartupMessage" });

      if (messageType === "error") {
        displayMessage(
          messageType,
          i18n("StartupMessage__ERROR_DEFAULT_TEXT"),
          i18n("StartupMessage__ERROR_DEFAULT_TITLE"),
          "center-to-window",
          undefined,
          true,
          false
        );
      }
    }
  }, []);

  return null;

  function isApprovedStartupMessage(i18nKey) {
    return Object.keys(approvedStartupMessages).includes(i18nKey);
  }
};

StartupMessage.propTypes = {};
