import PropTypes from "prop-types";
import React from "react";

import { MEDIUM, SMALL, useDeviceInfo } from "@swa-ui/browser";
import { Heading, Icon, ListMatrix } from "@swa-ui/core";
import { classNames } from "@swa-ui/string";

import { applyOverrides } from "../applyOverrides";
import { CallToAction } from "../CallToAction";
import { HtmlValue } from "../HtmlValue";
import styles from "./BannerGridLayout4.module.scss";

const BannerGridLayout4 = (props) => {
  const { callToAction, disclaimer, itemAlignment, items, title } = props;
  const { screenSize } = useDeviceInfo();
  const itemsCount = items.length;
  const ITEM_COUNT = 3;

  return renderLayout();

  function renderLayout() {
    let layout = "";

    if (screenSize === SMALL) {
      layout = renderContentSmallScreen();
    } else if (screenSize === MEDIUM && itemsCount === ITEM_COUNT) {
      layout = renderContentThreeItemsMediumScreen();
    } else {
      layout = renderContent();
    }

    return layout;
  }

  function renderContentSmallScreen() {
    return (
      <div className={styles.layout4Container}>
        {renderHeading()}
        <ListMatrix {...getListMatrixProps()} />
        {renderCallToAction()}
        {renderDisclaimer()}
      </div>
    );
  }

  function renderContentThreeItemsMediumScreen() {
    return (
      <div className={styles.threeItemsContainer}>
        {renderTextItems()}
        <ListMatrix {...getListMatrixProps()} />
      </div>
    );
  }

  function renderContent() {
    return (
      <div className={styles.layout4Container}>
        <div className={styles.layout4HeroContent}>{renderTextItems()}</div>
        <ListMatrix {...getListMatrixProps()} />
      </div>
    );
  }

  function renderHeading() {
    return title && <Heading {...getHeadingProps()} />;
  }

  function renderCallToAction() {
    return callToAction && <CallToAction {...callToAction} />;
  }

  function renderDisclaimer() {
    return disclaimer && <HtmlValue htmlValue={disclaimer.value} />;
  }

  function renderItemContent(item) {
    const { icon, text } = item;

    return {
      content: (
        <div className={styles.cardItems} key={text.primary || text.secondary}>
          <div className={styles.cardItemHeader} style={{ alignItems: getAlignment() }}>
            <Icon className={styles.icon} {...icon} />
            {text && renderTextConditionally(text)}
          </div>
          {text?.primary && text.secondary && renderText(text.secondary, "secondaryText")}
        </div>
      ),
    };
  }

  function renderTextConditionally(text) {
    const { primary, secondary } = text;
    let content;

    if (primary) {
      content = renderText(primary, "primaryText");
    } else if (secondary) {
      content = renderText(secondary, "secondaryTextOnly");
    }

    return content;
  }

  function renderText(text, type) {
    return (
      <div className={styles[type]} style={getTextStyle(text)}>
        {text.value}
      </div>
    );
  }

  function renderTextItems() {
    return (
      <>
        {renderHeading()}
        {renderCallToAction()}
        {renderDisclaimer()}
      </>
    );
  }

  function getHeadingProps() {
    return {
      children: <span {...getHeadingStyleProps()}>{title.value}</span>,
      className: styles.heading,
      headingLevel: 3,
    };
  }

  function getHeadingStyleProps() {
    const { color, fontSize, fontWeight } = title ?? {};

    return {
      style: {
        color,
        fontSize,
        fontWeight,
      },
    };
  }

  function getListMatrixProps() {
    const columnSizeMap = {
      large: 4,
      medium: itemsCount === ITEM_COUNT ? 3 : 2,
      small: 1,
      xlarge: 4,
    };

    return {
      className: getListMatrixClasses(),
      items: getItems(),
      layout: "grid",
      numberOfColumns:
        itemsCount < columnSizeMap[screenSize] ? itemsCount : columnSizeMap[screenSize],
    };
  }

  function getListMatrixClasses() {
    return classNames(styles.layout4Wrapper, {
      [styles.fullWidth]: screenSize === MEDIUM && itemsCount === ITEM_COUNT,
    });
  }

  function getItems() {
    return items.map((item) => renderItemContent(item));
  }

  function getTextStyle(text) {
    const { color, fontSize, fontWeight } = text;

    return {
      color,
      fontSize,
      fontWeight,
      textAlign: getAlignment(),
    };
  }

  function getAlignment() {
    const { align } = applyOverrides(itemAlignment, screenSize);

    return align;
  }
};

BannerGridLayout4.propTypes = {
  /** CallToAction link/button to be displayed. */
  callToAction: PropTypes.object,

  /** Bottom disclaimer for legal text, takes HTML values. */
  disclaimer: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),

  /** Determines text alignment for banner content. */
  itemAlignment: PropTypes.shape({
    align: PropTypes.string,
    overrides: PropTypes.object,
  }).isRequired,

  /** Banner content utilizing can consist of any permutation of icon, primary, and/or secondary text. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object,
      text: PropTypes.shape({
        primary: PropTypes.object,
        secondary: PropTypes.object,
      }),
    })
  ).isRequired,

  /** Main heading content and styling to be displayed. */
  title: PropTypes.shape({
    color: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    value: PropTypes.string.isRequired,
  }),
};

export default BannerGridLayout4;
