import React, { useContext } from "react";

import { AppSettingsContext } from "@swa-ui/application";
import { AuthContext } from "@swa-ui/authentication";

import { LocaleSwitcher } from "../LocaleSwitcher";
import { CustomerInformation } from "./CustomerInformation";
import { ThemeSelector } from "./ThemeSelector";

/**
 * DesktopHeaderControlCorporate provides the account and locale type controls in the header for a Corporate Customer.
 */

export const DesktopHeaderControlCorporate = () => {
  const { appSettings } = useContext(AppSettingsContext);
  const { isLoggedIn } = useContext(AuthContext);
  const loggedIn = isLoggedIn();

  return (
    <>
      {appSettings.themeSelector && <ThemeSelector />}
      {appSettings.loginAvailable && loggedIn && (
        <CustomerInformation accountLinkText="DesktopHeaderControl__TRAVELER_ACCOUNT" separator />
      )}
      <LocaleSwitcher />
    </>
  );
};
