import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { classNames } from "@swa-ui/string";

import { getColorVariable } from "../getColorVariable";
import { PlayList } from "../PlayList";
import { Transform } from "../Transform";
import styles from "./Shimmer.module.scss";

const RECTANGLE_HEIGHT = 1000;
const RECTANGLE_WIDTH = 100;
const ROTATIONAL_OFFSET = 50;

/**
 * The Shimmer component is a colored rectangle that applies a uniform "streak" to simulate the shimmering
 * of light against a glossy surface. The animation itself lasts 2 seconds and travels the entire width
 * of the screen, regardless of the component's actual width and position. This allows for uniform "shimmering"
 * across multiple instances of this component throughout the page. Because of this, the delay between "shimmers"
 * may seem long depending on the layout and width of the parent. The `delay` prop sets a pause time (in ms) before
 * the shimmer starts at the leftmost edge of the page.
 */

export const Shimmer = (props) => {
  const { backgroundColor, className, delay, duration, height, repetitions } = props;
  const [offset, setOffset] = useState(0);
  const [width, setWidth] = useState(0);
  const outerContainerRef = useRef();
  const windowWidth = window.innerWidth;

  useEffect(() => {
    setWidth(outerContainerRef.current.offsetWidth);
    setOffset(outerContainerRef.current.offsetLeft);
  }, []);

  return (
    <div
      className={classNames(styles.shimmer, className)}
      ref={outerContainerRef}
      style={{ backgroundColor, height }}
    >
      <div {...getRectangleProps()}>
        <PlayList {...getPlayListProps()}>
          <Transform {...getRotationTransformProps()}>
            <div {...getShimmerProps()} />
          </Transform>
        </PlayList>
      </div>
    </div>
  );

  function getRectangleProps() {
    return {
      style: {
        left: `${-(RECTANGLE_WIDTH + offset + ROTATIONAL_OFFSET)}px`,
        position: "absolute",
        top: `${-(RECTANGLE_HEIGHT / 2)}px`,
      },
    };
  }

  function getShimmerProps() {
    return {
      className: styles.gradient,
      style: {
        display: width ? "block" : "none",
        height: `${RECTANGLE_HEIGHT}px`,
        width: `${RECTANGLE_WIDTH}px`,
      },
    };
  }

  function getRotationTransformProps() {
    return {
      immediate: true,
      transformations: [
        {
          action: "rotateZ",
          amount: `16deg`,
        },
      ],
    };
  }

  function getPlayListProps() {
    return {
      optimizeRerenders: true,
      playList: !width
        ? []
        : [
            {
              delay,
              duration: 0,
              transformations: [
                {
                  action: "translateX",
                  amount: "0px",
                },
              ],
            },
            {
              duration,
              transformations: [
                {
                  action: "translateX",
                  amount: `${windowWidth + RECTANGLE_WIDTH + ROTATIONAL_OFFSET * 2}px`,
                },
              ],
            },
          ],
      repetitions,
    };
  }
};

Shimmer.propTypes = {
  /** Color of the background rectangle, passed directly as a `background-color` CSS property. */
  backgroundColor: PropTypes.string,

  /** className provided to outermost div for positioning component */
  className: PropTypes.string,

  /** Time, in milliseconds, between "shimmer" effects after it reaches the end of the page. */
  delay: PropTypes.number,

  /** Length of animation in milliseconds for the "shimmer" to go from one end of the screen to the other. */
  duration: PropTypes.number,

  /** Height of the background rectangle, passed directly as a `height` CSS property. */
  height: PropTypes.string,

  /** Number of times for the shimmer to loop. */
  repetitions: PropTypes.number,
};

Shimmer.defaultProps = {
  backgroundColor: getColorVariable("shimmer-background"),
  delay: 500,
  duration: 1250,
  height: "100px",
  repetitions: 500,
};
