import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

import { keyCodes } from "../defines/keyCodes";
import { Input, inputPropTypes } from "../Input";

/**
 * FormattedInput will use the Input component to accept user input, and will additionally format the input according to
 * the given formatTemplate. Examples of formatTemplate: __/__/____ and (___) ___-____.
 */

const CURRENT_DATE = new Date();
const DATE_MAX = 31;
const DATE_MONTH_MIN = 1;
const MONTH_MAX = 12;
const YEAR_MAX = 2099;
const YEAR_MIN = CURRENT_DATE.getFullYear();

export const FormattedInput = React.forwardRef((props, ref) => {
  const {
    acceptableCharacters,
    autoComplete,
    className,
    customTemplateReplacement,
    defaultValue,
    disabled,
    error,
    formatTemplate,
    id,
    inputClassName,
    inputMode,
    name,
    onBlur,
    onChange,
    onClick,
    onFocus,
    onInput,
    onKeyDown,
    onMouseDown,
    readOnly,
    required,
    size,
    styleType,
    suffixIcon,
    type,
    value,
  } = props;
  const inputRef = ref || useRef();
  const previousText = useRef(separateInputFromTemplate(value));
  const currentStartSelection = useRef();
  const currentEndSelection = useRef();
  const editMode = useRef(false);

  useEffect(() => {
    const target = inputRef.current;

    if (target === document.activeElement) {
      preventCaretFromBeingBeyondEndOfContent(target, target.selectionStart, target.selectionEnd);
    }
  });

  return <Input {...getInputProps()} />;

  function getInputProps() {
    let newValue = undefined;

    if (value || value === "") {
      newValue = substituteTemplatePlaceholdersWithInput(separateInputFromTemplate(value));

      if (newValue === formatTemplate) {
        newValue = "";
      }
    }

    if (value !== undefined && previousText?.current && previousText.current === formatTemplate) {
      previousText.current = separateInputFromTemplate(value);
    }

    return {
      "aria-describedby": props["aria-describedby"],
      "aria-disabled": disabled,
      "aria-label": props["aria-label"],
      "aria-labelledby": props["aria-labelledby"],
      "aria-required": required,
      autoComplete,
      className,
      "data-test": props["data-test"],
      defaultValue: defaultValue?.length && substituteTemplatePlaceholdersWithInput(defaultValue),
      disabled,
      error,
      id,
      inputClassName,
      inputMode,
      name,
      onBlur,
      onChange: handleChange,
      onClick,
      onFocus,
      onInput: handleInput,
      onKeyDown: handleKeyDown,
      onMouseDown,
      onMouseUp: handleMouseUp,
      placeholder: formatTemplate,
      readOnly,
      ref: ref ? setUpPassedRef : inputRef,
      required,
      size,
      styleType,
      suffixIcon,
      type,
      value: newValue,
    };
  }

  function handleChange(event) {
    onChange && onChange(event);
  }

  function handleInput(event) {
    if (acceptableCharacters?.length) {
      const acceptable = acceptableCharacters.split("");
      const adjustedInput = separateInputFromTemplate(event.target.value);
      const text = adjustedInput.split("");

      event.target.value = substituteTemplatePlaceholdersWithInput(
        text.filter((character) => acceptable.indexOf(character) !== -1).join("")
      );
    }

    formatInput(event.target);
  }

  function handleKeyDown(event) {
    const { key, target } = event;
    const { selectionStart, selectionEnd } = target;
    const inputValue = target.value;
    const navigationKeys = [
      keyCodes.KEY_BACKSPACE,
      keyCodes.KEY_DELETE,
      keyCodes.KEY_DOWN,
      keyCodes.KEY_END,
      keyCodes.KEY_ENTER,
      keyCodes.KEY_HOME,
      keyCodes.KEY_LEFT,
      keyCodes.KEY_PAGE_DOWN,
      keyCodes.KEY_PAGE_UP,
      keyCodes.KEY_RIGHT,
      keyCodes.KEY_TAB,
      keyCodes.KEY_UP,
    ];

    if (key === keyCodes.KEY_BACKSPACE) {
      const indexesOfTemplateCharacters = getIndexesOfTemplateCharacters();

      if (
        selectionStart === selectionEnd &&
        indexesOfTemplateCharacters.indexOf(selectionStart - 1) !== -1
      ) {
        const caretPositionInRawInput =
          selectionStart - getNumberFormatCharactersBeforeCaret(selectionStart);
        const rawInput = separateInputFromTemplate(inputValue);
        const firstPart = rawInput.substring(0, caretPositionInRawInput - 1);
        const secondPart = rawInput.substring(caretPositionInRawInput);
        const indexesOfPlaceholders = getIndexesOfPlaceholders();
        const caretPosition = indexesOfPlaceholders[caretPositionInRawInput - 1];

        event.target.value = substituteTemplatePlaceholdersWithInput(`${firstPart}${secondPart}`);
        preventCaretFromBeingBeyondEndOfContent(target, caretPosition, caretPosition);
        previousText.current = separateInputFromTemplate(event.target.value);

        event.preventDefault();
        event.stopPropagation();

        onChange && onChange(event);
      }
    } else if (
      navigationKeys.indexOf(key) === -1 &&
      !event.ctrlKey &&
      !event.metaKey &&
      selectionStart === selectionEnd
    ) {
      const rawValue = separateInputFromTemplate(inputValue);
      const numberOfPlaceholders = formatTemplate.match(/_/g).length;

      if (rawValue.length >= numberOfPlaceholders) {
        event.preventDefault();
        event.stopPropagation();
      }
    } else if (key === keyCodes.KEY_RIGHT) {
      const numberFormatCharsBeforeCaret = getNumberFormatCharactersBeforeCaret(selectionEnd);
      const rawInput = separateInputFromTemplate(inputValue);

      if (selectionEnd >= rawInput.length + numberFormatCharsBeforeCaret) {
        event.preventDefault();
        event.stopPropagation();
      }
    } else if (key === keyCodes.KEY_DOWN || key === keyCodes.KEY_UP) {
      const isFullDate = !target.value.includes("_") && target.value.includes("/");

      event.preventDefault();
      event.stopPropagation();

      if (isFullDate) {
        target.value = incrementDecrementDate(target, key);
        inputRef.current.setSelectionRange(selectionStart, selectionStart);
        onChange && onChange(event);
      }
    }

    currentEndSelection.current = selectionEnd;
    currentStartSelection.current = selectionStart;

    if (navigationKeys.indexOf(key) !== -1 && !isFieldEmpty(target.value)) {
      editMode.current = true;
    }

    onKeyDown && onKeyDown(event);
  }

  function incrementDecrementDate(target, key) {
    const dateSegmentIndex = getDateSegmentIndex(target.selectionStart);
    const newDateSegments = getNewDate(target.value, dateSegmentIndex, key);

    return newDateSegments.join("/");
  }

  function getDateSegmentIndex(stringIndex) {
    const isMonth = stringIndex >= 0 && stringIndex <= 2;
    const isDay = stringIndex >= 3 && stringIndex <= 5;
    const isYear = stringIndex >= 6 && stringIndex <= 10;

    return isMonth ? 0 : isDay ? 1 : isYear ? 2 : "";
  }

  function getNewDate(targetValue, dateSegmentIndex, key) {
    const dateSegments = targetValue.split("/");
    let processedDateSegments;

    if (isDateBeyondMinimumOrMaximum(dateSegments, dateSegmentIndex, key)) {
      processedDateSegments = resetToMinimumOrMaximum(dateSegments, dateSegmentIndex, key);
    } else {
      processedDateSegments = incrementOrDecrementByOne(dateSegments, dateSegmentIndex, key);
    }

    return processedDateSegments;
  }

  function isDaySegment(dateSegmentIndex) {
    return dateSegmentIndex === 1;
  }

  function isMonthOrDaySegment(dateSegmentIndex) {
    return dateSegmentIndex === 0 || dateSegmentIndex === 1;
  }

  function isMonthSegment(dateSegmentIndex) {
    return dateSegmentIndex === 0;
  }

  function getIncrementedOrDecrementedDateValue(dateSegments, dateSegmentIndex, key) {
    const delta = key === keyCodes.KEY_UP ? 1 : -1;

    return zeroPad(Number(dateSegments[dateSegmentIndex]) + delta);
  }

  function resetToMinimumOrMaximum(dateSegments, dateSegmentIndex, key) {
    return key === keyCodes.KEY_UP
      ? resetToMinimum(dateSegments, dateSegmentIndex)
      : resetToMaximum(dateSegments, dateSegmentIndex);
  }

  function resetToMinimum(dateSegments, dateSegmentIndex) {
    const processedDateSegments = [...dateSegments];

    if (isMonthOrDaySegment(dateSegmentIndex)) {
      processedDateSegments.splice(dateSegmentIndex, 1, zeroPad(Number(DATE_MONTH_MIN)));
    }

    return processedDateSegments;
  }

  function resetToMaximum(dateSegments, dateSegmentIndex) {
    const processedDateSegments = [...dateSegments];

    if (isMonthSegment(dateSegmentIndex)) {
      processedDateSegments.splice(dateSegmentIndex, 1, zeroPad(Number(MONTH_MAX)));
    } else if (isDaySegment(dateSegmentIndex)) {
      processedDateSegments.splice(dateSegmentIndex, 1, zeroPad(Number(DATE_MAX)));
    }

    return processedDateSegments;
  }

  function incrementOrDecrementByOne(dateSegments, dateSegmentIndex, key) {
    const processedDateSegments = [...dateSegments];

    processedDateSegments.splice(
      dateSegmentIndex,
      1,
      getIncrementedOrDecrementedDateValue(dateSegments, dateSegmentIndex, key)
    );

    return processedDateSegments;
  }

  function isDateBeyondMinimumOrMaximum(dateSegments, dateSegmentIndex, key) {
    const minMaxValue = getDateMinimumOrMaximumValue(key, dateSegmentIndex);
    const newDate = getIncrementedOrDecrementedDateValue(dateSegments, dateSegmentIndex, key);

    return isDateAboveMax(key, newDate, minMaxValue) || isDateBelowMin(key, newDate, minMaxValue);
  }

  function isDateAboveMax(key, newDate, minMaxValue) {
    return key === keyCodes.KEY_UP && newDate > minMaxValue;
  }

  function isDateBelowMin(key, newDate, minMaxValue) {
    return key === keyCodes.KEY_DOWN && newDate < minMaxValue;
  }

  function getDateMinimumOrMaximumValue(key, dateSegmentIndex) {
    return key === keyCodes.KEY_UP
      ? getDateSegmentMaximumValue(dateSegmentIndex)
      : getDateSegmentMinimumValue(dateSegmentIndex);
  }

  function getDateSegmentMaximumValue(dateSegmentIndex) {
    const dateSegmentMaximumValues = [MONTH_MAX, DATE_MAX, YEAR_MAX];

    return dateSegmentMaximumValues[dateSegmentIndex];
  }

  function getDateSegmentMinimumValue(dateSegmentIndex) {
    const dateSegmentMinimumValues = [DATE_MONTH_MIN, DATE_MONTH_MIN, YEAR_MIN];

    return dateSegmentMinimumValues[dateSegmentIndex];
  }

  function zeroPad(dateValue) {
    return `${dateValue}`.padStart(2, "0");
  }

  function handleMouseUp(event) {
    const { target } = event;

    if (!isFieldEmpty(target.value)) {
      editMode.current = true;
    }

    preventCaretFromBeingBeyondEndOfContent(target, target.selectionStart, target.selectionEnd);
  }

  function formatInput(target) {
    const inputValue = target.value;
    const selectionStart = currentStartSelection.current;
    let rawValue = separateInputFromTemplate(inputValue);

    if (rawValue?.length) {
      if (rawValue !== previousText.current) {
        const numberOfPlaceholders = formatTemplate.match(/_/g).length;

        rawValue = rawValue.substring(0, numberOfPlaceholders);

        if (onInput) {
          rawValue = onInput(rawValue, editMode.current);
        }

        if (rawValue !== previousText.current) {
          const lengthOfChange = rawValue.length - previousText.current.length;
          const numberFormatCharsInChange = getNumberOfFormatCharsInChange(
            currentStartSelection.current,
            currentStartSelection.current + lengthOfChange
          );
          let caretPosition;

          target.value = substituteTemplatePlaceholdersWithInput(rawValue);

          if (currentStartSelection.current !== currentEndSelection.current) {
            caretPosition = advanceCaret(
              currentStartSelection.current +
                (currentEndSelection.current - currentStartSelection.current) +
                numberFormatCharsInChange +
                lengthOfChange
            );
          } else {
            caretPosition = advanceCaret(
              currentStartSelection.current + numberFormatCharsInChange + lengthOfChange
            );
          }

          preventCaretFromBeingBeyondEndOfContent(target, caretPosition, caretPosition);

          if (inputValue !== "") {
            previousText.current = separateInputFromTemplate(target.value);
          }
        } else {
          preventCaretFromBeingBeyondEndOfContent(target, selectionStart, selectionStart);
        }
      } else {
        preventCaretFromBeingBeyondEndOfContent(target, selectionStart, selectionStart);
      }
    } else {
      previousText.current = "";
      target.value = "";
    }

    if (isFieldEmpty(target.value)) {
      editMode.current = false;
    }
  }

  function isFieldEmpty(targetValue) {
    return separateInputFromTemplate(targetValue).length === 0;
  }

  function advanceCaret(caretPosition) {
    const indexesOfPlaceholders = getIndexesOfPlaceholders();

    if (
      indexesOfPlaceholders.indexOf(caretPosition) === -1 &&
      caretPosition < formatTemplate.length
    ) {
      caretPosition = findNextTemplatePosition(caretPosition);
    } else {
      caretPosition = Math.min(caretPosition, formatTemplate.length);
    }

    return caretPosition;
  }

  function findNextTemplatePosition(caretPosition) {
    const indexesOfPlaceholders = getIndexesOfPlaceholders();
    let index = 0;
    let stillLooking = true;

    while (stillLooking) {
      if (caretPosition >= indexesOfPlaceholders[index]) {
        if (index < indexesOfPlaceholders.length) {
          index += 1;
        } else {
          stillLooking = false;
        }
      } else {
        stillLooking = false;
      }
    }

    return indexesOfPlaceholders[index];
  }

  function getIndexesOfPlaceholders() {
    const placeholderIndexes = [];
    let index;

    for (index = 0; index < formatTemplate.length; index += 1) {
      if (formatTemplate[index] === "_") {
        placeholderIndexes.push(index);
      }
    }

    return placeholderIndexes;
  }

  function getIndexesOfTemplateCharacters() {
    const templateCharacterIndexes = [];
    let index;

    for (index = 0; index < formatTemplate.length; index += 1) {
      if (formatTemplate[index] !== "_") {
        templateCharacterIndexes.push(index);
      }
    }

    return templateCharacterIndexes;
  }

  function getNumberOfFormatCharsInChange(start, caretPosition) {
    let indexTemplate = start;
    let numberOfTemplateChars = 0;

    while (indexTemplate <= caretPosition) {
      if (formatTemplate[indexTemplate] !== "_") {
        numberOfTemplateChars += 1;
      }
      indexTemplate += 1;
    }

    return numberOfTemplateChars;
  }

  function getNumberFormatCharactersBeforeCaret(caretPosition) {
    const indexesOfTemplateCharacters = getIndexesOfTemplateCharacters();
    let index = 0;
    let stillLooking = true;

    while (stillLooking) {
      if (caretPosition < indexesOfTemplateCharacters[index]) {
        stillLooking = false;
      } else {
        if (index < indexesOfTemplateCharacters.length) {
          index += 1;
        } else {
          stillLooking = false;
        }
      }
    }

    return index;
  }

  function getUniqueCharactersInTemplate() {
    const templateCharacters = formatTemplate.split("");
    const uniqueCharacters = [];
    let index;

    for (index = 0; index < formatTemplate.length; index += 1) {
      const char = templateCharacters[index];

      if (uniqueCharacters.indexOf(char) === -1) {
        uniqueCharacters.push(char);
      }
    }

    return uniqueCharacters;
  }

  function preventCaretFromBeingBeyondEndOfContent(target, selectionStart, selectionEnd) {
    const indexesOfPlaceholders = getIndexesOfPlaceholders();
    const rawValue = separateInputFromTemplate(target.value);
    const indexFirstAvailablePlaceholder = indexesOfPlaceholders[rawValue.length];

    if (
      indexFirstAvailablePlaceholder < selectionStart ||
      indexFirstAvailablePlaceholder < selectionEnd
    ) {
      if (indexFirstAvailablePlaceholder < selectionStart) {
        selectionStart = indexFirstAvailablePlaceholder;
      }

      if (indexFirstAvailablePlaceholder < selectionEnd) {
        selectionEnd = indexFirstAvailablePlaceholder;
      }
    }

    target.setSelectionRange(selectionStart, selectionEnd);
  }

  function separateInputFromTemplate(inputValue) {
    const result = [];
    const uniqueCharacters = getUniqueCharactersInTemplate();

    if (inputValue?.length) {
      inputValue = customTemplateReplacement ? customTemplateReplacement(inputValue) : inputValue;
      const inputChars = inputValue.split("");
      let index;

      for (index = 0; index < inputChars.length; index += 1) {
        const char = inputChars[index];

        if (uniqueCharacters.indexOf(char) === -1) {
          result.push(char);
        }
      }
    }

    return result.join("");
  }

  function substituteTemplatePlaceholdersWithInput(rawValue) {
    const indexesOfPlaceholders = getIndexesOfPlaceholders();
    const result = [...formatTemplate.split("")];
    let index;

    if (rawValue) {
      rawValue = rawValue.split("");

      for (index = 0; index < rawValue.length; index += 1) {
        result[indexesOfPlaceholders[index]] = rawValue[index];
      }
    }

    return result.join("");
  }

  function setUpPassedRef(element) {
    typeof ref === "function" && ref(element);
    inputRef.current = element;
  }
});

FormattedInput.propTypes = {
  /**
   * List of keys that will be accepted as valid input. This array should be single characters strings like:
   * '0123456789'. For the sake of simplicity, there is no logic to determine if "invalid" characters are pasted into
   * the field.
   */
  acceptableCharacters: PropTypes.string,

  /** aria-describedby id to element which provides additional accessibility description of input element. */
  "aria-describedby": PropTypes.string,

  /** aria-label text to provide additional accessibility description of input element. */
  "aria-label": PropTypes.string,

  /** aria-labelledby id to element which provides additional accessibility description of input element. */
  "aria-labelledby": PropTypes.string,

  /** HTML input element attribute for autoComplete. */
  autoComplete: PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Optional function to manipulate incorrectly formatted value, specially when working with browser auto fill. */
  customTemplateReplacement: PropTypes.func,

  /** Name to use for the data-test attribute used for automated testing. */
  "data-test": PropTypes.string,

  /**
   * Initial value for input. If given, Input assumes that the component will not be "controlled" and the value will
   * be maintained in the DOM.
   */
  defaultValue: PropTypes.string,

  /** Indicates Input should apply disabled styling and ignore mouse and key events. */
  disabled: PropTypes.bool,

  /** Indicates Input should apply error styling and apply aria-invalid attribute. */
  error: PropTypes.bool,

  /**
   * String with "stationary" and "replacement" characters to automatically format user input. Examples:
   * __/__/____ and (___) ___-____ where all underscores will be replaced with user input.
   */
  formatTemplate: PropTypes.string,

  /** ID to be added to input element. */
  id: PropTypes.string,

  /** Optional class to be added to input element. The className prop is added to the input element's container. */
  inputClassName: PropTypes.string,

  /** Optional string to tell mobile device browsers to display an appropriate keyboard for the type of input. */
  inputMode: PropTypes.oneOf(["email", "none", "numeric", "tel", "text"]),

  /** Name to be added to input element. */
  name: PropTypes.string,

  /** Optional event handler to learn when focus is lost from input element. */
  onBlur: PropTypes.func,

  /** Optional event handler to learn when input element's content is changed. */
  onChange: PropTypes.func,

  /** Optional event handler to learn when input element is clicked. */
  onClick: PropTypes.func,

  /** Optional event handler to learn when focus is given to input element. */
  onFocus: PropTypes.func,

  /**
   * Optional event handler to learn when an input event is fired. The first parameter passed is current field value;
   * second parameter is a boolean to indicate if the input field is being edited versus initial entry.
   */
  onInput: PropTypes.func,

  /** Optional event handler to learn when a key is pressed. */
  onKeyDown: PropTypes.func,

  /** Optional event handler to learn when a mouse button pressed. */
  onMouseDown: PropTypes.func,

  /** Indicates Input should be immutable and ignore mouse and key events. */
  readOnly: PropTypes.bool,

  /** Indicates Input should apply aria-required attribute. */
  required: PropTypes.bool,

  /** Indicates the height and padding of the input element. */
  size: PropTypes.oneOf(["large", "small"]),

  /** Indicates the style of the input element. */
  styleType: PropTypes.oneOf(["primary", "secondary"]),

  /** Icon that can be placed at end of input field. See Input for more details. */
  suffixIcon: inputPropTypes.suffixIcon,

  /** Type to be added to input element, such as 'text' or 'email'. */
  type: PropTypes.string,

  value: PropTypes.string,
};
