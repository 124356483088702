import { StateAndCountryAutocomplete } from "../StateAndCountryAutocomplete";
import { withFormField } from "../withFormField";

/**
 * StateFormField provides support for a StateAndCountryAutocomplete component to be used in a Form.
 */

export const StateFormField = withFormField(StateAndCountryAutocomplete, {
  useController: true,
});
