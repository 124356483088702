import PropTypes from "prop-types";
import React from "react";

import { SMALL, useDeviceInfo } from "@swa-ui/browser";
import { Icon, Link } from "@swa-ui/core";
import i18n from "@swa-ui/locale";

import { CallToAction } from "../../CallToAction";
import { TextContent } from "../../TextContent";
import styles from "./FeatureItem.module.scss";

export const FeatureItem = (props) => {
  const { callToAction, image, link, text } = props;
  const { primary, secondary } = text || {};
  const { screenSize } = useDeviceInfo();

  return (
    <>
      {image && renderImage()}
      {renderPrimaryText()}
      {renderSecondaryText()}
      {renderCallToAction()}
    </>
  );

  function renderImage() {
    const imageContent = <img {...getImageProps()} />;

    return link ? (
      <Link {...getLinkProps()} className={styles.image}>
        {imageContent}
      </Link>
    ) : (
      imageContent
    );
  }

  function renderPrimaryText() {
    const { prefixIcon, value } = primary || {};
    const primaryText = (
      <div className={styles.primaryText}>
        {prefixIcon?.name && <Icon {...getIconProps()} />}
        <TextContent {...primary} />
      </div>
    );

    return value && link ? (
      <Link {...getLinkProps()} className={styles.link}>
        {primaryText}
      </Link>
    ) : (
      primaryText
    );
  }

  function renderSecondaryText() {
    return <TextContent {...secondary} />;
  }

  function getImageProps() {
    const { altText, path } = image;

    return {
      alt: altText ?? "",
      className: styles.image,
      src: path,
    };
  }

  function getLinkProps() {
    const { ariaLabel, externalLink, newWindow, target } = link;

    return {
      "aria-label": ariaLabel,
      external: externalLink,
      href: target,
      newWindow,
      newWindowDescription: i18n("FeatureItem__OPENS_NEW_WINDOW"),
    };
  }

  function getIconProps() {
    const { prefixIcon } = primary;
    const { color, name, size } = prefixIcon;

    return {
      "aria-label": name,
      className: styles.icon,
      color,
      name,
      size,
    };
  }

  function renderCallToAction() {
    return callToAction && <CallToAction {...getCallToActionProps()} />;
  }

  function getCallToActionProps() {
    return {
      ...callToAction,
      ...(screenSize === SMALL && { additionalProps: { fullWidth: true } }),
    };
  }
};

FeatureItem.propTypes = {
  /** Props for Call To Action. */
  callToAction: PropTypes.shape({
    buttonType: PropTypes.string,
    newWindow: PropTypes.bool,
    target: PropTypes.string,
    targetType: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.string,
  }),

  /** Placement image. */
  image: PropTypes.shape({
    /** Placement image alt text. */
    altText: PropTypes.string,

    /** Placement image URL. */
    path: PropTypes.string,
  }),

  /** Placement link. */
  link: PropTypes.shape({
    /** Aria label text for the link. */
    ariaLabel: PropTypes.string,

    /** External link flag. */
    externalLink: PropTypes.bool,

    /** Flag to open link on a new window. */
    newWindow: PropTypes.bool,

    /** Destination page for the link. */
    target: PropTypes.string.isRequired,
  }),

  /** Placement text. */
  text: PropTypes.shape({
    /** Primary text on placement. */
    primary: PropTypes.shape({
      /** Color of the primary text. */
      color: PropTypes.string,

      /** Font size of the primary text. */
      fontSize: PropTypes.string,

      /** Font weight of the primary text. */
      fontWeight: PropTypes.string,

      /** Icon that goes before the primary text. */
      prefixIcon: PropTypes.shape({
        /** Color of the prefix icon. */
        color: PropTypes.string,

        /** Name of the prefix icon. */
        name: PropTypes.string,

        /** Size of the prefix icon. */
        size: PropTypes.string,
      }),

      /** Text for placement. */
      value: PropTypes.string,
    }),

    /** Secondary text on placement. */
    secondary: PropTypes.shape({
      /** Text for placement. */
      value: PropTypes.string,
    }),
  }),
};
