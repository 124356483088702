import PropTypes from "prop-types";
import React, { useContext, useRef } from "react";

import { AppSettingsContext } from "@swa-ui/application";
import { AuthContext, LoginForm } from "@swa-ui/authentication";
import { getBootstrapData } from "@swa-ui/bootstrap";
import { Button, Caption, Link } from "@swa-ui/core";
import { useCaption } from "@swa-ui/core";
import { VacationsLightTheme } from "@swa-ui/design-tokens";
import i18n from "@swa-ui/locale";
import { PersonasContext } from "@swa-ui/personas";
import { PlacementFactory } from "@swa-ui/placements";
import { HtmlValue, usePlacement } from "@swa-ui/placements";

import { LocaleSwitcher } from "../LocaleSwitcher";
import { CustomerInformation } from "./CustomerInformation";
import styles from "./DesktopHeaderControl.module.scss";
import { ThemeSelector } from "./ThemeSelector";

/**
 * DesktopHeaderControlLeisure provides the account and locale type controls in the header for a Leisure Customer.
 */
export const DesktopHeaderControlLeisure = (props) => {
  const { light } = props;
  const { appSettings } = useContext(AppSettingsContext);
  const { captionRef, captionLocation, hideCaption, isCaptionVisible, showCaption } =
    useCaption("below");
  const { placement: globalNavHeader } = usePlacement("globalNavHeader");
  const { isLoggedIn } = useContext(AuthContext);
  const { persona } = useContext(PersonasContext);
  const loggedIn = isLoggedIn();

  const menuItemRefs = useRef([]);

  return (
    <>
      {appSettings.themeSelector && <ThemeSelector />}
      {appSettings.loginAvailable && loggedIn && (
        <CustomerInformation
          accountLinkText="DesktopHeaderControl__MY_ACCOUNT"
          light={light}
          separator={false}
        />
      )}
      {appSettings.loginAvailable && !loggedIn && renderLoggedOut()}
      {globalNavHeader?.globalNavControlLinks &&
        globalNavHeader?.globalNavControlLinks.map((menuItem, index) => (
          <Link key={menuItem.value ?? index} {...getMenuItemProps(menuItem, index)}>
            <HtmlValue htmlValue={menuItem.value} />
          </Link>
        ))}
      <LocaleSwitcher light={light} />
    </>
  );

  function renderLoggedOut() {
    const { LOGIN__ENROLL_MEMBER } = getBootstrapData("urls");

    return (
      <>
        <PlacementFactory additionalProps={{ light }} id="loginTextInfo" />
        <Caption {...getLoginCaptionProps()}>
          <Button {...getLoginButtonProps()}>{i18n("DesktopHeaderControl__LOG_IN")}</Button>
        </Caption>
        <Link
          className={styles.createAccountOption}
          emphasis
          href={LOGIN__ENROLL_MEMBER}
          light={light}
          size="fontSize13"
        >
          {i18n("DesktopHeaderControl__CREATE_ACCOUNT")}
        </Link>
      </>
    );
  }

  function getLoginCaptionProps() {
    const ThemeWrapper = persona === "vacations" ? VacationsLightTheme : React.Fragment;

    return {
      adjoiningContent: (
        <ThemeWrapper>
          <div className={styles.loginPopup} ref={captionRef}>
            <LoginForm onLoggedIn={handleLoggedIn} />
          </div>
        </ThemeWrapper>
      ),
      alignment: "center",
      bestFit: false,
      location: captionLocation,
      pointerAlignment: "center",
      pointerXOffset: 0,
      role: "navigation",
      showPointer: true,
      stackingContext: isCaptionVisible,
    };
  }

  function getMenuItemProps(menuItem, menuItemIndex) {
    const { externalLink, href, newWindow, url } = menuItem;

    return {
      className: styles.menuItem,
      emphasis: true,
      external: externalLink,
      href: href ?? url,
      light,
      newWindow,
      ref: (element) => (menuItemRefs.current[menuItemIndex] = element),
      size: "fontSize13",
    };
  }

  function handleLoggedIn() {
    hideCaption();
  }

  function getLoginButtonProps() {
    return {
      onClick: () => {
        isCaptionVisible ? hideCaption() : showCaption();
      },
      prefixIcon: { name: "Passenger" },
      size: "small",
      styleType: "primary",
    };
  }
};

DesktopHeaderControlLeisure.propTypes = {
  /** Indicates whether the app is using a dark Header background, to be passed to Link component for styling. */
  light: PropTypes.bool,
};
