import PropTypes from "prop-types";
import React, { useState } from "react";

import { classNames } from "@swa-ui/string";

import styles from "./Background.module.scss";

const COLORS = [
  "black",
  "caution",
  "danger",
  "disabled",
  "error",
  "information",
  "neutral",
  "primary",
  "secondary",
  "success",
  "tertiary",
  "transparent",
  "warning",
  "white",
];
const HEIGHTS_LINE = "2px";
const HEIGHTS_LINE_HEIGHT = "2rem";

/**
 * The Background component renders a background for a component. The background can either be an
 * image and/or a background color. For backgrounds without imageUrl, the background can be a shape,
 * like a circle.
 */

export const Background = (props) => {
  const {
    backgroundAttachment,
    backgroundPosition,
    border,
    className,
    color,
    gradient,
    height,
    imageUrl,
    immediate,
    shape,
    size,
    style,
    watermark,
  } = props;
  const [currentOpacity, setCurrentOpacity] = useState(gradient ? 1 : 0);
  const backgroundImage = new Image();
  const isImage = imageUrl?.length;

  if (isImage) {
    backgroundImage.onload = function () {
      setCurrentOpacity(1);
    };
    backgroundImage.src = imageUrl;
  }

  return (
    <div {...getProps()}>
      {watermark && renderWatermark()}
      {props.children}
    </div>
  );

  function renderWatermark() {
    return (
      <div className={styles.watermark}>
        <div className={styles.content}>{watermark}</div>
      </div>
    );
  }

  function getProps() {
    return {
      className: getClass(),
      "data-test": props["data-test"],
      style:
        gradient || isImage
          ? {
              backgroundAttachment,
              backgroundImage: gradient || `url(${imageUrl})`,
              backgroundPosition,
              opacity: currentOpacity,
              ...getStyle(),
            }
          : getStyle(),
    };
  }

  function getClass() {
    const isWidthNeeded = height && (shape === "circle" || shape === "square");

    return classNames(className, {
      [styles.backgroundColor]: color,
      [styles.borderColor]: border,
      [styles.borderRadius]: border?.radius,
      [styles.borderStyle]: border,
      [styles.borderWidth]: border,
      [styles.capsule]: shape === "capsule",
      [styles.circle]: shape === "circle",
      [styles.contain]: isImage && size === "contain",
      [styles.cover]: isImage && size === "cover",
      [styles.ellipse]: shape === "ellipse",
      [styles.height]: height,
      [styles.image]: isImage,
      [styles.immediate]: isImage && immediate,
      [styles.roundedRectangle]: shape === "roundedRectangle",
      [styles.shape]: !isImage && !watermark,
      [styles.square]: shape === "square",
      [styles.width]: isWidthNeeded && height !== "line" && height !== "lineHeight",
    });
  }

  function getStyle() {
    const borderColor = border?.color ?? "neutral";
    const borderRadius = border?.radius;
    const borderStyle = border?.style ?? "solid";
    const borderWidth = border?.width ?? "small";
    const heights = {
      line: HEIGHTS_LINE,
      lineHeight: HEIGHTS_LINE_HEIGHT,
    };
    const backgroundSize = heights[height] || `var(--theme-height-${height})`;

    return {
      "--background-color": `var(--theme-${color})`,
      "--border-color": `var(--theme-${borderColor})`,
      "--border-radius": borderRadius && `var(--theme-border-${border.radius})`,
      "--border-style": borderStyle,
      "--border-width": `var(--theme-border-${borderWidth})`,
      ...style,
      ...(height && { "--height": backgroundSize, "--width": backgroundSize }),
    };
  }
};

export const backgroundPropTypes = {
  /** Standard CSS background attachement property. */
  backgroundAttachment: PropTypes.string,

  /** Standard CSS background position property. */
  backgroundPosition: PropTypes.string,

  /** Optional border to surround shape. Not applied to images. */
  border: PropTypes.shape({
    color: PropTypes.oneOf(COLORS),
    radius: PropTypes.oneOf(["large", "medium", "small", "xlarge", "xsmall"]),
    style: PropTypes.oneOf([
      "dashed",
      "dotted",
      "double",
      "groove",
      "hidden",
      "inset",
      "outset",
      "ridge",
      "solid",
    ]),
    width: PropTypes.oneOf(["large", "medium", "small", "xlarge", "xsmall"]),
  }),

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Option color definition to be given for background color. */
  color: PropTypes.oneOf(COLORS),

  /**
   * Gradient definition to be used for CSS backgroundImage property.
   * Examples:
   * "linear-gradient(90deg, var(--primary-500), var(--primary-700))"
   * "linear-gradient(90deg, var(--primary-500) 0%, var(--primary-700) 100%)"
   */
  gradient: PropTypes.string,

  /**
   * Optional minimum height to be given to Background. For circle or square shapes, height will
   * also define the background's width.
   */
  height: PropTypes.oneOfType([
    PropTypes.oneOf(["large", "line", "lineHeight", "medium", "small", "xlarge", "xsmall"]),
    PropTypes.number,
  ]),

  /** Sets the background image URL. */
  imageUrl: PropTypes.string,

  /** If immediate is true, no animation will occur and the background will be displayed immediately. */
  immediate: PropTypes.bool,

  /** Defines if a specific background shape is desired such as a circle or capsule. */
  shape: PropTypes.oneOf([
    "capsule",
    "circle",
    "ellipse",
    "rectangle",
    "roundedRectangle",
    "square",
  ]),

  /**
   * Size determines how the background will fill the window. For more information, research
   * background properties contain and cover. For cover, the background-position is set to center.
   */
  size: PropTypes.oneOf(["contain", "cover"]),

  /** CSS styles to be applied to Background outer element. */
  style: PropTypes.object,

  /**
   * Content to be displayed for an element's background. This would typically be a light colored
   * image, but can be whatever is needed.
   */
  watermark: PropTypes.node,
};

Background.propTypes = backgroundPropTypes;
Background.defaultProps = {
  backgroundAttachment: "fixed",
  backgroundPosition: "center top",
  color: "transparent",
  imageUrl: "",
  shape: "rectangle",
  size: "cover",
};
