import PropTypes from "prop-types";
import React from "react";

import { DeviceInfoProvider } from "@swa-ui/browser";

import { ConfigurationProvider } from "../ConfigurationContext";

/**
 * All providers used by the core. Applications will use this to set up providers.
 * Future providers can be added here without any app changes.
 */

export const CoreProviders = (props) => {
  const { children, configuration, deviceInfoConfiguration } = props;

  return (
    <ConfigurationProvider configuration={configuration}>
      <DeviceInfoProvider {...deviceInfoConfiguration}>{children}</DeviceInfoProvider>
    </ConfigurationProvider>
  );
};

CoreProviders.propTypes = {
  /** Content that will be rendered with all core context providers. */
  children: PropTypes.node,

  /** Configuration object used to configure the core. */
  configuration: PropTypes.shape({
    iconMapping: PropTypes.object,
  }),

  /** DeviceInfoProvider configuration used to configure device info processing. */
  deviceInfoConfiguration: PropTypes.object,
};

CoreProviders.defaultProps = {
  deviceInfoConfiguration: {},
};
