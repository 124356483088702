import PropTypes from "prop-types";
import { useContext } from "react";

import { DesignTokensThemeContext } from "../DesignTokensThemeProvider";

/**
 * useDesignTokensTheme uses React's useContext hook and Provider to return a className with component
 * styleType design tokens.
 */

export const useDesignTokensTheme = (props) => {
  const { component, styleType } = props;
  const { themePrefix = "" } = useContext(DesignTokensThemeContext) ?? {};

  return { themeClass: `${themePrefix}${component}-${styleType}` };
};

useDesignTokensTheme.propTypes = {
  /** Lowercased component name, for example "button".  */
  component: PropTypes.string.isRequired,

  /** Lowercased component styleType, for example "primary". */
  styleType: PropTypes.string.isRequired,
};
