import React, { useEffect, useState } from "react";

import { ErrorBoundary } from "@swa-ui/error";

import { PlacementsUpdater } from "./PlacementsUpdater";

/**
 * The Content Service Toolbox allows internal testing for NextGen placement api calls under specific conditions, and can be displayed by pressing Shift+F3.
 */

export const Toolbox = () => {
  const [displayToolbox, setDisplayToolbox] = useState(false);

  useEffect(() => {
    window.addEventListener("keyup", displayContentServiceToolboxOnShiftF3); // NOSONAR

    return () => {
      window.removeEventListener("keyup", displayContentServiceToolboxOnShiftF3);
    };
  }, [displayToolbox]);

  return (
    <ErrorBoundary fallbackComponent={() => null}>
      {displayToolbox ? <PlacementsUpdater displayToolbox={displayToolbox} /> : null}
    </ErrorBoundary>
  );

  function displayContentServiceToolboxOnShiftF3(event) {
    if (event.keyCode === 114 && event.shiftKey) {
      setDisplayToolbox(!displayToolbox);
    }
  }
};

Toolbox.propTypes = {};
Toolbox.defaultProps = {};
