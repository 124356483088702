import PropTypes from "prop-types";
import React from "react";

import { useDeviceInfo } from "@swa-ui/browser";
import { Heading, ListMatrix } from "@swa-ui/core";

import { getBackgroundStyle } from "../Banner/BannerUtils";
import { CallToAction } from "../CallToAction";
import styles from "./BannerGridLayout2.module.scss";
import { callToActionPropType } from "./utils";

const BannerGridLayout2 = ({ background, title, callToAction, items }) => {
  const { screenSize } = useDeviceInfo();

  return (
    <div className={styles.backgroundContainer} style={getBackgroundStyle(background)}>
      <div {...getHeaderContainerProps()}>
        <Heading {...getHeadingProps()} />
      </div>
      <ListMatrix {...getListMatrixProps()} />
      <div className={styles.ctaContainer}>{renderCallToAction()}</div>
    </div>
  );

  function renderCallToAction() {
    return callToAction && <CallToAction {...callToAction} />;
  }

  function getHeaderContainerProps() {
    return {
      className: styles.headerContainer,
    };
  }

  function getHeadingProps() {
    return {
      children: <span {...getStyleProps(title)}>{title.value}</span>,
      headingLevel: 4,
      styleLevel: 3,
    };
  }

  function getStyleProps(styleProps) {
    const { color, fontSize, fontWeight } = styleProps ?? {};

    return {
      style: {
        color,
        fontSize,
        fontWeight,
      },
    };
  }

  function getListMatrixProps() {
    const columnSizeMap = {
      large: 4,
      medium: 3,
      small: 1,
      xlarge: 4,
    };
    const itemsLength = items.length;
    const maxSize = columnSizeMap[screenSize];

    return {
      className: styles.layout2Images,
      itemClassName: styles.item,
      items: getItems(),
      layout: "grid",
      numberOfColumns: itemsLength > maxSize ? maxSize : itemsLength,
    };
  }

  function getItems() {
    return items.map((item) => ({
      content: [
        <div className={styles.images} key={item?.image?.path}>
          <img src={`${item?.image?.path}`} alt={item?.image?.altText} />
        </div>,
      ],
    }));
  }
};

BannerGridLayout2.propTypes = {
  callToAction: callToActionPropType,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        altText: PropTypes.string,
        path: PropTypes.string,
      }),
    })
  ).isRequired,
  layoutType: PropTypes.string.isRequired,
};

export default BannerGridLayout2;
