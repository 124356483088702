import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";

import { classNames } from "@swa-ui/string";

import { AriaLive } from "../AriaLive";
import { ConfigurationContext } from "../ConfigurationContext";
import { TransitionBlock, transitionBlockPropTypes } from "../TransitionBlock";
import styles from "./List.module.scss";

const CASCADE_DELAY = 22.5;
const TRANSITION_IN = { duration: 400, transformations: ["fadeReset"] };
const TRANSITION_INITIAL = { duration: 400, transformations: ["fade"] };
const TRANSITION_OUT = { duration: 200, transformations: ["fade"] };
const UNOPENED = -1;

/**
 * General use component to display a list of items that can be swapped out with another list of items. The initial
 * render of the list will fade in if the animation prop is set to 'default'. Subsequent renders, when an updated list
 * is given, can be "slid" in from the left or right based on what's given for animation.
 */

export const List = React.memo((props) => {
  const {
    animateInitialRender,
    animationToken,
    className,
    itemClassName,
    items,
    maxItemsToDisplay,
    onTransformationEnd,
    startingIndex,
    transformOrigin,
    transitionIn,
    transitionInitial,
    transitionOut,
    wrapperComponent,
    wrapperComponentProps,
  } = props;
  const [currentAnimationToken, setCurrentAnimationToken] = useState(animationToken);
  const [currentItems, setCurrentItems] = useState(items);
  const [initialRender, setInitialRender] = useState(true);
  const configurationContext = useContext(ConfigurationContext);
  const [open, setOpen] = useState(configurationContext?.open || UNOPENED);
  const itemRefs = useRef([]);

  useEffect(() => {
    setInitialRender(false);
  }, [initialRender]);

  useEffect(() => {
    setCurrentAnimationToken(animationToken);
    setCurrentItems(items);
  }, [animationToken, items]);

  return (
    <ConfigurationContext.Provider value={getContextValue()}>
      <AriaLive hiddenFromScreen={false}>
        <ul className={classNames(className, { [styles.listContainer]: open === UNOPENED })}>
          {currentItems && renderList()}
        </ul>
      </AriaLive>
    </ConfigurationContext.Provider>
  );

  function renderList() {
    const content = [];
    const lastItemToDisplay = getMaxPageNumber() * maxItemsToDisplay;
    let index;

    for (index = startingIndex; index < lastItemToDisplay; index += 1) {
      content.push(renderListItem(currentItems[index], index));
    }

    return content;
  }

  function renderListItem(item, index) {
    return index >= startingIndex && index < startingIndex + maxItemsToDisplay ? (
      <li {...getListItemProps(index)}>{renderContent(item, index)}</li>
    ) : null;
  }

  function renderContent(item, index) {
    let content = <TransitionBlock {...getTransitionBlockProps(index)}>{item}</TransitionBlock>;

    if (wrapperComponent) {
      const Component = wrapperComponent;

      content = <Component {...wrapperComponentProps}>{content}</Component>;
    }

    return content;
  }

  function getTransitionBlockProps(index) {
    const delay = { delay: (index - startingIndex) * CASCADE_DELAY };
    const lastItemToDisplay = Math.min(startingIndex + maxItemsToDisplay, currentItems.length);

    return {
      animationToken:
        initialRender && animateInitialRender
          ? "initial render"
          : `${currentAnimationToken}-${currentItems[index]?.key}`,
      className: classNames(styles.item, itemClassName),
      hideWhenOffScreen: false,
      key: index,
      onTransformationEnd:
        index === lastItemToDisplay - 1 && onTransformationEnd ? onTransformationEnd : undefined,
      transformOrigin,
      transitionIn: { ...transitionIn, ...delay },
      transitionInitial: getInitialTransition(),
      transitionOut: { ...transitionOut, ...delay },
    };
  }

  function getListItemProps(index) {
    return {
      className: classNames(styles.listItemContainer, { [styles.open]: open === index }),
      key: index - startingIndex,
      ref: (element) => (itemRefs.current[index] = element),
    };
  }

  function getContextValue() {
    return {
      ...configurationContext,
      ...{ handleCaption: [...(configurationContext?.handleCaption ?? []), handleCaption] },
    };
  }

  function handleCaption(captionInfo) {
    const { mainContent, open: opened } = captionInfo;

    setOpen(opened ? findParentListItemForTarget(mainContent) : UNOPENED);
  }

  function findParentListItemForTarget(target) {
    return itemRefs.current.findIndex((item) => item?.contains(target));
  }

  function getMaxPageNumber() {
    return (
      Math.floor(currentItems.length / maxItemsToDisplay) +
      (currentItems.length % maxItemsToDisplay ? 1 : 0)
    );
  }

  function getInitialTransition() {
    return transitionInitial
      ? transitionInitial
      : animateInitialRender
      ? TRANSITION_INITIAL
      : undefined;
  }
});

export const listPropTypes = {
  /** Indicator that the list will be shown via transition when first rendered. The transition props will be used. */
  animateInitialRender: PropTypes.bool,

  /** Unique id that forces an animation (re-render). See TransitionBlock for more details. */
  animationToken: transitionBlockPropTypes.animationToken,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Class to be added to each item's container. */
  itemClassName: PropTypes.string,

  /**
   * Content List to be displayed. No styling will be defined for each item, and all styling should be provided by
   * content being passed in.
   */
  items: PropTypes.arrayOf(PropTypes.node),

  /** Defines how items will be rendered. */
  maxItemsToDisplay: PropTypes.number,

  /** Callback to inform when a new set of content has been rendered. */
  onTransformationEnd: PropTypes.func,

  /** Index of first item to display. */
  startingIndex: PropTypes.number,

  /** Pertains to how things are rotated and scaled. See Transform for more info. */
  transformOrigin: transitionBlockPropTypes.transformOrigin,

  /** Array of transformations to use when a content change is animated in. */
  transitionIn: transitionBlockPropTypes.transitionIn,

  /** Animation options to apply to child content. See Transform for more info. */
  transitionInitial: transitionBlockPropTypes.transitionInitial,

  /** Array of transformations to use when a content change is animated out. */
  transitionOut: transitionBlockPropTypes.transitionOut,

  /**
   * Optional component to wrap content that will be transitioned when new content is given. The wrapper will not
   * transition which will provide a "less dynamic" looking transition.
   */
  wrapperComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),

  /** Props to be given to wrapperComponent. */
  wrapperComponentProps: PropTypes.object,
};

List.displayName = "List";
List.propTypes = listPropTypes;

List.defaultProps = {
  animation: "default",
  maxItemsToDisplay: 10,
  startingIndex: 0,
  transitionIn: TRANSITION_IN,
  transitionOut: TRANSITION_OUT,
};
