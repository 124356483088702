import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import colors from "../../defines/colors";

/**
 * Cargo simply renders the text "Cargo" using an SVG to match exist SWA Cargo SVG logo.
 */

const Cargo = (props) => {
  const { className, fillColor, height, role, strokeColor, style, width } = props;

  return (
    <svg {...getProps()}>
      <path
        d="
                M24.3,53.6 c-15,0-23.3-11.9-23.3-24.7 c0-12.8,8.6-24.4,23.5-24.4 c6,0,10,1.4,13.4,3.6 l-3.1,4.8
                c-2.7-1.8-6-3.1-10.5-3.1 c-11,0-16.6,9-16.6,18.9 c0,10.1,5.4,19.4,16.7,19.4
                c5.1,0,8.7-1.7,11.4-4l2.8,4.6 C36.4,51.1,31,53.6,24.3,53.6z

                M64.6,52.8 c-0.3-0.9-0.5-3-0.6-4.1 c-1.6,2-5,4.9-10.1,4.9 c-5.8,0-9.9-3.6-9.9-9.3
                c0-9,9.8-11.8,18.3-11.8 h1.4v-3 c0-4.1-1.4-6.7-6.6-6.7 c-4.8,0-7.6,2.6-8.9,3.6l-3-4.1
                c1.8-1.6,5.5-4.7,12.4-4.7 c7.9,0,12.1,3.6,12.1,11.6 v15.2 c0,3.7,0.4,6.5,0.9,8.2 H64.6z

                M63.7,36.7 h-1.4 c-7,0-12,1.9-12,7.4 c0,3.3,2.8,4.6,4.8,4.6
                c4.8,0,7.5-3.1,8.6-4.6 V36.7z

                M091.9,24.1 c-0.4-0.2-1.3-0.6-3.1-0.6 c-4.9,0-7.7,5.5-8.2,6.6 v22.7h-6 v-34.2 h6v5.2
                c1.4-2.3,4.3-6,9-6 c2.2,0,3.3,0.5,3.8,0.7 L091.9,24.1z

                M125.5,18.6 v4.5 h-5 c1.4,2,2.2,4.5,2.2,7.2 c0,7.2-5.3,12.5-13.9,12.5
                c-1.4,0-2.6-0.1-3.8-0.4 c-0.8,0.4-2.1,1.2-2.1,2.7 c0,1.8,2.1,2.5,5.3,2.6
                c7.4,0.4,17.5-0.1,17.5,10.5 c0,7.1-7.9,11.5-17.2,11.5
                c-8,0-14-2.8-14-9.5 c0-3.8,2.3-6.8,5.8-8.7 c-1.8-0.9-3.1-2.5-3.1-5 c0-2.5,1.6-4.5,3.6-5.5
                c-3.8-2.2-6.1-6-6.1-10.6 c0-7.3,5.4-12.5,14-12.5 c2.1,0,3.9,0.3,5.5,0.9 H125.5z

                M100.3,59.4 c0,3.1,2.5,5.7,8.4,5.7 c6.5,0,10.8-2.6,10.8-6.6
                c0-5-4.5-5-8.7-5.3 c-2.1-0.1-4.3-0.2-6.3-0.5 C101.7,54.3,100.3,56.6,100.3,59.4z

                M116.5,30.2 c0-4.6-2.9-7.9-7.9-7.9 s-7.9,3.3-7.9,7.9 s2.9,7.9,7.9,7.9 S116.5,34.9,116.5,30.2z

                M144.7,53.6 c-11.6,0-16.1-9.7-16.1-17.9 c0-8.1,4.3-18,15.9-18
                c11.6,0,16.5,9.7,16.5,17.8 C161.1,43.7,156.4,53.6,144.7,53.6z

                M144.5,22.7 c-6.8,0-9.5,5.7-9.5,12.9 c0,7.2,3,13.1,9.8,13.1
                c6.8,0,9.8-5.8,9.8-12.9 C154.6,28.6,151.2,22.7,144.5,22.7z"
      />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className: classNames(className),
      fill: fillColor,
      height,
      role,
      stroke: strokeColor,
      style,
      viewBox: "0 0 162 70",
      width,
    };
  }
};

Cargo.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Color name from color palette or name of an entry in color theme file, themes.js. */
  fillColor: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(colors)), PropTypes.string]),

  /** Indicates SVG's height. The width, if not given, will be sized according to SVG's aspect ratio. */
  height: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Color name from color palette or name of an entry in color theme file, themes.js. */
  strokeColor: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(colors)), PropTypes.string]),

  /** Indicates SVG's width. The height, if not given, will be sized according to SVG's aspect ratio. */
  width: PropTypes.string,
};

Cargo.defaultProps = {
  fillColor: colors["blue-1"],
  height: "32",
  role: "img",
};

export { Cargo };
