import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

import { focusFirstElement, focusLastElement } from "@swa-ui/browser";
import { classNames, getUniqueId } from "@swa-ui/string";

import globalRules from "../assets/styles/globalRules.module.scss";
import styles from "./focusContainer.module.scss";

const DELAY_FOR_ANIMATION = 500;

/**
 * Component that "constraints" the tabOrder so that tabbing will not cause focus to go outside FocusContainer's
 * children.
 */

export const FocusContainer = (props) => {
  const { children, className, doc, id, revealed } = props;
  const uniqueId = useRef(id || getUniqueId("focusContainer-id"));
  let timer;

  useEffect(() => {
    if (revealed) {
      timer = setTimeout(() => {
        focusFirstElement(`[id="${uniqueId.current}"]`, doc);
      }, DELAY_FOR_ANIMATION);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [revealed]);

  return (
    <div className={classNames(className, styles.focusContainer)} id={uniqueId.current}>
      <div {...getGuardrailProps(handleFirstFocus)}>first</div>
      {children}
      <div {...getGuardrailProps(handleLastFocus)}>last</div>
    </div>
  );

  function getGuardrailProps(focusHandler) {
    return {
      "aria-hidden": props["aria-hidden"] || !revealed,
      className: globalRules.hiddenFromScreen,
      onFocus: focusHandler,
      tabIndex: `${props["aria-hidden"] || !revealed ? -1 : 0}`,
    };
  }

  function handleFirstFocus() {
    focusLastElement(`[id="${uniqueId.current}"]`, doc);
  }

  function handleLastFocus() {
    focusFirstElement(`[id="${uniqueId.current}"]`, doc);
  }
};

FocusContainer.propTypes = {
  /** Controls the aria-hidden attribute to be applied to the guardrail element and set the tab index. */
  "aria-hidden": PropTypes.bool,

  /** Content that will be rendered in heading. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * By default this will the global document object, but can be overridden to support iFrames. This is used by
   * Caption to get a reference to a DOM element.
   */
  doc: PropTypes.object,
};
