import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { classNames } from "@swa-ui/string";

import colors from "../defines/colors";
import { FadingDot } from "../FadingDot";
import styles from "./Ripple.module.scss";

const DELAY_FOR_2ND_RIPPLE = 175;
const IMMEDIATE = 0.0001;
const STANDARD_DURATION = 400;
const STEP_ANIMATE = "animate";
const STEP_IDLE = "idle";
const STEP_NONE = "";

/**
 * The Ripple component shows a fading circle on the page that last less than a second. Ripple
 * requires the position css property of its parent be set to: absolute, fixed, relative or sticky.
 */

export const Ripple = React.memo((props) => {
  const { className, color, focalPoint, onTransformationEnd } = props;
  const [forceUpdate, setForceUpdate] = useState(false);
  const step = useRef(STEP_NONE);

  useEffect(() => {
    if (step.current === STEP_NONE) {
      step.current = STEP_IDLE;
      setForceUpdate(!forceUpdate);
    }
  }, [step.current]);

  return (
    <div className={classNames(className)}>
      <FadingDot {...getProps(false)} />
      <FadingDot {...getProps(true)} />
    </div>
  );

  function getProps(delayed) {
    const animating = isAnimating();

    return {
      className: styles.dot,
      color,
      delay: delayed && animating ? DELAY_FOR_2ND_RIPPLE : undefined,
      duration: animating ? STANDARD_DURATION : IMMEDIATE,
      focalPoint,
      onTransformationEnd: delayed ? handleTransformationEnd : undefined,
      to:
        step.current === STEP_NONE
          ? "FADE"
          : step.current === STEP_IDLE
          ? "FADED-SHRINK"
          : "FADE-GROW",
    };
  }

  function handleTransformationEnd() {
    if (step.current === STEP_IDLE) {
      step.current = STEP_ANIMATE;
      setForceUpdate(!forceUpdate);
    } else {
      step.current = STEP_IDLE;
      onTransformationEnd?.();
    }
  }

  function isAnimating() {
    return step.current === STEP_ANIMATE;
  }
});

Ripple.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** The ripple dots can use any of the predefined colors in the SWA palette. */
  color: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(colors)), PropTypes.string]),

  /** Position where center of child will render in pixels. */
  focalPoint: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),

  /** Callback that will be called when Ripple animation is complete. No arguments will be returned. */
  onTransformationEnd: PropTypes.func,
};
