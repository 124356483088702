import PropTypes from "prop-types";
import React, { createContext, useContext } from "react";

/**
 * DesignTokensThemeProvider provides the ability to apply nested themes within the same page,
 * including dark and light modes.
 */

export const DesignTokensThemeContext = createContext();
export const DesignTokensThemeProvider = (props) => {
  const { children, className, configuration, themeName } = props;
  const contextConfiguration = useContext(DesignTokensThemeContext);

  return (
    <DesignTokensThemeContext.Provider
      value={{ ...contextConfiguration, ...configuration, themePrefix: `${themeName}-` }}
    >
      <div className={className}>{children}</div>
    </DesignTokensThemeContext.Provider>
  );
};

DesignTokensThemeProvider.propTypes = {
  /** App that will be provided with configuration values. */
  children: PropTypes.node.isRequired,

  /** Additional classes for styling the component. */
  className: PropTypes.string,

  /** Configuration object used to override global values with local values. */
  configuration: PropTypes.object,

  /** Name of theme styles to be applied via context. */
  themeName: PropTypes.string,
};

DesignTokensThemeContext.displayName = "DesignTokensThemeContext";
