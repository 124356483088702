import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Transform } from "@swa-ui/core";
import { classNames } from "@swa-ui/string";

const DELAY_TO_ALLOW_FOR_SCROLL = 200;
const DURATION_ENTER = 200;
const DURATION_IDLE = 20;
const ENTER = "enter";
const INITIAL = "init";

/**
 * PageTransition detects when the router location has changed and transitions to the new page content.
 */

export const PageTransition = (props) => {
  const { children, className } = props;
  const location = useLocation();
  const transitions = {
    [ENTER]: [{ action: "opacity", amount: "1" }],
    [INITIAL]: [{ action: "opacity", amount: "0.1" }],
  };
  const [currentPathname, setCurrentPathname] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setCurrentPathname(location.pathname);
    }, DELAY_TO_ALLOW_FOR_SCROLL);
  }, [location.pathname]);

  return <Transform {...getTransformProps()}>{children}</Transform>;

  function getTransformProps() {
    const initOrTransition =
      location.pathname !== currentPathname || currentPathname === "" ? INITIAL : ENTER;

    return {
      className: classNames(className),
      duration: initOrTransition === ENTER ? DURATION_ENTER : DURATION_IDLE,
      key: "key",
      transformations: transitions[initOrTransition],
      transformOrigin: "center-top",
    };
  }
};

PageTransition.propTypes = {
  /** Content to be rendered on page. */
  children: PropTypes.node.isRequired,

  /** Optional class name to position page content. By-in-large, this shouldn't be needed. */
  className: PropTypes.string,
};
