import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import { classNames } from "@swa-ui/string";

import globalRules from "../assets/styles/globalRules.module.scss";
import { Button } from "../Button";
import { keyCodes } from "../defines/keyCodes";
import { Digit } from "../Digit";
import { FadingDot } from "../FadingDot";
import { Icon } from "../Icon";
import styles from "./NumberSelector.module.scss";

const DECREMENT = -1;
const DEFAULT_FONT_SIZE = 30;
const DOT_ANIMATION_DURATION = 300;
const INCREMENT = 1;
const OFF = 0;

/**
 * A component that allows the user to enter a numeric value by incrementing / decrementing the value
 * using plus and minus (+ / -) buttons.
 */

export const NumberSelector = React.forwardRef((props, ref) => {
  const { className, defaultValue, disabled, maximumValue, minimumValue, name, onChange, value } =
    props;
  const [on, setOn] = useState(OFF);
  const decrementRef = useRef();
  const incrementRef = useRef();
  const numberSelectorRef = ref || useRef();

  return (
    <div {...getProps()}>
      <Button {...getButtonProps(DECREMENT)}>
        {on === DECREMENT && <FadingDot {...getDotProps()} />}
        <Icon {...getIconProps(DECREMENT)} />
      </Button>
      <Digit {...getDigitProps()} />
      <span aria-live="polite" className={globalRules.hiddenFromScreen}>
        {`${props["aria-label-value"]} ${getValue()}`}
      </span>
      <Button {...getButtonProps(INCREMENT)}>
        {on === INCREMENT && <FadingDot {...getDotProps()} />}
        <Icon {...getIconProps(INCREMENT)} />
      </Button>
    </div>
  );

  function getProps() {
    return {
      className: classNames(className, styles.numberSelectorContainer),
      onKeyDown: handleKeyDown,
      ref: numberSelectorRef,
    };
  }

  function getButtonProps(direction) {
    const isDisabled = isButtonDisabled(direction);

    return {
      "aria-label":
        props[direction === DECREMENT ? "aria-label-decrement" : "aria-label-increment"],
      className: getButtonClass(direction),
      clickFeedback: "none",
      defaultValue,
      disabled: isDisabled,
      name,
      onClick: () => handleClick(direction),
      ref: direction === DECREMENT ? decrementRef : incrementRef,
      styleType: "no-style",
    };
  }

  function getDotProps() {
    return {
      className: classNames(styles.feedback, { [styles.none]: on !== OFF }),
      color: "number-selector-feedback",
      duration: DOT_ANIMATION_DURATION,
      fullWidth: true,
      onTransformationEnd: handleTransformationEnd,
      to: "FADED-GROW",
    };
  }

  function getIconProps(direction) {
    const isDisabled = isButtonDisabled(direction);

    return {
      className: classNames(styles.icon, { [styles.disabled]: isDisabled }),
      color: isDisabled ? "body-disabled" : "link",
      name: direction === DECREMENT ? "Minus" : "Plus",
      size: "size20",
      transparentBorder: true,
    };
  }

  function getDigitProps() {
    return {
      className: classNames(styles.numberCount, {
        [styles.disabled]: disabled,
      }),
      fontSize: getFontSize(),
      maxNumber: maximumValue,
      number: getValue(),
    };
  }

  function getButtonClass(direction) {
    return classNames(styles.numberSelectorButton, {
      [styles.disabled]: isButtonDisabled(direction),
    });
  }

  function handleKeyDown(event) {
    const { key } = event;

    if (key === keyCodes.KEY_DOWN || key === keyCodes.KEY_LEFT || key === "-") {
      event.preventDefault();
      event.stopPropagation();

      if (getValue() > minimumValue) {
        updateValue(DECREMENT);
      }
    } else if (key === keyCodes.KEY_UP || key === keyCodes.KEY_RIGHT || key === "+") {
      event.preventDefault();
      event.stopPropagation();

      if (getValue() < maximumValue) {
        updateValue(INCREMENT);
      }
    } else if (key >= "0" && key <= `${maximumValue}`) {
      const newValue = parseInt(key);

      if (newValue !== value) {
        onChange(newValue);
        setOn(newValue < value ? DECREMENT : INCREMENT);
        moveFocus(newValue);
      }
    }
  }

  function handleClick(direction) {
    updateValue(direction);
  }

  function handleTransformationEnd() {
    setOn(OFF);
  }

  function isButtonDisabled(direction) {
    return disabled || isDisabled(direction, getValue());
  }

  function updateValue(direction) {
    const newValue = getValue() + direction;

    onChange(newValue);
    setOn(direction);
    moveFocus(newValue);
  }

  function moveFocus(newValue) {
    if (isDisabled(DECREMENT, newValue)) {
      incrementRef.current.focus();
    } else if (isDisabled(INCREMENT, newValue)) {
      decrementRef.current.focus();
    }
  }

  function isDisabled(direction, newValue) {
    return direction === DECREMENT ? newValue <= minimumValue : newValue >= maximumValue;
  }

  function getValue() {
    return value ? value : defaultValue;
  }

  function getFontSize() {
    return parseInt(getCustomValue("number-selector-font-size", DEFAULT_FONT_SIZE));
  }

  function getCustomValue(propertyName, customDefaultValue) {
    return (
      window.getComputedStyle(document.body).getPropertyValue(`--${propertyName}`) ||
      customDefaultValue
    );
  }
});

NumberSelector.propTypes = {
  /** aria-label text to provide accessibility description for decrement button. */
  "aria-label-decrement": PropTypes.string.isRequired,

  /** aria-label text to provide accessibility description for increment button. */
  "aria-label-increment": PropTypes.string.isRequired,

  /** aria-label text to be read as the value is decremented/incremented. */
  "aria-label-value": PropTypes.string.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Optional initial default value for number selector. */
  defaultValue: PropTypes.number,

  /**
   * Indicates if the NumberSelector should accept events to change the value, and whether disabled
   * styling should be applied.
   */
  disabled: PropTypes.bool,

  /**
   * The maximum number that NumberSelector will allow. NumberSelector only allows for single
   * digits, so the maximum value cannot be greater than nine. Once reached, the increment button
   * will become disabled.
   */
  maximumValue: PropTypes.number,

  /**
   * The minimum number that the number selector will allow. Default is 0.
   * Once reached the decrement button will become disabled.
   */
  minimumValue: PropTypes.number,

  /** Name given to number selector when form is submitted. */
  name: PropTypes.string,

  /** A function that updates the value when a button is clicked. */
  onChange: PropTypes.func.isRequired,

  /** The value that will be displayed and modified. */
  value: PropTypes.number,
};

NumberSelector.displayName = "NumberSelector";
NumberSelector.defaultProps = {
  defaultValue: 0,
  maximumValue: 9,
  minimumValue: 0,
};
