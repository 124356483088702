import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { classNames } from "@swa-ui/string";

import { Transform } from "../Transform";
import styles from "./HorizontalGestureScroller.module.scss";

const MASK_FADE_SIZE = 50;
const MINIMUM_MOVE_DISTANCE = 12;

/**
 * HorizontalGestureScroller provides support to scroll left and right using touch and wheel gestures.
 * The actual scroll position is controlled by the calling component and will receive position updates
 * from the onMove callback.
 */

export const HorizontalGestureScroller = React.forwardRef((props, ref) => {
  const { children, className, moreContentIndicator, onFocus, onMove, role, scrollPosition } =
    props;
  const [, setForceUpdate] = useState(9999);
  const container = ref || useRef();
  const scrollOffset = useRef(scrollPosition || 0);
  const sizeRef = useRef();
  const touchMovement = useRef(0);
  const touchPosition = useRef(0);

  useEffect(() => {
    if (scrollPosition !== undefined) {
      scrollOffset.current = scrollPosition;
      setForceUpdate(scrollOffset.current);
    }
  }, [scrollPosition]);

  useEffect(() => {
    if (container.current) {
      container.current.addEventListener("wheel", handleWheel, {
        passive: false,
      });
    }

    return () => {
      if (container.current) {
        container.current.removeEventListener("wheel", handleWheel, {
          passive: false,
        });
      }
    };
  }, []);

  return (
    <div {...getProps()}>
      <div ref={sizeRef}>
        <Transform {...getTransformProps()}>
          <div {...getContainerProps()}>{children}</div>
        </Transform>
      </div>
    </div>
  );

  function getProps() {
    return {
      className: classNames(className, styles.horizontalGestureScroller),
      onFocus,
      ref: container,
      role,
    };
  }

  function getTransformProps() {
    let maskEnd = 0;
    let maskEndStart = 0;
    let maskStart = 0;
    let maskStartEnd = 0;

    if (container?.current && sizeRef?.current) {
      const containerWidth = getContainerWidth();
      const contentWidth = getContentWidth();

      maskEnd = containerWidth - scrollOffset.current;
      maskEndStart =
        maskEnd -
        Math.min(MASK_FADE_SIZE, -(containerWidth - (scrollOffset.current + contentWidth)));
      maskStart = -scrollOffset.current;
      maskStartEnd = -scrollOffset.current + Math.min(MASK_FADE_SIZE, -scrollOffset.current);
    }

    return {
      className: classNames({ [styles.moreContentIndicator]: moreContentIndicator }),
      style: {
        "--mask-end": `${maskEnd}px`,
        "--mask-end-start": `${maskEndStart}px`,
        "--mask-start": `${maskStart}px`,
        "--mask-start-end": `${maskStartEnd}px`,
      },
      transformations: [
        {
          action: "translateX",
          amount: `${scrollOffset.current}px`,
        },
      ],
    };
  }

  function getContainerProps() {
    return {
      className: styles.container,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
      onTouchEnd: handleTouchEnd,
      onTouchMove: handleTouchMove,
      onTouchStart: handleTouchStart,
    };
  }

  function handleMouseEnter() {
    document.body.classList.add(styles.preventGestureNavigation);
  }

  function handleMouseLeave() {
    document.body.classList.remove(styles.preventGestureNavigation);
  }

  function handleWheel(event) {
    const { deltaX, deltaY } = event;

    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      event.preventDefault();
      event.stopPropagation();

      deltaX < 0 ? moveLeft(deltaX) : moveRight(deltaX);
    }
  }

  function handleTouchEnd() {
    touchMovement.current = 0;
    touchPosition.current = 0;
  }

  function handleTouchMove(event) {
    const containerWidth = getContainerWidth();
    const contentWidth = getContentWidth();
    const currentPosition = event.changedTouches[0]?.pageX;
    let delta = Math.round(touchMovement.current + (touchPosition.current - currentPosition));

    event.preventDefault();
    event.stopPropagation();

    if (Math.abs(delta) > MINIMUM_MOVE_DISTANCE) {
      if (onMove) {
        if (delta < 0) {
          scrollOffset.current = Math.min(0, scrollOffset.current - delta);
        } else {
          scrollOffset.current = Math.max(
            containerWidth - contentWidth,
            scrollOffset.current - delta
          );
        }

        onMove(scrollOffset.current);
      } else {
        scrollOffset.current = Math.max(
          containerWidth - contentWidth,
          Math.min(scrollOffset.current - delta, 0)
        );

        setForceUpdate(scrollOffset.current);
      }
      delta = 0;
    }

    touchMovement.current = delta;
    touchPosition.current = currentPosition;
  }

  function handleTouchStart(event) {
    touchPosition.current = event.changedTouches[0]?.pageX;
  }

  function moveLeft(deltaX) {
    if (scrollOffset.current < 0) {
      scrollOffset.current = Math.min(0, scrollOffset.current - deltaX);
      onMove && onMove(scrollOffset.current);
      setForceUpdate(scrollOffset.current);
    }
  }

  function moveRight(deltaX) {
    const containerWidth = getContainerWidth();
    const contentWidth = getContentWidth();

    if (scrollOffset.current > containerWidth - contentWidth) {
      scrollOffset.current = Math.max(containerWidth - contentWidth, scrollOffset.current - deltaX);
      onMove && onMove(scrollOffset.current);
      setForceUpdate(scrollOffset.current);
    }
  }

  function getContainerWidth() {
    return Math.ceil(container.current.getBoundingClientRect().width);
  }

  function getContentWidth() {
    return Math.ceil(sizeRef.current.getBoundingClientRect().width);
  }
});

HorizontalGestureScroller.propTypes = {
  /** Content to be rendered. This is the content that will be scrolled if scrollOffset prop is given. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Denotes if a "faded edge" will be rendered to indicate more content is scrollable. */
  moreContentIndicator: PropTypes.bool,

  /** Callback to learn when a child of HGS receives focus. */
  onFocus: PropTypes.func,

  /**
   * Callback to receive updated scroll position. The horizontal change will be passed as the only parameter to the
   * callback. A negative value means the container is being scrolled left.
   */
  onMove: PropTypes.func,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Scroll position. Negative values will mean the children content has scrolled to the left of the visible area. */
  scrollPosition: PropTypes.number,
};

HorizontalGestureScroller.defaultProps = {
  moreContentIndicator: true,
  scrollPosition: 0,
};
