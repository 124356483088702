import { useEffect, useLayoutEffect, useMemo, useState } from "react";

import { NATIVE_APP_INTERFACE_NAMES, sendMessageToNativeApp } from "./sendMessageToNativeApp";
import { useMessagesFromNativeApp } from "./useMessagesFromNativeApp";

const { PAGE_RENDERED } = NATIVE_APP_INTERFACE_NAMES;

// eslint-disable-next-line no-unused-vars
export const useHybrid = ({ additionalMessagesFromNativeApp, appId, history, sendErrorLog }) => {
  const queryParams = new URLSearchParams(history?.location?.search);
  const webViewRouteChanged =
    history?.location?.state?.webViewRouteChanged || queryParams?.get("webViewRouteChanged");
  const [currentWebViewRoute, setCurrentWebViewRoute] = useState(webViewRouteChanged);

  useEffect(() => {
    if (webViewRouteChanged && webViewRouteChanged !== currentWebViewRoute) {
      setCurrentWebViewRoute(webViewRouteChanged);
    }
  }, [webViewRouteChanged]);

  const messagesFromNativeApp = useMemo(
    () =>
      useMessagesFromNativeApp({
        additionalMessagesFromNativeApp,
        appId,
        history,
      }),
    [additionalMessagesFromNativeApp, appId, history]
  );

  useLayoutEffect(() => {
    window.swa = window.swa || {};
    window.swa.webViewMessage = receiveMessageFromNativeApps;
  }, []);

  useEffect(() => {
    sendMessageToNativeApp(PAGE_RENDERED);
  }, []);

  useEffect(() => {
    currentWebViewRoute && sendMessageToNativeApp(PAGE_RENDERED, history?.location?.pathname);
  }, [currentWebViewRoute]);

  function receiveMessageFromNativeApps(messageId, value, state = "") {
    messagesFromNativeApp[messageId]?.(value, state);
  }
};
