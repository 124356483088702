import PropTypes from "prop-types";
import React from "react";

import { Button, Grid, Heading, HorizontalGestureScroller, HorizontalList } from "@swa-ui/core";
import { usePlacement } from "@swa-ui/placements";
import { classNames } from "@swa-ui/string";

import styles from "./DotcomHeaderMobileWithGlobalNav.module.scss";

/**
 * DotcomHeaderMobileWithGlobalNav provides the header with scrollable navigation links
 * instead of a hamburger menu.
 */
export const DotcomHeaderMobileWithGlobalNav = (props) => {
  const { control, pageTitle, uppercaseMenuItems } = props;
  const {
    placement: globalNavHeader = {
      globalNavLogo: {},
      globalNavPrimaryLinks: [],
    },
  } = usePlacement("globalNavHeader");

  return (
    <>
      <Grid className={classNames(styles.logoSection, styles.layout)}>
        <div className={styles.fullWidth}>{renderLogoSection()}</div>
      </Grid>
      <Grid className={classNames(styles.titleSection, styles.layout)}>
        <div className={styles.fullWidth}>{renderOptionsSection()}</div>
      </Grid>
      <Grid className={classNames(styles.navigationLinksSection, styles.layout)}>
        <div className={styles.fullWidth}>{renderNavigationLinksSection()}</div>
      </Grid>
    </>
  );

  function renderLogoSection() {
    return (
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <Button {...getLogoButtonProps()}>
            <img {...getLogoImageProps()} />
          </Button>
        </div>
        <span className={styles.controlContainer}>{control}</span>
      </div>
    );
  }

  function getLogoButtonProps() {
    const { altText, href, url } = globalNavHeader?.globalNavLogo;

    return {
      "aria-label": altText,
      className: styles.logo,
      href: href ?? url ?? "/",
      styleType: "no-style",
    };
  }

  function getLogoImageProps() {
    const { altText: alt, height, src, width } = globalNavHeader?.globalNavLogo;
    const imageWidth = width * 0.7;

    return {
      alt,
      height,
      role: "img",
      src,
      width: Number.isNaN(imageWidth) ? "auto" : imageWidth,
    };
  }

  function renderNavigationLinksSection() {
    return (
      <HorizontalGestureScroller softEdgeColor={"none"}>
        <HorizontalList {...getHorizontalListProps()} />
      </HorizontalGestureScroller>
    );
  }

  function getHorizontalListProps() {
    return {
      items: getNavigationLinks(),
      role: "menubar",
      separator: true,
      spacing: "medium",
      tagName: "link",
    };
  }

  function getNavigationLinks() {
    const { globalNavPrimaryLinks } = globalNavHeader;

    return globalNavPrimaryLinks.reduce((result, globalNavPrimaryLink) => {
      if (globalNavPrimaryLink?.action !== "GLOBALNAVSEARCH") {
        result.push({
          componentProps: {
            "aria-label": globalNavPrimaryLink.ariaLabel,
            className: classNames({
              [styles.menuItem]: true,
              [styles.menuItemUppercase]: uppercaseMenuItems,
            }),
            children: globalNavPrimaryLink.value,
            external: globalNavPrimaryLink.externalLink,
            href: globalNavPrimaryLink.url,
            newWindow: globalNavPrimaryLink.newWindow,
          },
        });
      }

      return result;
    }, []);
  }

  function renderOptionsSection() {
    return (
      !!pageTitle && (
        <div className={styles.container}>
          <Heading light styleLevel={4}>
            {pageTitle}
          </Heading>
        </div>
      )
    );
  }
};

DotcomHeaderMobileWithGlobalNav.propTypes = {
  /**
   * Header control component used to control items like login and locale switching
   */
  control: PropTypes.node,

  /** Page's title */
  pageTitle: PropTypes.string,

  /** Transforms menu item links to uppercase. */
  uppercaseMenuItems: PropTypes.bool,
};

DotcomHeaderMobileWithGlobalNav.defaultProps = {
  uppercaseMenuItems: true,
};
