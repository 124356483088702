import PropTypes from "prop-types";
import React from "react";

import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import { getStationNameAndState } from "../Stations";
import styles from "./TripOriginDestinationDetail.module.scss";

/**
 * TripOriginDestinationDetail displays the origin and destination of a reservation.
 */

export const TripOriginDestinationDetail = (props) => {
  const { bounds, className, tripType } = props;

  return <div {...getProps()}>{renderReservation()}</div>;

  function renderReservation() {
    return isMulticityReservation()
      ? renderMultiCityReservation()
      : renderOnewayOrRoundTripReservation();
  }

  function renderMultiCityReservation() {
    return bounds.map((bound, index) => (
      <span key={index}>
        {index === 0
          ? getBoundDetail(bound.destinationAirportCode, bound.originationAirportCode)
          : getBoundDetail(bound.destinationAirportCode)}
      </span>
    ));
  }

  function renderOnewayOrRoundTripReservation() {
    return getBoundDetail(bounds[0].destinationAirportCode, bounds[0].originationAirportCode);
  }

  function getBoundDetail(destination, origin = "") {
    return (
      <>
        <span>{getStationNameAndState(origin)}</span>
        <span className={styles.separator}>{` ${i18n("TripOriginDestinationDetail__TO")} `}</span>
        <span>{getStationNameAndState(destination)}</span>
      </>
    );
  }

  function isMulticityReservation() {
    return tripType === "MULTICITY";
  }

  function getProps() {
    return {
      className: classNames(className, styles.tripOriginDestinationDetail),
    };
  }
};

TripOriginDestinationDetail.propTypes = {
  /** Bounds information will be used to render origin and destination. */
  bounds: PropTypes.arrayOf(
    PropTypes.shape({
      destinationAirportCode: PropTypes.string.isRequired,
      originationAirportCode: PropTypes.string.isRequired,
    })
  ).isRequired,

  /** Additional classes for positioning the component. */
  className: PropTypes.string,

  /** Indicates the type of reservation */
  tripType: PropTypes.string.isRequired,
};
