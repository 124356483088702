import React, { useEffect, useState } from "react";

import { MfaContactSelectForm } from "../MfaContactSelectForm";
import { useMfaServices } from "./useMfaServices";

const MFA_STEP_CONTACT_SELECT = "MFA_STEP_CONTACT_SELECT";
const MFA_STEP_ENTER_PASSCODE = "MFA_STEP_ENTER_PASSCODE";

/**
 * MfaAuthorizer renders a div with children.
 */
export const MfaAuthorizer = () => {
  const [currentStep, setCurrentStep] = useState(MFA_STEP_CONTACT_SELECT);
  const [selectedContact, setSelectedContact] = useState();
  const [contacts, setContacts] = useState();
  const { getMfaContacts } = useMfaServices();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getMfaContacts().then((response) => {
      setContacts(response);
    });
  }, []);

  return (
    <>
      {currentStep === MFA_STEP_CONTACT_SELECT && (
        <MfaContactSelectForm
          contacts={contacts}
          loading={loading}
          onSubmit={handleContactSelectSubmit}
        />
      )}
      {currentStep === MFA_STEP_ENTER_PASSCODE && (
        <div>Enter passcode step: {JSON.stringify(selectedContact)}</div>
      )}
    </>
  );

  function handleContactSelectSubmit(formData) {
    setLoading(true);

    // TODO: Remove FAKE contact selection here
    setTimeout(() => {
      setLoading(false);
      setSelectedContact(formData.contact);
      setCurrentStep(MFA_STEP_ENTER_PASSCODE);
    }, 1000);
  }
};

MfaAuthorizer.propTypes = {};
