import PropTypes from "prop-types";
import React, { forwardRef, useState } from "react";

import { Input, inputPropTypes } from "../Input";

/**
 * Password component provides an input field with type 'password.
 */

export const Password = forwardRef((props, ref) => {
  const {
    className,
    disabled,
    error,
    hideAriaLabel,
    hideLabel,
    id,
    maxLength,
    minLength,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    readOnly,
    required,
    showAriaLabel,
    showLabel,
    size,
    styleType,
  } = props;
  const [show, setShow] = useState(false);

  return <Input {...getProps()} />;

  function getProps() {
    return {
      "aria-describedby": props["aria-describedby"],
      "aria-label": props["aria-label"],
      "aria-labelledby": props["aria-labelledby"],
      className,
      disabled,
      error,
      id,
      maxLength,
      minLength,
      name,
      onBlur,
      onChange,
      onFocus,
      option: {
        "aria-label": show ? hideAriaLabel : showAriaLabel,
        onClick: handleOptionClick,
        text: show ? hideLabel : showLabel,
      },
      placeholder,
      readOnly,
      ref,
      required,
      size,
      styleType,
      type: show ? "text" : "password",
    };
  }

  function handleOptionClick() {
    setShow(!show);
  }
});

Password.propTypes = {
  /** aria-describedby id to element which provides additional accessibility description of input element. */
  "aria-describedby": PropTypes.string,

  /** aria-label text to provide additional accessibility description of input element. */
  "aria-label": PropTypes.string,

  /** aria-labelledby id to element which provides additional accessibility description of input element. */
  "aria-labelledby": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Indicates Input should apply disabled styling and ignore mouse and key events. */
  disabled: PropTypes.bool,

  /** Indicates Input should apply error styling and apply aria-invalid attribute. */
  error: PropTypes.bool,

  /** aria-label text to provide additional accessibility description of hide label button. */
  hideAriaLabel: PropTypes.string,

  /**
   * Label to display on the Input's option button to "unhide" the password entry. This primary just switches the
   * input field from "password" to "text".
   */
  hideLabel: PropTypes.string,

  /** ID to be added to input element. */
  id: PropTypes.string,

  /** Optional maximum number of characters allowed in input element. */
  maxLength: PropTypes.number,

  /** Optional minimum number of characters allowed in input element. */
  minLength: PropTypes.number,

  /** Name to be added to input element. */
  name: PropTypes.string,

  /** Optional event handler to learn when focus is lost from input element. */
  onBlur: PropTypes.func,

  /** Optional event handler to learn when input element's content is changed. */
  onChange: PropTypes.func,

  /** Optional event handler to learn when focus is given to input element. */
  onFocus: PropTypes.func,

  /** Optional value to show when field is empty. */
  placeholder: PropTypes.string,

  /** Indicates Input should be immutable and ignore mouse and key events. */
  readOnly: PropTypes.bool,

  /** Indicates Input should apply aria-required attribute. */
  required: PropTypes.bool,

  /** aria-label text to provide additional accessibility description of show label button. */
  showAriaLabel: PropTypes.string,

  /**
   * Label to display on the Input's option button to "unhide" the password entry. This primary just switches the
   * input field from "password" to "text".
   */
  showLabel: PropTypes.string,

  /** Indicates the height and padding of the input element. */
  size: inputPropTypes.size,

  /** Indicates the style of the input element. */
  styleType: inputPropTypes.styleType,
};

Password.defaultProps = {
  type: "primary",
};
