import * as yup from "yup";

import i18n from "@swa-ui/locale";

export const getMfaContactSelectFormSchema = () =>
  yup.object().shape({
    contact: yup.string().required(),
    termsAgree: yup
      .boolean()
      .oneOf([true], i18n("MfaContactSelectForm__TERMS_AGREE_REQUIRED"))
      .required(),
  });
