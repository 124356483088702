import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import "../../generated/platforms/scss/customer-dark.module.scss";
import "../../generated/platforms/scss/customer.module.scss";
import { DesignTokensThemeProvider } from "../DesignTokensThemeProvider";

/**
 * Default customer dark theme.
 */

export const CustomerDarkTheme = (props) => {
  const { children } = props;

  return <DesignTokensThemeProvider {...getProps()}>{children}</DesignTokensThemeProvider>;

  function getProps() {
    return {
      className: classNames("customer-dark-theme", "customer-theme"),
      themeName: "customer",
    };
  }
};

CustomerDarkTheme.propTypes = {
  /** Content that will be rendered on the button. */
  children: PropTypes.node,
};
