import PropTypes from "prop-types";
import { useContext } from "react";
import { useLocation } from "react-router-dom";

import { PlacementsContext } from "../PlacementsContext";
import { processedPlacementsList } from "./processedPlacementsList";

/**
 * usePlacement retrieves the specified placement using the placement ID and allows for further data manipulation.
 */

export const usePlacement = (id, options = {}) => {
  const { pathname } = useLocation();
  const { additionalProps, preprocessResponse = true } = options;
  const { error, complete, loading, placements } = useContext(PlacementsContext);
  const isPlacementLoading = loading.current.pathname === pathname;
  const isComplete = complete?.current?.ok;
  const { content: rawPlacement, ...placementMetadata } = placements?.[id] ?? {};
  let placement = rawPlacement;

  if (shouldProcessPlacement()) {
    placement = processedPlacementsList[placement?.displayType](placement, additionalProps);
  }

  return {
    error,
    isComplete,
    isPlacementLoading,
    placement,
    placementMetadata,
  };

  function shouldProcessPlacement() {
    return !!(
      processedPlacementsList[placement?.displayType] &&
      preprocessResponse &&
      placement.placement
    );
  }
};

usePlacement.propTypes = {
  /**
   * Placement ID to use to identify content to render.
   */
  id: PropTypes.string.isRequired,

  /**
   * Object to pass options to usePlacement.
   */
  options: PropTypes.object,
};
