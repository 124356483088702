"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _useAllDestinationsPageAnalytics = require("./useAllDestinationsPageAnalytics");
Object.keys(_useAllDestinationsPageAnalytics).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useAllDestinationsPageAnalytics[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useAllDestinationsPageAnalytics[key];
    }
  });
});
var _useFeaturedDealsAnalytics = require("./useFeaturedDealsAnalytics");
Object.keys(_useFeaturedDealsAnalytics).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useFeaturedDealsAnalytics[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useFeaturedDealsAnalytics[key];
    }
  });
});
var _useRecentSearchesAnalytics = require("./useRecentSearchesAnalytics");
Object.keys(_useRecentSearchesAnalytics).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useRecentSearchesAnalytics[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useRecentSearchesAnalytics[key];
    }
  });
});
var _useTravelerSelectionModalAnalytics = require("./useTravelerSelectionModalAnalytics");
Object.keys(_useTravelerSelectionModalAnalytics).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useTravelerSelectionModalAnalytics[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useTravelerSelectionModalAnalytics[key];
    }
  });
});
var _useWhyGetawaysPageAnalytics = require("./useWhyGetawaysPageAnalytics");
Object.keys(_useWhyGetawaysPageAnalytics).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useWhyGetawaysPageAnalytics[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useWhyGetawaysPageAnalytics[key];
    }
  });
});