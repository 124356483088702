import { SuffixAutocomplete } from "../SuffixAutocomplete";
import { withFormField } from "../withFormField";

/**
 * SuffixFormField provides support for a SuffixAutocomplete component to be used in a Form.
 */

export const SuffixFormField = withFormField(SuffixAutocomplete, {
  useController: true,
});
