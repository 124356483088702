import PropTypes from "prop-types";
import React, { useContext } from "react";

import { ApplicationContext } from "@swa-ui/application";

import styles from "./DesktopHeaderControl.module.scss";
import { DesktopHeaderControlCorporate } from "./DesktopHeaderControlCorporate";
import { DesktopHeaderControlLeisure } from "./DesktopHeaderControlLeisure";

/**
 * DesktopHeaderControl provides the account and locale type controls for the header.
 */

export const DesktopHeaderControl = (props) => {
  const { light } = props;
  const { persona } = useContext(ApplicationContext);

  return (
    <div className={styles.headerControl}>
      {persona === "corporate" && <DesktopHeaderControlCorporate />}
      {persona === "leisure" && <DesktopHeaderControlLeisure light={light} />}
    </div>
  );
};

DesktopHeaderControl.propTypes = {
  /** Indicates whether the app is using a dark Header background, to be passed to Link component for styling. */
  light: PropTypes.bool,
};
