import { useContext, useEffect } from "react";

import { MboxContext } from "@swa-ui/application";

export const MboxScripts = () => {
  const { mboxes } = useContext(MboxContext);

  useEffect(() => {
    mboxes?.scripts?.forEach((mboxScript) => {
      const script = document.createElement("script");

      script.text = mboxScript.replace(/<\/?script>/g, "");
      document.body.appendChild(script);
    });
  }, [mboxes]);

  return null;
};
