import * as yup from "yup";

import { getLocationDataSchema } from "./getLocationDataSchema";

let schema;

export const getGeolocationSchema = () => {
  if (!schema) {
    schema = yup.object().shape({
      countryCode: yup.string().required(),
      isInternationalStation: yup.boolean(),
      locationData: getLocationDataSchema(),
      nearestStation: yup.string(),
    });
  }

  return schema;
};
