import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import { Button } from "../Button";
import { Link } from "../Link";
import styles from "./HorizontalList.module.scss";

/**
 * HorizontalList provides a consistent way to show a list of links/buttons or individual text items
 * with or without a separator between each item.
 */
export const HorizontalList = (props) => {
  const { className, emphasis, items, light, role, separator, spacing, tagName } = props;
  const computedRole = getRole();

  return (
    <ul className={getClass()} role={computedRole}>
      {items.map(renderItem)}
    </ul>
  );

  function renderItem(item, index) {
    const { componentProps } = item;
    const components = {
      button: Button,
      div: "div",
      link: Link,
      span: "span",
    };
    const tag = item.tagName || tagName;
    const Component = components[tag];
    const itemRole = getRole() === "menubar" ? "menuitem" : undefined;

    return (
      <li key={`${tag}-${index}`} role={itemRole}>
        <Component {...getProps(componentProps, tag)}>{componentProps.children}</Component>
        {index !== items.length - 1 ? (
          <span key={`separator-${index}`} {...getSeparatorProps()}>
            {renderSeparator()}
          </span>
        ) : null}
      </li>
    );
  }

  function renderSeparator() {
    return separator ? "|" : "";
  }

  function getProps(componentProps, tag) {
    const isDomTag = tag === "div" || tag === "span";
    const isEmphasis = componentProps.emphasis !== undefined ? componentProps.emphasis : emphasis;

    return {
      ...(!isDomTag && { light }),
      ...componentProps,
      className: classNames(componentProps.className, {
        [styles.emphasis]: isDomTag && isEmphasis,
        [styles.light]: isDomTag && light,
      }),
      emphasis: isDomTag ? undefined : isEmphasis,
    };
  }

  function getSeparatorProps() {
    return {
      "aria-hidden": true,
      className: classNames({
        [styles.light]: light,
        [styles.separator]: true,
      }),
    };
  }

  function getClass() {
    return classNames(className, styles.horizontalList, {
      [styles.spaceAround]: spacing === "space-around",
      [styles.spaceBetween]: spacing === "space-between",
      [styles.spaceEvenly]: spacing === "space-evenly",
      [styles.spacingLarge]: spacing === "large",
      [styles.spacingSmall]: spacing === "small",
    });
  }

  function getRole() {
    const anyTagActionable = items.some(
      (item) => item.tagName !== "div" && item.tagName !== "span"
    );
    let workingRole = role;

    if (!role) {
      workingRole =
        tagName === "div" || tagName === "span" || !anyTagActionable ? undefined : "menubar";
    }

    return workingRole;
  }
};

HorizontalList.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Allows all items, whether the items are components or DOM elements, to be bolded. */
  emphasis: PropTypes.bool,

  /** List of options to be rendered. */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      componentProps: PropTypes.object,
      tagName: PropTypes.oneOf(["button", "div", "link", "span"]),
    })
  ),

  /** Prop to be passed to child elements for styling on top of a dark background. */
  light: PropTypes.bool,

  /**
   * Role given to container. This will typically 'menubar', but when HorizontalList is used for a
   * legend, and there's no actionable items given in list, then role will be set to 'list'. This
   * prop will allow a custom role to be set if needed.
   */
  role: PropTypes.string,

  /** Each list item will be separated by a pipe symbol (|) unless this prop is false. */
  separator: PropTypes.bool,

  /** The amount of space before and after the heading can be set to one of the predefined settings. */
  spacing: PropTypes.oneOf([
    "large",
    "medium",
    "small",
    "space-around",
    "space-between",
    "space-evenly",
  ]),

  /**
   * Each item to be rendered can be specified separately, or if left unspecified, will default to
   * this tagName.
   */
  tagName: PropTypes.oneOf(["button", "div", "link", "span"]),
};

HorizontalList.defaultProps = {
  emphasis: true,
  separator: true,
  spacing: "medium",
  tagName: "link",
};
