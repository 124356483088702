export const useMfaServices = () => ({
  getMfaContacts: async () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve([
          { id: "IVAAVA12V", type: "EMAIL", value: "r*******************n@gmaillongdomain.com" },
          { id: "AKW23AFAF", type: "PHONE", value: "***-***-1459" },
        ]);
      }, 1000);
    }),
  sendMfaOtp: async () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    }),
  verifyMfaOtp: async () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    }),
});
