import { getBootstrapData } from "@swa-ui/bootstrap";

import { decodeMessage } from "./useMessagesFromNativeApp";

export const handleRouteChange = (history, appId, route, state) => {
  const { pathname, search } = new URL(route, window.location.origin);
  const decodedState = decodeMessage(state);
  const webViewRoutes = getBootstrapData("webview-routes");
  const routes = Object.assign({}, ...Object.values(webViewRoutes));
  const destination = routes?.[pathname] ?? "/blank";
  const separator = search ? "&" : "?";
  const url = `${destination}${search}`;

  Object.keys(webViewRoutes[appId]).includes(pathname)
    ? history?.replace?.(url, { ...decodedState, webViewRouteChanged: destination })
    : window.location?.replace?.(
        `${url}${separator}webView=true&hardLoad=true&webViewRouteChanged=${destination}`
      );
};
