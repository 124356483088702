import PropTypes from "prop-types";
import React from "react";

import { fireTrack } from "@swa-ui/analytics";
import { SMALL, useDeviceInfo } from "@swa-ui/browser";
import { Accordion, Heading } from "@swa-ui/core";
import i18n from "@swa-ui/locale";

import { CallToAction } from "../CallToAction";
import { HtmlValue } from "../HtmlValue";
import { getMissingTemplateKeys } from "../usePlacement/getMissingTemplateKeys";
import interpolate from "../usePlacement/interpolate";
import styles from "./AccordionBlock.module.scss";

/**
 * AccordionBlock renders a list of one or more Accordion components, with a main header and CTA Link.
 */

export const AccordionBlock = (props) => {
  const {
    additionalProps: { styleLevel } = {},
    placement,
    placement: { callToAction, heading, items, layoutType } = {},
    placementData,
  } = props;
  const { screenSize } = useDeviceInfo();
  const missingTemplateKeys = getMissingTemplateKeys(placement, placementData);
  const placementDataFormatted = { ...placementData };

  if (missingTemplateKeys.length) {
    missingTemplateKeys.forEach((key) => (placementDataFormatted[key] = ""));
  }

  return renderAccordionSection();

  function renderAccordionSection() {
    let content;

    if (layoutType === "compact") {
      content = screenSize === SMALL ? renderCompactSmallAccordion() : renderCompactAccordion();
    } else {
      content = renderDefaultAccordion();
    }

    return content;
  }

  function renderCompactSmallAccordion() {
    return (
      <>
        {renderHeading()}
        {renderAccordion()}
        {renderCallToAction()}
      </>
    );
  }

  function renderCompactAccordion() {
    return (
      <>
        <div className={styles.headingCtaContainer}>
          {renderHeading()}
          {renderCallToAction()}
        </div>
        {renderAccordion()}
      </>
    );
  }

  function renderDefaultAccordion() {
    return (
      <>
        <div className={styles.defaultHeading}>{renderHeading()}</div>
        {renderAccordion()}
        <div className={styles.callToAction}>{renderCallToAction()}</div>
      </>
    );
  }

  function renderHeading() {
    return (
      heading && (
        <Heading styleLevel={styleLevel}>
          {i18n(interpolate(heading, placementDataFormatted))}
        </Heading>
      )
    );
  }

  function renderCallToAction() {
    return callToAction && <CallToAction {...callToAction} />;
  }

  function renderAccordion() {
    return (
      items.length &&
      items.map((accordion) => {
        const { label } = accordion;
        const key = label?.value || label;

        return (
          <Accordion key={key} {...getAccordionProps(accordion)}>
            <HtmlValue htmlValue={i18n(interpolate(accordion.children, placementDataFormatted))} />
          </Accordion>
        );
      })
    );
  }

  function renderLabel(label) {
    let Node;

    if (label?.value) {
      Node = (
        <span style={{ ...label }}>{i18n(interpolate(label.value, placementDataFormatted))}</span>
      );
    } else {
      Node = i18n(interpolate(label, placementDataFormatted));
    }

    return Node;
  }

  function getAccordionProps(accordion) {
    const { id, label, revealed } = accordion;

    return {
      className: styles.accordion,
      fullWidthContent: true,
      id,
      label: renderLabel(label),
      name: createTrackingId(label),
      onChange: (revealState) => handleChange(revealState, label),
      revealed,
    };
  }

  function handleChange(revealState, label) {
    if (revealState) {
      fireTrack("squid", { page_description: label });
    }
  }

  function createTrackingId(label) {
    const name = label?.value || label;

    return name.replace(/\s+/g, "").substring(0, 20);
  }
};

AccordionBlock.propTypes = {
  /** Additional props to position the Accordion component. */
  additionalProps: PropTypes.object,

  /** Object that contains placement props, including templateKeys, heading, and items. */
  placement: PropTypes.shape({
    /** Object that contains link props. */
    callToAction: PropTypes.object,

    /** Heading creates a heading element for the specified heading level. */
    heading: PropTypes.string,

    /** Object that contains accordion props. */
    items: PropTypes.arrayOf(PropTypes.object),
  }),

  /** Object that contains string values corresponding to templateKeys. */
  placementData: PropTypes.object,
};
