/* eslint-disable prefer-arrow-callback */
import React, { useContext, useEffect, useMemo } from "react";

import { useAppSetting, usePrevious } from "@swa-ui/application";
import { getBootstrapData } from "@swa-ui/bootstrap";
import { Dialog } from "@swa-ui/core";
import {
  encodeMessage,
  isHybridEnabled,
  NATIVE_APP_INTERFACE_NAMES,
  sendMessageToNativeApp,
} from "@swa-ui/hybrid";

import { AuthContext } from "../../AuthProvider";
import { AuthResponseHandlerContext } from "../../AuthResponseHandler";
import { useUserInfo } from "../../UserInfoProvider";
import { LoginForm } from "../LoginForm";
import { MissingInformationRedirectDialog } from "../MissingInformationRedirectDialog";

/**
 * Renders Login or Missing Information Dialog based on response handling.
 */

export const AuthResponseDialog = () => {
  const { activeForm, pendingRetry, shouldCleanUpSession, setHasExistingAccountInfo } = useContext(
    AuthResponseHandlerContext
  );
  const {
    appendNativeLoginCancelHandlers,
    appendNativeLoginHandlers,
    cleanUpSession,
    isLimitedAccess,
  } = useContext(AuthContext);
  const dialogBody = useMemo(renderActiveForm, [activeForm?.type]); // NOSONAR
  const onReAuthCancel = useAppSetting("onReAuthCancel", handleReAuthCancel);
  const previousCancel = usePrevious(pendingRetry?.cancel);
  const previousNext = usePrevious(pendingRetry?.next);
  const { currentAccountNumber } = useUserInfo();

  useEffect(
    function handleCleanUpSession() {
      shouldCleanUpSession && cleanUpSession();
      shouldCleanUpSession &&
        isHybridEnabled() &&
        sendMessageToNativeApp(NATIVE_APP_INTERFACE_NAMES.LOGOUT);
    },
    [shouldCleanUpSession]
  );

  useEffect(
    function handleDisplayNativeLogin() {
      if (isHybridEnabled() && pendingRetry?.next && !previousNext) {
        const message = encodeMessage({
          continueAsGuest: false,
          loginType: "normal",
        });

        sendMessageToNativeApp(NATIVE_APP_INTERFACE_NAMES.DISPLAY_LOGIN, message);
        appendNativeLoginHandlers(pendingRetry?.next, pendingRetry?.url);
      }
    },
    [pendingRetry?.next]
  );

  useEffect(() => {
    setHasExistingAccountInfo(!!currentAccountNumber);
  }, [currentAccountNumber]);

  useEffect(
    function handleNativeLoginCancel() {
      if (isHybridEnabled() && pendingRetry?.cancel && !previousCancel) {
        const onNativeLoginCancel = () => {
          pendingRetry?.cancel?.();
          sendMessageToNativeApp(NATIVE_APP_INTERFACE_NAMES.EXIT);
        };

        appendNativeLoginCancelHandlers(onNativeLoginCancel, pendingRetry?.url);
      }
    },
    [pendingRetry?.cancel]
  );

  return renderResponseDialog();

  function renderResponseDialog() {
    let dialog;

    if (!isHybridEnabled() && dialogBody) {
      dialog = <Dialog {...getLoginDialogProps()}>{dialogBody}</Dialog>;
    } else if (!isHybridEnabled() && isLimitedAccess()) {
      dialog = <MissingInformationRedirectDialog />;
    }

    return dialog;
  }

  function getLoginDialogProps() {
    return {
      onClose: handleCancel,
      portal: false,
      revealed: !!dialogBody,
      showClose: true,
      width: "small",
    };
  }

  function renderActiveForm() {
    const loginFormProps = {
      formType: activeForm?.type,
      onCancel: handleCancel,
      onLoggedIn: handleLoggedIn,
    };

    return loginFormProps.formType && <LoginForm {...loginFormProps} />;
  }

  function handleCancel() {
    pendingRetry?.cancel?.();
    onReAuthCancel();
  }

  function handleLoggedIn() {
    pendingRetry?.next?.();
  }

  function handleReAuthCancel() {
    const { HOME_PAGE } = getBootstrapData("urls");

    cleanUpSession();
    window.location.replace(HOME_PAGE);
  }
};
