import PropTypes from "prop-types";
import React, { useContext } from "react";

import { classNames, convertCamelCaseToSpaceSeparated } from "@swa-ui/string";

import { ConfigurationContext } from "../ConfigurationContext";
import colors from "../defines/colors";
import { sizes } from "../defines/sizes";
import { Endow, endowPropTypes } from "../Endow";
import { getColorVariable } from "../getColorVariable";
import styles from "./Icon.module.scss";
import * as iconListWithoutAliases from "./icons";

const iconList = {
  ...iconListWithoutAliases,
  Airplane: iconListWithoutAliases["Flight"],
  ArrowThinLeft: iconListWithoutAliases["ArrowThinAdapter"],
  ArrowThinRight: iconListWithoutAliases["ArrowThinAdapter"],
  At: iconListWithoutAliases["Contact"],
  BidirectionalSort: iconListWithoutAliases["Reverse"],
  Phone: iconListWithoutAliases["MobilePhone"],
  Profile: iconListWithoutAliases["Passenger"],
  Tag: iconListWithoutAliases["Offers"],
  Twitter: iconListWithoutAliases["XCorp"],
};

/**
 * Icon provides an common entry point for all Icon SVGs, which are individual, separate components. Icon simply
 * instantiate the desired child component, but provides an Endow wrapper to enable transformation capabilities. See
 * Endow for more info.
 */

const ICON_DISABLED_DESIGN_TOKEN = "cmp-core-color-icon-fg-disabled";

export const Icon = (props) => {
  const {
    actions,
    background,
    border,
    className,
    color,
    custom,
    delay,
    disabled,
    duration,
    iconClassName,
    id,
    name,
    onClick,
    onTransformationEnd,
    role,
    shrink,
    size,
    style,
    tabIndex,
    transparentBorder,
  } = props;
  const configuration = useContext(ConfigurationContext);
  const iconName = configuration?.iconMapping?.[name] ?? name;
  const Component = iconList[iconName];

  return Component ? (
    <Endow {...getProps()}>
      <Component {...getIconProps()} />
    </Endow>
  ) : null;

  function getProps() {
    return {
      actions,
      background,
      border,
      className,
      delay,
      disabled,
      duration,
      height: sizes[size] || size,
      id,
      onClick,
      onTransformationEnd,
      style,
      tabIndex,
      width: sizes[size] || size,
    };
  }

  function getIconProps() {
    const adapterProps = { actions, color, name };
    const svgColor = disabled ? ICON_DISABLED_DESIGN_TOKEN : color;
    const svgColorAsVariable = getColorVariable(svgColor);

    return {
      ...getAriaProps(),
      className: getClass(),
      custom,
      role,
      size: getSize(),
      style: { fill: svgColorAsVariable, stroke: svgColorAsVariable },
      ...adapterProps,
    };
  }

  function getAriaProps() {
    const ariaProps = {
      "aria-hidden": props["aria-hidden"],
      "aria-label": convertCamelCaseToSpaceSeparated(props["aria-label"] || props.name),
    };

    return isApplicableForAccessibility() ? ariaProps : undefined;
  }

  function isApplicableForAccessibility() {
    return role !== "presentation" && role !== "none";
  }

  function getClass() {
    return classNames(styles.icon, iconClassName, {
      [styles.transparentBorder]: transparentBorder,
    });
  }

  function getSize() {
    let computedSize = sizes[size] || size;

    if (shrink) {
      computedSize = `${parseInt(computedSize) * shrink}`;
    }

    return computedSize;
  }
};

export const iconPropTypes = {
  /**
   * Icons can have generic "action" behaviors, such as rotating 180 degrees around the z or x axis.
   * For some actions, applying more than one behavior would cancel out the other.
   */
  actions: endowPropTypes.actions,

  /** aria-hidden text to hide non-interactive content from the accessibility API. */
  "aria-hidden": PropTypes.string,

  /** aria-label text to provide additional accessibility description of icon. */
  "aria-label": PropTypes.string,

  /** Optional background to surround icon. */
  background: endowPropTypes.background,

  /** Optional border to surround icon. */
  border: endowPropTypes.border,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Icons can use any standard brand color or the name of a CSS var. */
  color: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(colors)), PropTypes.string]),

  /**
   * Icon specific behavior can be called with values passed through this object. As an example, ArrowThin can accept
   * custom: { type: 'active' } to morph. Not all icons will implement custom behavior.
   */
  custom: PropTypes.object,

  /** Number of milliseconds before action transformations begin. */
  delay: endowPropTypes.delay,

  /** Indicates Icon should apply disabled styling. */
  disabled: endowPropTypes.disabled,

  /**
   * Duration of transformation when an action is animated. When the action(s) given do cause a transformation,
   * duration is irrelevant.
   */
  duration: endowPropTypes.duration,

  /** Class to be applied to icon Svg, not to the other container which how the className props is applied. */
  iconClassName: PropTypes.string,

  /** ID to be added to the element. */
  id: PropTypes.string,

  /** Name of icon such as Flight or Menu. */
  name: PropTypes.string.isRequired,

  /** Callback that will be called when Icon is clicked. */
  onClick: PropTypes.func,

  /** Informs the caller if actions is given that the animation has concluded. */
  onTransformationEnd: endowPropTypes.onTransformationEnd,

  /** Optional role property for accessibility. */
  role: PropTypes.string,

  /**
   * Giving this prop will "shrink" the icon to allow for a background or border. The value should be a number
   * representing a percentage. For example, passing 0.75 will shrink the icon to 75% of its specified size while
   * maintaining 100% of the specifiec size for the border/background.
   */
  shrink: PropTypes.number,

  /** Indicates icon's height. The width will be sized accordion to SVG's aspect ratio. */
  size: PropTypes.oneOfType([endowPropTypes.size, PropTypes.string]),

  /** Object to style the HTML span element. */
  style: endowPropTypes.style,

  /** Optional value to place the icon in the browser's tab order. */
  tabIndex: PropTypes.string,

  /** Optional transparent border to surround icon. */
  transparentBorder: PropTypes.bool,
};

Icon.propTypes = iconPropTypes;

Icon.defaultProps = {
  actions: ["none"],
  color: "primary-200",
  size: "size20",
  transparentBorder: true,
};
