import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { classNames } from "@swa-ui/string";

import { Checkbox } from "../Checkbox";
import { DropDown, dropDownPropTypes } from "../DropDown";
import styles from "./Multiselect.module.scss";

/**
 * Multiselect uses the DropDown component to provide a way to select multiple options. Each option
 * in the DropDown will show a Checkbox. The defaultValues and prop specifies which option should be
 * initially selected and when a change to the selections is made, then onChange will receive a
 * pipe-separated string containing the selected values.
 */

export const Multiselect = React.forwardRef((props, ref) => {
  const {
    className,
    comboBox,
    defaultValue,
    disabled,
    error,
    fullScreen,
    getSelectionValueText,
    id,
    list,
    maxItemsToDisplay,
    minimumListWidth,
    name,
    onBlur,
    onChange,
    onInputChange,
    option,
    readOnly,
    required,
    selectionTextMultiple,
    selectionTextOne,
    selectionTextZero,
    showArrow,
    size,
    value,
  } = props;
  const [values, setValues] = useState(value || defaultValue || []);

  useEffect(() => {
    if (value !== undefined) {
      setValues(value);
    }
  }, [value]);

  return <DropDown {...getProps()} />;

  function getProps() {
    return {
      allowSpaceBarToSelect: false,
      "aria-describedby": props["aria-describedby"],
      "aria-label": props["aria-label"],
      "aria-required": required,
      className,
      comboBox,
      disabled,
      error,
      fullScreen,
      id,
      list: getCheckboxItems(),
      maxItemsToDisplay,
      minimumListWidth,
      name,
      onBlur,
      onInputChange: handleInputChange,
      option,
      readOnly,
      ref,
      reinitOnValueChange: true,
      renderToken: `${values.length}`,
      required,
      showArrow,
      size,
      value: getSelectionValueText ? getSelectionValueText(values) : getSelectionValue(),
    };
  }

  function getListItemProps(listItem) {
    return {
      className: listItem.className,
      closeOnSelect: listItem.custom,
      defaultValue: values?.indexOf(listItem.value) !== -1,
      disabled: listItem.disabled,
      label: listItem.custom ? listItem.label : <Checkbox {...getCheckboxProps(listItem)} />,
      onClick: handleClick.bind(this, listItem),
      value: listItem.value,
    };
  }

  function getCheckboxProps(listItem) {
    return {
      className: classNames({ [styles.indent]: listItem.indent }),
      disabled: listItem.disabled,
      label: listItem.label,
      name: listItem.value,
      onMouseDown: (event) => event.preventDefault(),
      tabIndex: -1,
      value: values?.indexOf(listItem.value) !== -1,
    };
  }

  function handleClick(listItem) {
    const { custom, value: newValue } = listItem;
    let newValues;

    if (custom) {
      onChange?.({ target: { value: [newValue] } });
    } else {
      if (values.indexOf(newValue) === -1) {
        newValues = (values?.[0] === "" ? [] : values).concat(newValue);
      } else {
        newValues = values.filter((currentValue) => currentValue !== newValue);
      }

      setValues(newValues);
      onChange?.({ target: { value: newValues } });
    }
  }

  function handleInputChange(event) {
    onInputChange?.(event);
  }

  function getCheckboxItems() {
    return list.map(getListItemProps);
  }

  function getSelectionValue() {
    const numberSelections = values.length;
    let valueText;

    if (!numberSelections) {
      valueText = selectionTextZero;
    } else if (numberSelections === 1) {
      valueText = selectionTextOne;
    } else {
      valueText = `${numberSelections} ${selectionTextMultiple}`;
    }

    return valueText;
  }
});

Multiselect.propTypes = {
  /** aria-describedby id to element which provides additional accessibility description of input element. */
  "aria-describedby": PropTypes.string,

  /** aria-label text to provide additional accessibility description of input element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Indicator if Input component will accept input, or if DropDown will only allow selection from
   * the list.
   */
  comboBox: dropDownPropTypes.comboBox,

  /** Name to use for the data-test attribute used for automated testing. */
  "data-test": PropTypes.string,

  /**
   * Initial values to select in list. Each value should be separated by a pipe character. For example:
   * "option one|option three".
   */
  defaultValue: PropTypes.string,

  /** Indicates Input should apply disabled styling and ignore mouse and key events. */
  disabled: dropDownPropTypes.disabled,

  /**
   * Indicates Dropdown should apply error styling and apply aria-invalid attribute -- in essence, Input will
   * provide error styling.
   */
  error: dropDownPropTypes.error,

  /** Indicates whether the list of items should be displayed in full screen mode. */
  fullScreen: PropTypes.bool,

  /**
   * Callback to get text to be displayed in Input field. If this function is not provided, then
   * the selection text props below must be given.
   */
  getSelectionValueText: PropTypes.func,

  /** ID to be added to input element. */
  id: dropDownPropTypes.id,

  /**
   * Options to display in multiselect list.
   * For "custom" items, Multiselect will assume that the item is not a checkbox and any interaction
   * with the item will be handled by the caller.
   * If indent is given, then checkbox will have left padding applied.
   * onMouseDown can be used to override the default behavior. By default, multiselect will handle
   * click events and the event will not be passed down to the checkbox.
   */
  list: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      custom: PropTypes.bool,
      disabled: PropTypes.bool,
      indent: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      onMouseDown: PropTypes.func,
      value: PropTypes.string,
    })
  ),

  /** Total number of items visible. If more items available than can be displayed, they can be scrolled into view. */
  maxItemsToDisplay: dropDownPropTypes.maxItemsToDisplay,

  /** Minimum width for drop down list. Normally the width of the list is governed by the content in the list. But
   * this can cause the width to change as the traveler is paging through the list. In these cases, and when a more
   * specific size is desired (like when the list is typically very narrow), DropDown allows for a specific size to
   * be defined.
   */
  minimumListWidth: dropDownPropTypes.minimumListWidth,

  /** Name assigned to the select form control. */
  name: dropDownPropTypes.name,

  /** Optional event handler to learn when focus is lost from input element. */
  onBlur: dropDownPropTypes.onBlur,

  /**
   * Optional callback to learn of selection changes. The values of the selected items will be
   * returned as pipe-separated strings. See defaultValue prop description for an example.
   */
  onChange: dropDownPropTypes.onChange,

  /** Optional callback to learn of changes to Input when readOnly is false. */
  onInputChange: dropDownPropTypes.onInputChange,

  /** Text to display for button "overlaid" on right side of input. */
  option: dropDownPropTypes.option,

  /** Indicates Multiselect's Input should be immutable and ignore mouse and key events. */
  readOnly: PropTypes.bool,

  /** Indicates DropDown should apply aria-required attribute. */
  required: dropDownPropTypes.required,

  /**
   * String to indicate that multiple items are selected. This prop is not used if
   * getSelectionValueText is given, otherwise this prop must be specified.
   */
  selectionTextMultiple: PropTypes.string,

  /**
   * String to indicate that one item is selected. This prop is not used if getSelectionValueText
   * is given, otherwise this prop must be specified.
   */
  selectionTextOne: PropTypes.string,

  /**
   * String to label that zero items are selected. This prop is not used if getSelectionValueText
   * is given, otherwise this prop must be specified.
   */
  selectionTextZero: PropTypes.string,

  /** Indicates if the suffix arrow icon is displayed. */
  showArrow: dropDownPropTypes.showArrow,

  /** Indicates the height and padding of the input element. */
  size: dropDownPropTypes.size,

  /**
   * Value is an array of selected options. The values in the array will correspond to the values
   * given in list props.
   */
  value: PropTypes.arrayOf(PropTypes.string),
};

Multiselect.defaultProps = {
  comboBox: false,
};
