/**
 * Calculates the cumulative transformations applied to an element and its ancestors.
 * The function specifically looks for "transform" CSS property and extracts translation values (x and y).
 *
 * @returns {Object} - An object with 'transformX' and 'transformY' properties representing the cumulative x and y translations respectively.
 */

const getCumulativeTransformations = (element) => {
  let transformX = 0;
  let transformY = 0;

  if (element) {
    do {
      element = element.parentNode;

      if (element) {
        try {
          const computedStyle = getComputedStyle(element);
          const transform = computedStyle.getPropertyValue("transform");

          if (transform.indexOf("matrix(") !== -1) {
            transformX += parseInt(transform.split(",")[4]);
            transformY += parseInt(transform.split(",")[5]);
          }
          // eslint-disable-next-line no-empty
        } catch {}
      }
    } while (element);
  }

  return {
    transformX,
    transformY,
  };
};

export { getCumulativeTransformations };
