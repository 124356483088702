import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useAppSetting } from "@swa-ui/application";
import { swaDate } from "@swa-ui/date";

import { AuthContext } from "../AuthProvider";
import { decodeCookie } from "../cookie";
import { getCookie } from "../cookie";
import { RESTRICTION_NONE, RESTRICTION_OTP_REQUIRED } from "../defines/restrictions";

export const MfaContext = createContext();

export const MfaProvider = (props) => {
  const { children } = props;
  const { auth, handleTokenExchange } = useContext(AuthContext);
  const [mfaExpiredCheck, setMfaExpiredCheck] = useState(0);
  // TODO: Remove this setting after gateway integration
  const mfaOtpRequired = useAppSetting("mfaOtpRequired", false);
  const mfaStatus = useMemo(getMfaStatus, [auth]);
  const timeoutId = useRef(100);

  useEffect(() => {
    setMfaResetTimeout();
  }, [auth]);

  return <MfaContext.Provider value={getContextValue()}>{children}</MfaContext.Provider>;

  function getContextValue() {
    return {
      mfaAuthenticated: isMfaAuthenticated(),
      ...mfaStatus,
      handleMfaAuthenticated: () => {
        handleTokenExchange(auth.idToken);
      },
    };
  }

  function setMfaResetTimeout() {
    const expirationDurationInSeconds = getExpirationDurationInSeconds();

    clearMfaTimeout();

    if (expirationDurationInSeconds > 0) {
      timeoutId.current = setTimeout(() => {
        setMfaExpiredCheck((mfaExpiredCheck + 1) % 2);
      }, expirationDurationInSeconds * 1000);
    }
  }

  function isMfaAuthenticated() {
    return getExpirationDurationInSeconds() > 0;
  }

  function getExpirationDurationInSeconds() {
    const now = swaDate().unix();
    const { mfaExpiration = now } = mfaStatus ?? {};

    return mfaExpiration - now;
  }

  function clearMfaTimeout() {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = 0;
  }

  function getMfaStatus() {
    const idTokenData = getIdTokenData();
    // TODO: Always default to RESTRICTION_NONE after gateway integration
    const defaultUpdateRestriction = mfaOtpRequired ? RESTRICTION_OTP_REQUIRED : RESTRICTION_NONE;

    return {
      mfaExpiration: idTokenData?.mfa_expiration ?? 0,
      mfaRestrictions: { update: idTokenData?.update_restriction ?? defaultUpdateRestriction },
    };
  }

  function getIdTokenData() {
    // TODO: Remove FAKE cookie after gateway integration
    return auth.idToken ? decodeCookie(getCookie("id_token_fake") ?? auth.idToken) : {};
  }
};

MfaContext.displayName = "MfaContext";
MfaProvider.propTypes = {
  /** Content to be rendered on the page. */
  children: PropTypes.node.isRequired,
};
