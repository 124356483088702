import React from "react";

import { Card } from "@swa-ui/core";

import { VacationsBookingForm } from "../VacationsBookingForm";

export const VacationsBookingWidget = () => (
  <Card>
    <VacationsBookingForm />
  </Card>
);
