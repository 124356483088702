import PropTypes from "prop-types";
import { useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";

const DOCUMENT_ROOT = "root";
const PORTAL_ROOT = "portal-root";

/**
 * CreatePortal provides a consistent way to add content to React's portal feature of ReactDOM.
 */

export const PortalRoot = (props) => {
  const { children } = props;
  const [portalTarget, setPortalTarget] = useState(getPortalRoot());
  const documentRoot = document.getElementById(DOCUMENT_ROOT) ?? document.body;

  useLayoutEffect(() => {
    if (!getPortalRoot()) {
      const newPortalTarget = document.createElement("div");

      newPortalTarget.setAttribute("id", PORTAL_ROOT);
      documentRoot.insertBefore(newPortalTarget, documentRoot.firstChild);
      setPortalTarget(newPortalTarget);
    }
  });

  return getPortalRoot() && createPortal(children, portalTarget);

  function getPortalRoot() {
    return document.getElementById(PORTAL_ROOT);
  }
};

PortalRoot.propTypes = {
  /** Content that will be rendered in the React Portal. */
  children: PropTypes.node,
};
