import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import { FormattedInputFormField } from "../FormattedInputFormField";
import { SelectFormField } from "../SelectFormField";
import { TextFormField } from "../TextFormField";
import { useFormContext } from "../useFormContext";
import styles from "./PhoneFormField.module.scss";
import { SCHEMA_FIELD_NAMES } from "./PhoneFormField.schema";

const DEFAULT_COUNTRY_CODE = 1;
const INTERNATIONAL_PHONE_NUMBER_LENGTH = 12;
const NORTH_AMERICAN_PHONE_NUMBER_LENGTH = 10;

/**
 * PhoneFormField facilitates selecting a countryCode from the dropdown and applying a format
 * to the input based on if the country code is Domestic or i18n.
 *
 * Note: the schema is present in this directory and the application ingesting it should pass the label to the schema.
 */

export const PhoneFormField = (props) => {
  const {
    caption,
    className,
    countryCode,
    countryCodeFieldName,
    countryCodeFieldLabel,
    countryCodes,
    defaultValue,
    disabled,
    fieldName,
    label,
  } = props;
  const { watch } = useFormContext();
  const selectedCountryCode = watch(countryCodeFieldName) ?? countryCode ?? DEFAULT_COUNTRY_CODE;

  return (
    <div className={classNames(className)}>
      <SelectFormField {...getCountryCodeProps()} />
      {renderPhoneNumberField()}
    </div>
  );

  function renderPhoneNumberField() {
    return isNorthAmericanPlanCountryCode() ? (
      <FormattedInputFormField {...getPhoneNumberProps()} />
    ) : (
      <TextFormField {...getPhoneNumberProps()} />
    );
  }

  function getCountryCodeProps() {
    return {
      className: styles.countryCode,
      componentProps: {
        defaultValue: selectedCountryCode,
        list: countryCodes,
        required: true,
      },
      disabled,
      label: countryCodeFieldLabel,
      name: countryCodeFieldName,
    };
  }

  function getPhoneNumberProps() {
    const isDomesticCountryCode = isNorthAmericanPlanCountryCode();

    return {
      caption,
      componentProps: {
        acceptableCharacters: "0123456789",
        "aria-label": props?.["aria-label"],
        defaultValue,
        inputMode: "tel",
        maxLength: isDomesticCountryCode
          ? NORTH_AMERICAN_PHONE_NUMBER_LENGTH
          : INTERNATIONAL_PHONE_NUMBER_LENGTH,
        required: true,
        ...(isDomesticCountryCode && {
          formatTemplate: "(___) ___-____",
        }),
      },
      disabled,
      label,
      name: fieldName,
    };
  }

  function isNorthAmericanPlanCountryCode() {
    return selectedCountryCode === DEFAULT_COUNTRY_CODE;
  }
};

PhoneFormField.propTypes = {
  /** Content to appear below the form field. */
  caption: PropTypes.string,

  /** Class name for positioning the component. */
  className: PropTypes.string,

  /** Country code. */
  countryCode: PropTypes.number,

  /** Label to be passed to the country code field. */
  countryCodeFieldLabel: PropTypes.node,

  /** Country code field name. */
  countryCodeFieldName: PropTypes.string,

  /** Country codes list to be passed to the dropdown. */
  countryCodes: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        value: PropTypes.oneOfType([PropTypes.number]),
      })
    ),
  ]),

  /** default value for the Phone number. */
  defaultValue: PropTypes.string,

  /** Indicates whether the field is disabled.  */
  disabled: PropTypes.bool,

  /** Phone number field name. */
  fieldName: PropTypes.string,

  /** Label to be passed to the phone number field. */
  label: PropTypes.node,
};

PhoneFormField.defaultProps = {
  countryCodeFieldName: SCHEMA_FIELD_NAMES.PHONE_COUNTRY_CODE,
  fieldName: SCHEMA_FIELD_NAMES.PHONE_NUMBER,
};
