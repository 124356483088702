import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { classNames } from "@swa-ui/string";

import { FadingDot } from "../FadingDot";
import styles from "./PositionIndicator.module.scss";

const DURATION_CASCADE = 75;
const DURATION_FADE = 125;
const DURATION_INITIAL_FADE = 500;
const SELECTED_DESIGN_TOKEN = "cmp-core-color-position-indicator-selected";
const UNSELECTED_DESIGN_TOKEN = "cmp-core-color-position-indicator-unselected";

/**
 * PositionIndicator provides location status to show the current position in a list of items.
 */

export const PositionIndicator = React.memo((props) => {
  const { className, position, positionCount } = props;
  const [currentPosition, setCurrrentPosition] = useState(0);

  useEffect(() => {
    if (currentPosition > positionCount - 1) {
      setCurrrentPosition(positionCount - 1);
    }
  }, [positionCount]);

  return <div {...getProps()}>{[...Array(positionCount)].map((_, index) => renderDot(index))}</div>;

  function renderDot(index) {
    return (
      <div className={getClass()} key={index}>
        <FadingDot {...getDotProps(index)} />
      </div>
    );
  }

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className: classNames(className, styles.positionIndicator),
    };
  }

  function getDotProps(index) {
    return {
      color: getColor(index),
      duration: isInitialRender()
        ? DURATION_INITIAL_FADE + index * DURATION_CASCADE
        : DURATION_FADE,
      fullWidth: true,
      id: index,
      onTransformationEnd: getTransformationEnd(index)?.bind(this, index),
      to: getTo(index),
    };
  }

  function getClass() {
    return classNames(styles.dotContainer, { [styles.initialRender]: isInitialRender() });
  }

  function handleTransformationEnd() {
    setCurrrentPosition(position);
  }

  function getColor(index) {
    return (isInitialRender() ? position : currentPosition) === index
      ? SELECTED_DESIGN_TOKEN
      : UNSELECTED_DESIGN_TOKEN;
  }

  function getTransformationEnd(index) {
    let transformationEnd = undefined;

    if (
      !willNothingChange() &&
      ((isInitialRender() && index === positionCount - 1) ||
        Math.max(currentPosition, position) === index)
    ) {
      transformationEnd = handleTransformationEnd;
    }

    return transformationEnd;
  }

  function getTo(index) {
    let to;

    if (isInitialRender()) {
      to = "SHOW";
    } else {
      to = willThisItemChangeNextRender(index) ? "FADE-SHRINK" : "SHOW";
    }

    return to;
  }

  function isInitialRender() {
    return currentPosition === undefined;
  }

  function willThisItemChangeNextRender(index) {
    let willChange;

    if (willNothingChange() || (index !== position && index !== currentPosition)) {
      willChange = false;
    } else if (index === position || index === currentPosition) {
      willChange = true;
    }

    return willChange;
  }

  function willNothingChange() {
    return position === currentPosition;
  }
});

PositionIndicator.propTypes = {
  /** aria-label text to be read by screen reader to indicate current page. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Index of current position. The current position will be a dot with the selected color as
   * defined in the color theme file.
   */
  position: PropTypes.number,

  /** Total number of positions (dots that represent locations). */
  positionCount: PropTypes.number,
};

PositionIndicator.displayName = "PositionIndicator";
PositionIndicator.defaultProps = {
  position: 0,
};
