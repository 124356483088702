import { Input } from "@swa-ui/core";

import { withFormField } from "../withFormField";

/**
 * EmailFormField provides support for a standard Input component to be used as
 * a email field in a Form.
 */

export const EmailFormField = withFormField(Input, { type: "email" });
