/* eslint-disable sort-keys */
export default {
  light: {
    "banner-with-card-text-background": "white",
    "footer-content": "blue-1",
    "primary-header-background": "white",
    "primary-header-heading-icon": "gray-4",
    "primary-header-logo": "blue-1",
    "primary-header-logo-light": "white",
    "primary-header-search": "blue-1",
    "primary-header-search-open": "white",
    "secondary-header-border": "blue-1",
    "secondary-header-logo": "white",
    "secondary-header-logo-section-background": "blue-1",
    "secondary-header-options-background": "white",
    "secondary-header-title-section-background": "blue-2",
    "system-message-alert-background": "white",
    "system-message-alert-icon": "blue-1",
    "system-message-background": "white",
    "system-message-border": "gray-2",
    "system-message-list-item-divider": "gray-3",
    "text-block-text-light": "neutral-900",
  },
  dark: {
    "banner-with-card-text": "white",
    "footer-content": "white",
    "primary-header-background": "blue-2",
    "primary-header-heading-icon": "blue-1-light",
    "primary-header-logo": "white",
    "primary-header-logo-light": "white",
    "primary-header-search": "white",
    "primary-header-search-open": "white",
    "secondary-header-border": "gray-2",
    "secondary-header-logo": "white",
    "secondary-header-logo-section-background": "blue-1",
    "secondary-header-options-background": "blue-3",
    "secondary-header-title-section-background": "blue-2",
    "system-message-alert-background": "blue-1",
    "system-message-alert-icon": "white",
    "system-message-background": "white",
    "system-message-border": "gray-2",
    "system-message-list-item-divider": "gray-3",
    "text-block-text-light": "neutral-900",
  },
};
