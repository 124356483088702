import PropTypes from "prop-types";
import React from "react";

import { BulletList, Heading, Layout } from "@swa-ui/core";

import { getBackgroundStyle } from "../Banner/BannerUtils";
import { CallToAction } from "../CallToAction";
import { HtmlValue } from "../HtmlValue";

/**
 * ListBlockPlacement renders a bulleted list with optional background, heading, callout, and call to action.
 * This placement also allows for the heading to be replaced by the consumer.
 */
export const ListBlockPlacement = (props) => {
  const { heading: headingOverride, placement } = props;

  return (
    <div {...getBackgroundProps()}>
      {renderCallout()}
      {renderHeading()}
      {renderList()}
      {renderCallToAction()}
    </div>
  );

  function renderCallout() {
    const { callout } = placement;
    const { value, ...calloutStyles } = callout ?? {};

    return callout && <span style={{ ...calloutStyles }}>{value}</span>;
  }

  function renderCallToAction() {
    const { callToAction } = placement;

    return (
      callToAction && (
        <Layout top="xlarge">
          <CallToAction {...callToAction} />
        </Layout>
      )
    );
  }

  function renderHeading() {
    const { heading } = placement;
    const defaultHeading = heading && <Heading {...getHeadingProps(heading)} />;

    return headingOverride ? headingOverride : defaultHeading;
  }

  function renderList() {
    const { list } = placement;

    return <BulletList {...getBulletListProps(list)} />;
  }

  // TODO: Consolidate Background placement logic into a shareable placement component.
  function getBackgroundProps() {
    const { background } = placement;
    const backgroundStyles = getBackgroundStyle(background);

    return {
      style: backgroundStyles,
    };
  }

  function getBulletListProps(list) {
    const { items, settings } = list ?? {};
    const normalizedItems = items.map((item) => {
      const content = <HtmlValue htmlValue={item.content ?? item.value} />;
      const svg = item.svg ?? item.image;

      return {
        ...item,
        ...(svg && { svg }),
        content,
      };
    });

    return {
      ...settings,
      items: normalizedItems,
    };
  }

  // TODO: Consolidate Heading placement logic into a shareable placement component.
  function getHeadingProps(heading) {
    const { color, fontSize, fontWeight, headingLevel = 2, styleLevel, value } = heading ?? {};

    return {
      children: <span style={{ color, fontSize, fontWeight }}>{value}</span>,
      headingLevel,
      styleLevel,
    };
  }
};

ListBlockPlacement.propTypes = {
  /** Used to override the default heading rendering behavior. */
  heading: PropTypes.node,

  /** Object consisting of an data used to construct the component. */
  placement: PropTypes.object.isRequired,
};
