import { useEffect } from "react";

/**
 * Custom React hook that debounces a side effect function.
 *
 * @param {Function} effect - The side effect function to debounce.
 * @param {Array} dependency - Array of dependencies for the effect.
 * @param {number} timeout - The debounce delay in milliseconds.
 */

const useDebounceEffect = (effect, dependency, timeout) => {
  useEffect(() => {
    const debounceTimoutId = setTimeout(() => {
      effect();
    }, timeout);

    return () => clearTimeout(debounceTimoutId);
  }, dependency);
};

export { useDebounceEffect };
