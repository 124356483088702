import PropTypes from "prop-types";
import React from "react";

import { useDesignTokensTheme } from "@swa-ui/design-tokens";
import { classNames } from "@swa-ui/string";

import { Background, backgroundPropTypes } from "../Background";
import styles from "./Badge.module.scss";

const COLORS = [
  "black",
  "caution",
  "danger",
  "disabled",
  "error",
  "information",
  "neutral",
  "primary",
  "secondary",
  "success",
  "tertiary",
  "transparent",
  "warning",
  "white",
];
const HEIGHTS_LINE = "2px";
const HEIGHTS_LINE_HEIGHT = "2rem";

/**
 * Badge renders a variety of small illustrations with labels- usually some sort of background with
 * optionally a small amount of text or JSX.
 */
export const Badge = (props) => {
  const { border, center, children, className, flagCutoutColor, height, padding, styleType, type } =
    props;
  const { themeClass } = useDesignTokensTheme({ component: "badge", styleType });

  return type === "flag" ? renderFlag() : <Background {...getProps()}>{children}</Background>;

  function renderFlag() {
    return (
      <div {...getFlagProps()}>
        <div className={getFlagChildrenClass()}>{children}</div>
        <div {...getFlagCutoutProps()} />
      </div>
    );
  }

  function getFlagProps() {
    return {
      className: getFlagClass(),
      style: { "--background-color": `var(--theme-${styleType})`, "--height": getSize() },
    };
  }

  function getFlagCutoutProps() {
    return {
      className: styles.flagCutout,
      style: { "--flag-background-color": `var(--theme-${flagCutoutColor})`, "--size": getSize() },
    };
  }

  function getProps() {
    return {
      border,
      className: getClass(),
      height,
      shape: type,
      ...(type === "flag" && { color: styleType }),
    };
  }

  function getFlagClass() {
    return classNames(className, styles.badge, themeClass);
  }

  function getFlagChildrenClass() {
    return classNames(styles.flagChildren, {
      [styles.paddingLarge]: padding === "large",
      [styles.paddingMedium]: padding === "medium",
      [styles.paddingSmall]: padding === "small",
      [styles.paddingXlarge]: padding === "xlarge",
      [styles.paddingXsmall]: padding === "xsmall",
    });
  }

  function getClass() {
    return classNames(className, styles.badge, themeClass, {
      [styles.leftJustify]: !center,
      [styles.paddingLarge]: padding === "large",
      [styles.paddingMedium]: padding === "medium",
      [styles.paddingSmall]: padding === "small",
      [styles.paddingXlarge]: padding === "xlarge",
      [styles.paddingXsmall]: padding === "xsmall",
    });
  }

  function getSize() {
    const heights = {
      line: HEIGHTS_LINE,
      lineHeight: HEIGHTS_LINE_HEIGHT,
    };

    return heights[height] || height
      ? `var(--theme-height-${height})`
      : `var(--theme-height-medium)`;
  }
};

Badge.propTypes = {
  /** Optional border to surround shape. Cannot be given for Flag at this time. */
  border: backgroundPropTypes.border,

  /** Content will be centered if true. */
  center: PropTypes.bool,

  /** Content that will be rendered on the in the badge. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Color of background where a flag will be displayed. This prop is only need for the flag shape
   * and when the background is not white.
   * This prop is temporary and will be refactored at some future date.
   */
  flagCutoutColor: PropTypes.oneOf(COLORS),

  /** Height as defined by Shape component. See that component for values. */
  height: backgroundPropTypes.height,

  /** Padding allows the caller to specify how much padding will be defined. */
  padding: PropTypes.oneOf(["large", "medium", "none", "small", "xlarge", "xsmall"]),

  /** Provides the variations in [background] treatment for the given badge type. */
  styleType: backgroundPropTypes.color,

  /** Defines which type of badge should be rendered. */
  type: PropTypes.oneOf([
    "capsule",
    "circle",
    "ellipse",
    "flag",
    "rectangle",
    "roundedRectangle",
    "square",
  ]),
};

Badge.defaultProps = {
  flagCutoutColor: "white",
  padding: "small",
  styleType: "primary",
};
