import PropTypes from "prop-types";

/**
 * The ExternalRedirect component is used to ensure that when "rendered" in the DOM that the
 * window object's location.href property is updated, effectively causing an external redirect.
 * Used primarily by the ProtectedRoute component.
 */

export const ExternalRedirect = (props) => {
  const { to } = props;

  window.location.href = to;

  return null;
};

ExternalRedirect.propTypes = {
  /** the path to redirect to when "rendered". */
  to: PropTypes.string.isRequired,
};
