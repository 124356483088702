import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { CustomerLightTheme } from "@swa-ui/design-tokens";
import { classNames } from "@swa-ui/string";

import { BackgroundVeil } from "../BackgroundVeil";
import { Caption, captionPropTypes } from "../Caption";
import { Message, messagePropTypes } from "../Message";
import { PortalRoot } from "../PortalRoot";
import styles from "./Toast.module.scss";

/**
 * Toast displays the Message component centered in the window. It accepts all the standard Message
 * props. See that component for more details.
 */

export const Toast = (props) => {
  const {
    captionRef,
    children,
    className,
    closeOnBackgroundClick,
    doc,
    messageProps,
    on,
    onClose,
    showBackgroundVeil,
    width,
  } = props;
  const [currentLocation, setCurrentLocation] = useState("hidden");

  useEffect(() => {
    setCurrentLocation(on ? "center-to-window" : "hidden");
  }, [on]);

  return (
    <>
      {showBackgroundVeil && <BackgroundVeil {...getBackgroundVeilProps()} />}
      <PortalRoot>
        <CustomerLightTheme>
          <div className={styles.messageContainer}>
            <Caption {...getCaptionProps()} captionRef={captionRef} />
          </div>
        </CustomerLightTheme>
      </PortalRoot>
    </>
  );

  function getBackgroundVeilProps() {
    return {
      delayedStart: true,
      on,
      onClick: closeOnBackgroundClick ? onClose : undefined,
    };
  }

  function getCaptionProps() {
    return {
      adjoiningContent:
        children || messageProps?.headingProps?.children ? (
          <Message {...getMessageProps()}>{children}</Message>
        ) : null,
      className: classNames(className, styles.toast),
      doc,
      location: currentLocation,
      showPointer: false,
      width,
    };
  }

  function getMessageProps() {
    return {
      ...messageProps,
      "aria-label-close": props["aria-label-close"],
      on: currentLocation !== "hidden",
      onClose: close,
    };
  }

  function close() {
    setCurrentLocation("hidden");
    onClose && onClose();
  }
};

Toast.propTypes = {
  /** aria-label text to provide additional accessibility description of button element. */
  "aria-label-close": PropTypes.string,

  /** Ref passed to allow useCaption to close Toast on clicking outside of component. */
  captionRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.object })]),

  /** Content that will be rendered in heading. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Allow modal to be dismissed when background veil is clicked. */
  closeOnBackgroundClick: PropTypes.bool,

  /**
   * By default this will the global document object, but can be overridden to support iFrames. This is used by
   * Caption to get a reference to a DOM element.
   */
  doc: PropTypes.object,

  /** See Heading component for available options. */
  messageProps: PropTypes.shape(messagePropTypes),

  /**
   * Toast on props should initially false. Once an action occurs that warrants Toast to be displayed, on should be
   * set to true.
   */
  on: PropTypes.bool,

  /** Optional callback to be informed when Toast is closed. */
  onClose: PropTypes.func,

  /**
   * When showBackgroundVeil is true, a mostly opaque background will be displayed behind the Toast
   * overlay to hide the page contents.
   */
  showBackgroundVeil: PropTypes.bool,

  /** Width for message content. */
  width: captionPropTypes.width,
};

Toast.defaultProps = {
  on: false,
  width: "large",
};
