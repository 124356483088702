import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import "../../generated/platforms/scss/business-dark.module.scss";
import "../../generated/platforms/scss/business.module.scss";
import { DesignTokensThemeProvider } from "../DesignTokensThemeProvider";

/**
 * Default business dark theme.
 */

export const BusinessDarkTheme = (props) => {
  const { children } = props;

  return <DesignTokensThemeProvider {...getProps()}>{children}</DesignTokensThemeProvider>;

  function getProps() {
    return {
      className: classNames("business-dark-theme", "business-theme"),
      themeName: "business",
    };
  }
};

BusinessDarkTheme.propTypes = {
  /** Content that will be rendered on the button. */
  children: PropTypes.node,
};
