import PropTypes from "prop-types";
import React, { useContext, useEffect, useRef, useState } from "react";

import { fireTrack } from "@swa-ui/analytics";
import { MessageContext } from "@swa-ui/application";
import { Badge, Drawer, HorizontalList, VerticalList } from "@swa-ui/core";
import { RecentSearchContext } from "@swa-ui/form";
import i18n from "@swa-ui/locale";

import { AIR, CAR } from "../defines/itineraryType";
import { ItineraryDate } from "../ItineraryDate";
import styles from "./ItineraryRecentSearch.module.scss";

const DELAY_FOR_BROWSER = 16;

/**
 * ItineraryRecentSearch presents selectable list of recent search sets that can be chosen to
 * facilitate search form entry.
 */

export const ItineraryRecentSearch = (props) => {
  const { className, list, onClick, type } = props;
  const {
    clearRecentSearches,
    isRemembering,
    startRememberingRecentSearches,
    stopRememberingRecentSearches,
  } = useContext(RecentSearchContext);
  const { displayMessage } = useContext(MessageContext);
  const [currentList, setCurrentList] = useState(list);
  const [revealed, setRevealed] = useState(false);
  const triggerRef = useRef();

  useEffect(() => {
    if (isRemembering(type)) {
      setCurrentList(list);
    }
  }, [list]);

  return <Drawer {...getProps()} />;

  function renderList() {
    return (
      <div className={styles.itineraryRecentSearch}>
        {renderTitle()}
        {currentList.length ? <VerticalList {...getVerticalListProps()} /> : renderEmpty()}
        <span className={styles.separator} />
        <HorizontalList {...getHorizontalListProps()} />
      </div>
    );
  }

  function renderTitle() {
    return (
      <span className={styles.title}>
        <span className={styles.label}>{i18n("ItineraryRecentSearch__TITLE")}</span>
        <span className={styles.inlineSeparator} />
      </span>
    );
  }

  function renderRecentSearchOption(option) {
    const { departureDate, returnDate } = option;

    return (
      <span className={styles.itinerary}>
        <span className={styles.itineraryOriginDestination}>{renderStationDetails(option)}</span>
        <span className={styles.itineraryDates}>
          <ItineraryDate date={departureDate} format={i18n("ItineraryRecentSearch__DATE_FORMAT")} />
          {returnDate && renderReturnDate(returnDate)}
        </span>
      </span>
    );
  }

  function renderStationDetails(option) {
    const { station1, station2, station3 } = option;
    let content = station1.concat(" - ").concat(station2);

    if (station3) {
      content = content.concat(" - ").concat(station3);
    }

    return content;
  }

  function renderReturnDate(returnDate) {
    return (
      <>
        &nbsp;-&nbsp;
        <ItineraryDate date={returnDate} format={i18n("ItineraryRecentSearch__DATE_FORMAT")} />
      </>
    );
  }

  function renderEmpty() {
    return (
      <div className={styles.emptyContainer}>
        <Badge {...getBadgeProps()}>
          {isRemembering(type)
            ? i18n("ItineraryRecentSearch__EMPTY")
            : i18n("ItineraryRecentSearch__DISABLED")}
        </Badge>
      </div>
    );
  }

  function getProps() {
    return {
      alignedRight: false,
      children: renderList(),
      className,
      onRevealChange: handleRevealChange,
      ref: triggerRef,
      revealed,
      showPointer: true,
      triggerContent: i18n("ItineraryRecentSearch__TITLE"),
    };
  }

  function getVerticalListProps() {
    return {
      items: currentList.map(renderRecentSearchOption),
      onClick: handleClick,
    };
  }

  function getHorizontalListProps() {
    return {
      className: styles.menuOptions,
      items: getItems(),
      separator: false,
      spacing: "small",
      tagName: "button",
    };
  }

  function getBadgeProps() {
    return {
      className: styles.emptyContainerContent,
      padding: "large",
      styleType: "tertiary",
      type: "capsule",
    };
  }

  function handleClick(index) {
    closeDrawer();
    onClick(index);
  }

  function handleRevealChange(open) {
    setRevealed(open);

    if (open && type) {
      fireTrack("squid", { page_description: `responsive drop down:recent search ${type}` });
    }
  }

  function handleRemember() {
    closeDrawer();

    if (isRemembering(type)) {
      stopRememberingRecentSearches([type]);
      setCurrentList([]);
      displayMessage(
        "success",
        i18n("ItineraryRecentSearch__SUCCESS_MESSAGE"),
        i18n("ItineraryRecentSearch__TITLE_SUCCESS_MESSAGE"),
        "center-to-window"
      );
    } else {
      startRememberingRecentSearches([type]);
    }
  }

  function handleClear() {
    closeDrawer();
    clearRecentSearches([type]);
    setCurrentList([]);
  }

  function getItems() {
    const items = [
      {
        componentProps: {
          children: isRemembering(type)
            ? i18n("ItineraryRecentSearch__STOP_REMEMBERING")
            : i18n("ItineraryRecentSearch__ENABLE"),
          className: styles.menuOption,
          onClick: handleRemember,
          styleType: "no-style",
        },
      },
    ];

    if (currentList.length) {
      items.push({
        componentProps: {
          children: i18n("ItineraryRecentSearch__CLEAR"),
          className: styles.menuOption,
          onClick: handleClear,
          styleType: "no-style",
        },
      });
    }

    return items;
  }

  // TODO consider using effect instead of setTimeout: PHX-1124
  function closeDrawer() {
    setRevealed(false);
    setTimeout(() => triggerRef.current.focus(), DELAY_FOR_BROWSER);
  }
};

ItineraryRecentSearch.propTypes = {
  /** Classes for positioning the component. */
  className: PropTypes.string,

  /**
   * List items to be displayed. Airports should be three-letter codes. Dates should be formatted
   * like so: 02/29/2024 or 2024-2-29.
   */
  list: PropTypes.arrayOf(
    PropTypes.shape({
      departureDate: PropTypes.string.isRequired,
      returnDate: PropTypes.string,
      station1: PropTypes.string.isRequired,
      station2: PropTypes.string.isRequired,
      station3: PropTypes.string,
    })
  ),

  /**
   * Callback event handler to learn when an itinerary option is clicked. The index of the clicked
   * item will be returned.
   */
  onClick: PropTypes.func.isRequired,

  /** Type of recent searches. */
  type: PropTypes.oneOf([AIR, CAR]).isRequired,
};

ItineraryRecentSearch.defaultProps = {
  list: [],
};
