import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { useFetch } from "@swa-ui/fetch";
import { logger } from "@swa-ui/log";

import { HtmlValue } from "../HtmlValue";

/**
 * ContentFragment pulls back fragment file content from Netstorage.
 */

export const ContentFragment = (props) => {
  const { href } = props;
  const { get } = useFetch();
  const [content, setContent] = useState();
  const loadedHref = useRef();

  useEffect(() => {
    if ((content && loadedHref.current !== href) || !href) {
      setContent("");
    }

    if (href) {
      get(href)
        .then(async (response) => {
          const body = typeof response === "string" ? response : response?.data;

          if (body) {
            loadedHref.current = href;
            setContent(body);
          } else {
            logger.error("Unable to retrieve fragment.", {
              status: response.status,
              statusText: response.statusText,
            });
          }
        })
        .catch((error) => {
          logger.error("Unable to retrieve fragment.", { error });
        });
    }
  }, [href]);

  return content ? <HtmlValue htmlValue={content} /> : null;
};

ContentFragment.propTypes = {
  /**
   * Path containing part of HTML file to fetch from Netstorage
   */
  href: PropTypes.string,
};
