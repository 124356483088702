import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { grid, media } from "../assets/styles/gridDefines";
import deviceRangeValues from "../defines/deviceRangeValues";
import { getSearchParam } from "../getSearchParam";
import { getWebViewType } from "../getWebViewType";
import { window } from "../window";
import { DeviceInfoContext } from "./DeviceInfoContext";

const COLUMN_SPACING_VALUES = {
  large: parseInt(grid.largeColumnSpacing),
  medium: parseInt(grid.mediumColumnSpacing),
  small: parseInt(grid.smallColumnSpacing),
  xlarge: parseInt(grid.xlargeColumnSpacing),
};
const MEDIA_WIDTH_LIMITS_VALUES = {
  large: { max: media.largeMax, min: media.largeMin },
  medium: { max: media.mediumMax, min: media.mediumMin },
  small: { max: media.smallMax, min: media.smallMin },
  xlarge: { min: media.xlargeMin },
};
const NUMBER_COLUMNS_VALUES = {
  large: parseInt(grid.largeNumCols),
  medium: parseInt(grid.mediumNumCols),
  small: parseInt(grid.smallNumCols),
  xlarge: parseInt(grid.xlargeNumCols),
};
const PADDING_VALUES = {
  large: parseInt(grid.largePadding),
  medium: parseInt(grid.mediumPadding),
  small: parseInt(grid.smallPadding),
  xlarge: parseInt(grid.xlargePadding),
};
const ROW_SPACING_VALUES = {
  large: parseInt(grid.largeRowSpacing),
  medium: parseInt(grid.mediumRowSpacing),
  small: parseInt(grid.smallRowSpacing),
  xlarge: parseInt(grid.xlargeRowSpacing),
};

/** Provider used to query and expose information about the current device. */

export const DeviceInfoProvider = (props) => {
  const { children, userAgentTokens } = props;
  const [screenSize, setScreenSize] = useState(() => getActiveScreenSize(getWatchers()));
  const orientationQuery = window.matchMedia?.("(orientation: portrait)");
  const [isLandscape, setIsLandscape] = useState(!orientationQuery?.matches);
  const [isPortrait, setIsPortrait] = useState(orientationQuery?.matches);
  const isPhysicalKeyboardPresent = window.matchMedia?.(
    "(hover: hover) and (pointer: fine)"
  )?.matches;
  const isTouchDevice = window.matchMedia?.("(hover: none), (pointer: coarse)")?.matches;
  const webViewType = getWebViewType(userAgentTokens);

  useEffect(() => {
    const watchers = getWatchers();

    orientationQuery.addEventListener
      ? orientationQuery.addEventListener("change", handleChange)
      : orientationQuery.addListener(handleChange);
    watchers.forEach((watcher) => {
      watcher.query.addEventListener
        ? watcher.query.addEventListener("change", watcher.listener)
        : watcher.query.addListener(watcher.listener);
    });

    return () => {
      orientationQuery.removeEventListener
        ? orientationQuery.removeEventListener("change", handleChange)
        : orientationQuery.removeListener(handleChange);
      watchers.forEach((watcher) => {
        watcher.query.removeEventListener
          ? watcher.query.removeEventListener("change", watcher.listener)
          : watcher.query.removeListener(watcher.listener);
      });
    };
  }, []);

  return (
    <DeviceInfoContext.Provider value={getDeviceInfoContextValue()}>
      {children}
    </DeviceInfoContext.Provider>
  );

  function handleChange(event) {
    setIsLandscape(!event?.matches);
    setIsPortrait(event?.matches);
  }

  function getActiveScreenSize(watchers) {
    return watchers.filter((watcher) => watcher.query?.matches)?.[0]?.size ?? "small";
  }

  function getWatchers() {
    return [
      {
        listener: (event) => evaluateSizeWatcher(event, "small"),
        query: window.matchMedia?.(`(max-width: ${deviceRangeValues.smallMax})`),
        size: "small",
      },
      {
        listener: (event) => evaluateSizeWatcher(event, "medium"),
        query: window.matchMedia?.(
          `(max-width: ${deviceRangeValues.mediumMax}) and (min-width: ${deviceRangeValues.mediumMin})`
        ),
        size: "medium",
      },
      {
        listener: (event) => evaluateSizeWatcher(event, "large"),
        query: window.matchMedia?.(
          `(max-width: ${deviceRangeValues.largeMax}) and (min-width: ${deviceRangeValues.largeMin})`
        ),
        size: "large",
      },
      {
        listener: (event) => evaluateSizeWatcher(event, "xlarge"),
        query: window.matchMedia?.(`(min-width: ${deviceRangeValues.xlargeMin})`),
        size: "xlarge",
      },
    ];
  }

  function evaluateSizeWatcher(event, size) {
    event?.matches && setScreenSize(size);
  }

  function getDeviceInfoContextValue() {
    return {
      gridColumnSpacing: getValue(COLUMN_SPACING_VALUES),
      gridNumberColumns: getValue(NUMBER_COLUMNS_VALUES),
      gridPadding: getValue(PADDING_VALUES),
      gridRowSpacing: getValue(ROW_SPACING_VALUES),
      isLandscape,
      isLargeOrXlarge: screenSize === "large" || screenSize === "xlarge",
      isPhysicalKeyboardPresent,
      isPortrait,
      isSecureWebView: getSearchParam("forceSecureWebView") ?? false,
      isSmallOrMedium: screenSize === "small" || screenSize === "medium",
      isSwaWebView: !!webViewType,
      isTouchDevice,
      mediaWidthLimits: getValue(MEDIA_WIDTH_LIMITS_VALUES),
      screenSize,
      webViewType,
    };
  }

  function getValue(values) {
    return values[screenSize] || 0;
  }
};

DeviceInfoProvider.propTypes = {
  /** Content that will be rendered with context. */
  children: PropTypes.node,

  /** Array of user agent tokens to determine if web view is "typed", by the window user agent having a custom suffix. */
  userAgentTokens: PropTypes.arrayOf(PropTypes.string),
};

DeviceInfoContext.defaultProps = {
  userAgentTokens: [],
};
