import { Engine } from "json-rules-engine";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import { swaDate } from "@swa-ui/date";
import { logger } from "@swa-ui/log";
import { diff, isEmpty } from "@swa-ui/object";
import { usePersistedState } from "@swa-ui/persistence";

/**
 * RulesEngine provides a way to fire event callback based on facts.
 * See https://github.com/cachecontrol/json-rules-engine for more information.
 */

export const RulesEngine = (props) => {
  const { allowUndefinedFacts, eventCallbacks, facts, operators, rules, rulesDictionary } = props;
  const [debugEnabled] = usePersistedState({
    defaultValue: false,
    key: "RulesEngine_debugEnabled",
  });
  const [eventTimes, setEventTimes] = useState({});
  const engine = useRef();
  const lastFacts = useRef({});

  useEffect(() => {
    debug("rules dictionary", () => JSON.stringify(rulesDictionary, null, 4));

    engine.current = new Engine(getRules(), { allowUndefinedFacts });

    Object.entries(getOperators())?.forEach(([operatorName, evaluateFunc]) => {
      engine.current.addOperator(operatorName, evaluateFunc);
    });
  }, [rules, rulesDictionary, operators]);

  useEffect(() => {
    const currentAndLastFacts = {
      ...eventTimes,
      ...facts,
      ...getLastFactsPrefixedWithLast(),
      now: swaDate().valueOf(),
    };
    const allEventCallbacks = getEventCallbacks();
    const factsDifferences = diff(lastFacts.current, facts);
    const factsDifferencesCombined = {
      ...factsDifferences?.added,
      ...factsDifferences?.deleted,
      ...factsDifferences?.updated,
    };

    // Don't re-run the rules engine if the facts didn't change (to prevent duplicate calls)
    if (!isEmpty(factsDifferencesCombined)) {
      debug("running engine", () => factsDifferences);
      lastFacts.current = { ...facts };
      engine.current
        .run(currentAndLastFacts)
        .then((results) => {
          debug("run results", () => results);
          results?.events?.forEach((event) => {
            const { delay } = event.params ?? {};
            const eventCallback = allEventCallbacks?.[event.type];

            if (delay) {
              delayEvent(eventCallback, delay, event);
            } else {
              fireEvent(eventCallback, event);
            }
          });
        })
        .catch((error) => {
          logger.error("Error occurred running rules engine.", error);
        });
    }
  }, [eventTimes, facts]);

  return null;

  function getRules() {
    const processedRules = replaceInRules(rules);

    debug("rules", () => JSON.stringify(processedRules, null, 4));

    return processedRules;
  }

  function getOperators() {
    return {
      ...operators,
      doesNotMatch: (factValue, testValue) => !factValue?.match?.(testValue),
      isNotEmpty: (factValue = {}) => Object.values(factValue).length !== 0,
      isNotUndefined: (factValue) => factValue !== undefined,
      isUndefined: (factValue) => factValue === undefined,
      matches: (factValue, testValue) => !!factValue?.match?.(testValue),
    };
  }

  function getLastFactsPrefixedWithLast() {
    return Object.assign(
      {},
      ...Object.keys(lastFacts.current ?? {}).map((key) => ({
        [`last_${key}`]: lastFacts.current[key],
      }))
    );
  }

  function getEventCallbacks() {
    return {
      ...eventCallbacks,
      recordEventTime,
    };
  }

  function delayEvent(eventCallback, delay, event) {
    debug(`firing DELAYED event in ${delay}ms`, () => event);
    setTimeout(() => {
      fireEvent(eventCallback, event);
    }, delay);
  }

  function fireEvent(eventCallback, event) {
    debug(`firing event`, () => event);
    eventCallback?.(event.params);
  }

  function recordEventTime(params) {
    const { delay, eventName } = params;

    if (delay) {
      setTimeout(() => {
        updateEventTimes(eventName);
      }, delay);
    } else {
      updateEventTimes(eventName);
    }
  }

  function updateEventTimes(eventName) {
    const eventTime = swaDate().valueOf();

    setEventTimes((oldEventTimes) => {
      const newEventTimes = {
        ...oldEventTimes,
        [eventName]: eventTime,
      };

      debug("recorded event time", () => JSON.stringify(newEventTimes));

      return newEventTimes;
    });
  }

  function replaceInRules(rulesToProcess) {
    return JSON.parse(
      JSON.stringify(rulesToProcess, (key, value) => {
        const dictionaryKey = value?.rule ?? value?.condition;
        let replacement = value;

        if (value && typeof value === "object" && dictionaryKey) {
          const dictionaryItem = rulesDictionary[dictionaryKey];

          if (dictionaryItem) {
            replacement = replaceInRules(dictionaryItem);
          } else {
            logger.error("Key not found in rules engine dictionary.", {
              dictionaryKey,
            });
          }
        }

        return replacement;
      })
    );
  }

  function debug(message, context) {
    debugEnabled && console.debug(`RULES ENGINE - ${message}:`, context());
  }
};

export const rulesEnginePropTypes = {
  /**
   * Allow facts to be undefined in the dictionary without throwing exceptions.
   */
  allowUndefinedFacts: PropTypes.bool,

  /**
   * Object containing event callbacks to fire when facts meet the configured conditions.
   */
  eventCallbacks: PropTypes.object,

  /**
   * Object of facts to be evaluated against.  Each time the facts change, the rules engine will run
   * and fire any events that are successful.
   */
  facts: PropTypes.object,

  /**
   * Additional operators to be added to the rules engine.
   * See: https://github.com/CacheControl/json-rules-engine/blob/master/docs/engine.md
   */
  operators: PropTypes.object,

  /**
   * Rules that contain conditions and events.
   * See: https://github.com/CacheControl/json-rules-engine/blob/master/docs/rules.md
   */
  rules: PropTypes.arrayOf(PropTypes.object),

  /**
   * Object containing reusable rules and conditions.
   */
  rulesDictionary: PropTypes.object,
};

RulesEngine.propTypes = rulesEnginePropTypes;
RulesEngine.defaultProps = {
  rules: [],
};
