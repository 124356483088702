import { AccordionBlock } from "../AccordionBlock";
import { Banner } from "../Banner";
import { BannerGrid } from "../BannerGrid";
import { BlockPlacement } from "../BlockPlacement";
import { ButtonGroupAdapter } from "../ButtonGroupAdapter";
import { ContentFragment } from "../ContentFragment";
import { FlexPlacement } from "../FlexPlacement";
import { HeroImagePlacement } from "../HeroImagePlacement";
import { LinkGroup } from "../LinkGroup";
import { ListBlockPlacement } from "../ListBlockPlacement";
import {
  AdaptiveListPlacement,
  CarouselPlacement,
  DynamicListPlacement,
  MatrixPlacement,
} from "../ListPlacements";
import { MessageBlock } from "../MessageBlock/MessageBlock";
import { PageLayout } from "../PageLayout";
import { PlacementList } from "../PlacementList";
import { SystemMessage } from "../SystemMessage";
import { TextBlock } from "../TextBlock";

export const commonPlacementComponents = {
  "accordion-block": AccordionBlock,
  "adaptive-list": AdaptiveListPlacement,
  banner: Banner,
  "banner-grid": BannerGrid,
  "block-placement": BlockPlacement,
  "button-group": ButtonGroupAdapter,
  carousel: CarouselPlacement,
  "dynamic-list": DynamicListPlacement,
  "flex-placement": FlexPlacement,
  fragment: ContentFragment,
  "hero-image": HeroImagePlacement,
  "link-group": LinkGroup,
  "list-block": ListBlockPlacement,
  matrix: MatrixPlacement,
  "message-block": MessageBlock,
  "page-layout": PageLayout,
  PlacementList,
  "system-message": SystemMessage,
  "text-block": TextBlock,
};
