/* eslint-disable sort-keys */
import globalDefines from "./globalDefines.module.scss";

export const colors = {
  primary100: globalDefines.primary100,
  primary200: globalDefines.primary200,
  primary300: globalDefines.primary300,
  primary400: globalDefines.primary400,
  primary500: globalDefines.primary500,
  primary600: globalDefines.primary600,
  primary700: globalDefines.primary700,
  primary800: globalDefines.primary800,
  primary900: globalDefines.primary900,
  primary950: globalDefines.primary950,

  secondary100: globalDefines.secondary100,
  secondary200: globalDefines.secondary200,
  secondary300: globalDefines.secondary300,
  secondary400: globalDefines.secondary400,
  secondary500: globalDefines.secondary500,
  secondary600: globalDefines.secondary600,
  secondary700: globalDefines.secondary700,
  secondary800: globalDefines.secondary800,
  secondary900: globalDefines.secondary900,

  secondaryVacations100: globalDefines.secondaryVacations100,
  secondaryVacations200: globalDefines.secondaryVacations200,
  secondaryVacations300: globalDefines.secondaryVacations300,
  secondaryVacations400: globalDefines.secondaryVacations400,
  secondaryVacations500: globalDefines.secondaryVacations500,
  secondaryVacations600: globalDefines.secondaryVacations600,
  secondaryVacations700: globalDefines.secondaryVacations700,
  secondaryVacations800: globalDefines.secondaryVacations800,
  secondaryVacations900: globalDefines.secondaryVacations900,

  tertiary100: globalDefines.tertiary100,
  tertiary200: globalDefines.tertiary200,
  tertiary300: globalDefines.tertiary300,
  tertiary400: globalDefines.tertiary400,
  tertiary500: globalDefines.tertiary500,
  tertiary600: globalDefines.tertiary600,
  tertiary700: globalDefines.tertiary700,
  tertiary800: globalDefines.tertiary800,
  tertiary900: globalDefines.tertiary900,

  tertiaryVacations100: globalDefines.tertiaryVacations100,
  tertiaryVacations200: globalDefines.tertiaryVacations200,
  tertiaryVacations300: globalDefines.tertiaryVacations300,
  tertiaryVacations400: globalDefines.tertiaryVacations400,
  tertiaryVacations500: globalDefines.tertiaryVacations500,
  tertiaryVacations600: globalDefines.tertiaryVacations600,
  tertiaryVacations700: globalDefines.tertiaryVacations700,
  tertiaryVacations800: globalDefines.tertiaryVacations800,
  tertiaryVacations900: globalDefines.tertiaryVacations900,

  success100: globalDefines.success100,
  success200: globalDefines.success200,
  success300: globalDefines.success300,
  success400: globalDefines.success400,
  success500: globalDefines.success500,
  success600: globalDefines.success600,
  success700: globalDefines.success700,
  success800: globalDefines.success800,
  success900: globalDefines.success900,

  warning100: globalDefines.warning100,
  warning200: globalDefines.warning200,
  warning300: globalDefines.warning300,
  warning400: globalDefines.warning400,
  warning500: globalDefines.warning500,
  warning600: globalDefines.warning600,
  warning700: globalDefines.warning700,
  warning800: globalDefines.warning800,
  warning900: globalDefines.warning900,

  danger100: globalDefines.danger100,
  danger200: globalDefines.danger200,
  danger300: globalDefines.danger300,
  danger400: globalDefines.danger400,
  danger500: globalDefines.danger500,
  danger600: globalDefines.danger600,
  danger700: globalDefines.danger700,
  danger800: globalDefines.danger800,
  danger900: globalDefines.danger900,

  neutral100: globalDefines.neutral100,
  neutral200: globalDefines.neutral200,
  neutral300: globalDefines.neutral300,
  neutral400: globalDefines.neutral400,
  neutral500: globalDefines.neutral500,
  neutral600: globalDefines.neutral600,
  neutral700: globalDefines.neutral700,
  neutral750: globalDefines.neutral750,
  neutral800: globalDefines.neutral800,
  neutral900: globalDefines.neutral900,

  black: globalDefines.black,
  blue1: globalDefines.blue1,
  blue1Light: globalDefines.blue1Light,
  blue2: globalDefines.blue2,
  blue3: globalDefines.blue3,
  blue3Light: globalDefines.blue3Light,
  blue4: globalDefines.blue4,
  gray1: globalDefines.gray1,
  gray2: globalDefines.gray2,
  gray3: globalDefines.gray3,
  gray4: globalDefines.gray4,
  gray5: globalDefines.gray5,
  gray5Dark: globalDefines.gray5Dark,
  green1: globalDefines.green1,
  green2: globalDefines.green2,
  red1: globalDefines.red1,
  red2: globalDefines.red2,
  yellow1: globalDefines.yellow1,
  white: globalDefines.white,

  primaryFacebook: globalDefines.primaryFacebook,
  secondaryFacebook: globalDefines.secondaryFacebook,
  primaryInstagram: globalDefines.primaryInstagram,
  secondaryInstagram: globalDefines.secondaryInstagram,
  primaryTwitter: globalDefines.primaryTwitter,
  secondaryTwitter: globalDefines.secondaryTwitter,
  primaryYoutube: globalDefines.primaryYoutube,
  secondaryYoutube: globalDefines.secondaryYoutube,
  primaryLinkedin: globalDefines.primaryLinkedin,
  secondaryLinkedin: globalDefines.secondaryLinkedin,
  primaryPinterest: globalDefines.primaryPinterest,
  secondaryPinterest: globalDefines.secondaryPinterest,
};

export const grid = {
  largeColumnSpacing: globalDefines.largeGutterSpacing,
  largeNumCols: globalDefines.largeNumCols,
  largePadding: globalDefines.largePaddingHorizontal,
  largeRowSpacing: globalDefines.largeRowSpacing,

  mediumColumnSpacing: globalDefines.mediumGutterSpacing,
  mediumNumCols: globalDefines.mediumNumCols,
  mediumPadding: globalDefines.mediumPaddingHorizontal,
  mediumRowSpacing: globalDefines.mediumRowSpacing,

  smallColumnSpacing: globalDefines.smallGutterSpacing,
  smallNumCols: globalDefines.smallNumCols,
  smallPadding: globalDefines.smallPaddingHorizontal,
  smallRowSpacing: globalDefines.smallRowSpacing,

  xlargeColumnSpacing: globalDefines.xlargeGutterSpacing,
  xlargeNumCols: globalDefines.xlargeNumCols,
  xlargePadding: globalDefines.xlargePaddingHorizontal,
  xlargeRowSpacing: globalDefines.xlargeRowSpacing,
};

export const media = {
  largeMin: globalDefines.largeMin,
  largeMax: globalDefines.largeMax,
  mediumMin: globalDefines.mediumMin,
  mediumMax: globalDefines.mediumMax,
  smallMin: globalDefines.smallMin,
  smallMax: globalDefines.smallMax,
  xlargeMin: globalDefines.xlargeMin,
};
