import PropTypes from "prop-types";
import React from "react";
import { animated, useSpring } from "react-spring";

import { classNames } from "@swa-ui/string";

import { transformationActions } from "../defines/transformationActions";
import styles from "./Transform.module.scss";

const SCREEN_CAPTURE_AND_UNIT_TEST_STYLES = {
  DELAY: 0,
  DURATION: 0.001,
};

export const Transform = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    clearAll,
    delay,
    duration,
    id,
    immediate,
    onTransformationEnd,
    precision,
    stackingContext,
    style,
    transformOrigin,
    transformations,
  } = props;
  const springStyles = useSpring(getStyle());

  return (
    <animated.div {...getProps()} ref={ref}>
      {children}
    </animated.div>
  );

  function getProps() {
    const animationProps = {
      "aria-hidden": props["aria-hidden"],
      className: getClass(),
      id,
      style: {
        ...style,
        ...springStyles,
      },
    };

    if (clearAll) {
      delete animationProps.style.height;
      delete animationProps.style.opacity;
      delete animationProps.style.transform;
      delete animationProps.style.width;
    }

    return animationProps;
  }

  function getClass() {
    return classNames(className, {
      [styles[`origin-${transformOrigin}`]]: transformOrigin,
      [styles.stackingContext]: stackingContext,
    });
  }

  function getStyle() {
    const screenCaptureOrUnitTestEnv =
      process.env.NODE_ENV === "screen-capture" || process.env.NODE_ENV === "test";
    const allStyles = {
      config: {
        duration: screenCaptureOrUnitTestEnv
          ? SCREEN_CAPTURE_AND_UNIT_TEST_STYLES.DURATION
          : duration,
        precision,
      },
      delay: screenCaptureOrUnitTestEnv ? SCREEN_CAPTURE_AND_UNIT_TEST_STYLES.DELAY : delay,
      immediate,
      onRest: onTransformationEnd,
    };
    const transforms = [];
    let height;
    let opacity;
    let width;

    if (transformations) {
      transformations.map((transformation) => {
        const { action, amount } =
          typeof transformation === "string"
            ? transformationActions[transformation]
            : transformation;

        if (action !== "none") {
          if (action === "height") {
            height = amount;
          } else if (action === "opacity") {
            opacity = amount;
          } else if (action === "width") {
            width = amount;
          } else {
            transforms.push(`${action}(${amount})`);
          }
        }
      });

      if (height !== undefined) {
        allStyles.height = height;
      }

      if (opacity !== undefined) {
        allStyles.opacity = opacity;
      }

      if (width !== undefined) {
        allStyles.width = width;
      }

      if (transforms.length) {
        allStyles.transform = transforms.join(" ");
      }
    }

    return allStyles;
  }
});

export const transformPropTypes = {
  /** Indicates if content is hidden. */
  "aria-hidden": PropTypes.bool,

  /** Content to be rendered. This is the content that Transform will act upon. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * If clearAll is set, then all transform properties will be removed from the children element.
   * ReactSpring retains any transform values applied to elements with previous transitions, so
   * Transform must explicitly remove them from ReactSpring when clearAll is given.
   */
  clearAll: PropTypes.bool,

  /** Milliseconds to delay before animation begins. */
  delay: PropTypes.number,

  /**
   * By default, the duration of the animation will be determined by ReactSpring's physics algorithm, but can be set
   * to a milliseconds value, or zero for immediate movement. If zero is given, the onTransformationEnd event will not
   * be fired.
   */
  duration: PropTypes.number,

  /** Unique id given to Transform container */
  id: PropTypes.string,

  /** If immediate is true, no animation will occur and the transformation will be immediate. */
  immediate: PropTypes.bool,

  /** Function called when the animation is complete. Will not be called when zero duration is given. */
  onTransformationEnd: PropTypes.func,

  /**
   * How close to the end result the animated value gets before we consider it to be "there". ReactSpring default is
   * 0.01.
   */
  precision: PropTypes.number,

  /**
   * Option allows the caller to indicate if a new stacking order should be defined, which changes z-index's effect.
   */
  stackingContext: PropTypes.bool,

  /** style provides a way to add CSS values in addition to the properties that will be transformed. */
  style: PropTypes.object,

  /**
   * Array of transformations to be performed. The transform values will be combined by Transform and will animated.
   * It's important to note that no transformation will occur if the new values do not change from the existing
   * values. Also worth noting, height and width can be animated, but animating these values will cause the browser to
   * re-layout and repaint which is very non-performant and should be used judiciously.
   *
   * In addition to the actions listed below, shorthand values can be used such as "rotate90" as defined by
   * transformationActions.
   *
   * Avoid attempting to transform the same value more than once such as having more than one rotateZ actions. Only
   * the last action definition will be applied.
   */
  transformations: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.oneOf(Object.keys(transformationActions)),
      PropTypes.shape({
        action: PropTypes.oneOf([
          "height",
          "none",
          "opacity",
          "rotateX",
          "rotateY",
          "rotateZ",
          "scaleX",
          "scaleY",
          "scaleZ",
          "translateX",
          "translateY",
          "translateZ",
          "width",
        ]),
        amount: PropTypes.string,
      }),
    ])
  ),

  /**
   * transformOrigin pertains to how things are rotated. By default, elements are rotated around the center, but this
   * prop allow flexibility how items rotate.
   */
  transformOrigin: PropTypes.oneOf([
    "center",
    "center-bottom",
    "center-top",
    "left-bottom",
    "left-center",
    "left-top",
    "right-bottom",
    "right-center",
    "right-top",
  ]),
};

Transform.propTypes = transformPropTypes;
