import PropTypes from "prop-types";
import React from "react";
import { Controller, get } from "react-hook-form";

import { AriaLive, informationTipPropTypes } from "@swa-ui/core";
import { InformationTip } from "@swa-ui/core";
import globalRules from "@swa-ui/core/assets/styles/globalRules.module.scss";
import { classNames } from "@swa-ui/string";

import { useFormContext } from "../useFormContext";
import styles from "./FormField.module.scss";

/**
 * GenericFormField provides a consistent way to display form controls with uniform spacing and label treatment.
 */

export const FormField = (props) => {
  const {
    InputComponent,
    caption,
    captionStatus,
    centerContent,
    children,
    className,
    componentProps,
    description,
    disabled,
    fieldFocusFromLabel,
    hideBottomSection,
    hideTopSection,
    id,
    label,
    labelInformationTipProps,
    name,
    renderCaption,
    shouldRegister,
    type,
    useController,
  } = props;
  const {
    formState: { errors },
    register,
    trigger,
    watch,
  } = useFormContext();
  const error = get(errors, name);

  return (
    <span className={getClass()}>
      {!hideTopSection ? renderTopSection() : null}
      {useController ? renderInputWithController() : renderInputWithoutController()}
      {!hideBottomSection ? renderBottomSection() : null}
    </span>
  );

  function renderTopSection() {
    return (
      <div className={styles.topSection}>
        {label && renderLabel()}
        {description && <div {...getDescriptionProps()}>{description}</div>}
      </div>
    );
  }

  function renderBottomSection() {
    return (
      <div className={styles.bottomSection}>
        {!error && (
          <div className={getCaptionClass()}>
            {caption ?? (renderCaption ? renderCaption(props, watch(name)) : "")}&nbsp;
          </div>
        )}
        {error && (
          <div {...getErrorProps()}>
            <AriaLive hiddenFromScreen={false}>{error.message}</AriaLive>
          </div>
        )}
        {captionStatus ? <div className={getCaptionStatusClass()}>{captionStatus}</div> : null}
      </div>
    );
  }

  function renderLabel() {
    const labelProps = getLabelProps();

    return (
      <div className={styles.topSectionLabelContainer}>
        {fieldFocusFromLabel ? (
          <label {...labelProps}>{label}</label>
        ) : (
          <span {...labelProps}>{label}</span>
        )}
        {labelInformationTipProps && <InformationTip {...getInformationTipProps()} />}
      </div>
    );
  }

  function getLabelProps() {
    return {
      className: getLabelClass(),
      "data-test": `${name}Label`,
      htmlFor: label && fieldFocusFromLabel ? getId() : undefined,
    };
  }

  function renderInputWithController() {
    return (
      <Controller
        defaultValue={componentProps.defaultValue}
        name={name}
        render={({
          field: { name: fieldName, onChange, onBlur, ref, value },
          fieldState: { invalid },
        }) => (
          <InputComponent
            {...componentProps}
            {...{ "aria-describedby": error && `${name}Error` }}
            data-test={`${fieldName}Field`}
            disabled={disabled}
            error={invalid}
            id={getId()}
            name={fieldName}
            onBlur={(event) => {
              onBlur(event);
              componentProps?.onBlur?.(event);
            }}
            onChange={(event, eventOptions) => {
              onChange(event);

              if (!eventOptions?.suppressTrigger) {
                trigger(fieldName);
              }

              componentProps?.onChange && componentProps.onChange(event);
            }}
            ref={ref}
            value={componentProps?.value ?? value}
          />
        )}
      />
    );
  }

  function renderInputWithoutController() {
    return <InputComponent {...getFieldProps()}>{children}</InputComponent>;
  }

  function getFieldProps() {
    return {
      ...(name && shouldRegister && register(name)),
      ...componentProps,
      ...{
        error: error !== undefined,
        name,
        type,
      },
      "aria-describedby": error && `${name}Error`,
      "data-test": `${name}Field`,
      disabled,
      id: getId(),
    };
  }

  function getInformationTipProps() {
    return {
      className: styles.topSectionInformationTip,
      ...labelInformationTipProps,
    };
  }

  function getDescriptionProps() {
    return {
      className: getDescriptionClass(),
      "data-test": `${name}Description`,
    };
  }

  function getErrorProps() {
    return {
      className: getErrorClass(),
      "data-test": `${name}Error`,
      id: `${name}Error`,
    };
  }

  function getClass() {
    return classNames(className, {
      [styles.centerContent]: centerContent,
      [styles.disabled]: disabled,
      [styles.error]: error,
      [styles.formField]: true,
    });
  }

  function getLabelClass() {
    return classNames(globalRules.labelSmall, styles.topSectionLabel);
  }

  function getDescriptionClass() {
    return classNames(globalRules.labelSmall, styles.topSectionDescription);
  }

  function getCaptionClass() {
    return classNames(globalRules.labelSmall, styles.bottomSectionCaption);
  }

  function getErrorClass() {
    return classNames(globalRules.labelSmall, styles.bottomSectionError);
  }

  function getCaptionStatusClass() {
    return classNames(globalRules.labelSmall, styles.bottomSectionCaptionStatus);
  }

  function getId() {
    return id || name;
  }
};

export const formFieldExternalPropTypes = {
  /** Content to appear below the form control. If an error is given, then it will be displayed instead. */
  caption: PropTypes.node,

  /** Text to appear below the form control and to the right of the caption or error. */
  captionStatus: PropTypes.node,

  /** Indicates if child content should be centered in FormField. */
  centerContent: PropTypes.bool,

  /** The content that will be displayed when Container is rendered. */
  children: PropTypes.node,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /**
   * Props to be passed along to child field component. For example, if the field type is 'text', then an Input
   * component will be rendered and it will accept { styleType: 'secondary' }.
   */
  componentProps: PropTypes.object,

  /** Text to appear above form control and to the right of the label. */
  description: PropTypes.node,

  /**
   * When disabled is true, all text like the label and caption will be grayed, and the disabled setting will be
   * passed to the form component to handle as it needs.
   */
  disabled: PropTypes.bool,

  /**
   * For some scenarios, it is desirable to not display anything below the form component. By default, even if there's
   * no caption displayed, there will be a gap below the field.
   */
  hideBottomSection: PropTypes.bool,

  /**
   * For some scenarios, it is desirable to not display anything above the form component. By default, even if there's
   * no label or description, there will be a gap above the field.
   */
  hideTopSection: PropTypes.bool,

  /** ID to be added to form control (field). */
  id: PropTypes.string,

  /** Text to appear above form control. label would typically be present for input and dropdown components. */
  label: PropTypes.node,

  /** Optional InformationTip to be rendered next to FormField's label. */
  labelInformationTipProps: PropTypes.shape(informationTipPropTypes),

  /** Name that will be given to form field for submission. */
  name: PropTypes.string.isRequired,

  /** Type that will be passed to the InputComponent */
  type: PropTypes.string,
};

FormField.propTypes = {
  ...formFieldExternalPropTypes,

  /** Should a label be rendered. */
  fieldFocusFromLabel: PropTypes.bool.isRequired,

  /** Component to be wrapped with a form field. */
  InputComponent: PropTypes.elementType.isRequired,

  /** Render function that can be used to render the caption. */
  renderCaption: PropTypes.func,

  /** Should the field be registered with use hook form. */
  shouldRegister: PropTypes.bool.isRequired,

  /** Should a use hook form controller be used. */
  useController: PropTypes.bool.isRequired,
};
