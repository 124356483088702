import React from "react";

import { CallToAction } from "../CallToAction";
import styles from "./ListPlacements.module.scss";

export const ListCallToAction = (props) => {
  const { callToAction } = props;

  return (
    callToAction && (
      <div className={styles.callToAction}>
        <CallToAction {...callToAction} />
      </div>
    )
  );
};
