import React from "react";

import { ScrollToTop } from "@swa-ui/browser";
import { Grid, Message } from "@swa-ui/core";
import i18n from "@swa-ui/locale";
import { classNames } from "@swa-ui/string";

import styles from "./MaintenanceMessage.module.scss";

/**
 * MaintenanceMessage is a functional component that renders a maintenance message layout.
 * It uses a Grid component to structure the layout and includes a non-dismissible message
 * with a warning style. The message includes a title and body text.
 */

export const MaintenanceMessage = () => {
  return (
    <Grid className={classNames(styles.maintenanceMessage, styles.layout)}>
      <div className={styles.message}>
        <ScrollToTop />
        <Message {...getMessageProps()} />
      </div>
    </Grid>
  );

  function getMessageProps() {
    return {
      children: renderMaintenanceInformation(),
      closeAvailable: false,
      headingProps: {
        children: i18n("MaintenanceMessage__TITLE"),
      },
      styleType: "warning",
    };
  }

  function renderMaintenanceInformation() {
    return (
      <div data-test="maintenanceMessageBody">
        <p>{i18n("MaintenanceMessage__TEXT1")}</p>
        <p>{i18n("MaintenanceMessage__TEXT2")}</p>
        <p className={styles.footer}>{i18n("MaintenanceMessage__FOOTER")}</p>
      </div>
    );
  }
};
