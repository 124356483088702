import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { window } from "@swa-ui/browser";
import { classNames, getUniqueId } from "@swa-ui/string";

import { Area, areaPropTypes } from "../Area";
import { Button } from "../Button";
import { Caption } from "../Caption";
import { keyCodes } from "../defines/keyCodes";
import { Icon } from "../Icon";
import styles from "./Drawer.module.scss";

const DELAY_FOR_BROWSER = 16;

/**
 * Drawer shows and hides content when a "trigger button" is selected". The content can be revealed
 * as a simple expanding element using Area, or can be rendered as Caption, depending on the
 * showPointer prop.
 */

export const Drawer = React.forwardRef((props, ref) => {
  const {
    alignedRight,
    animate,
    children,
    className,
    id,
    onRevealChange,
    onTransitionEnd,
    revealed,
    showPointer,
    triggerClass,
    triggerContent,
  } = props;
  const [open, setOpen] = useState(revealed);
  const containerRef = useRef();
  const triggerRef = ref || useRef();
  const uniqueId = id || getUniqueId("icon-id");

  useEffect(() => {
    setOpen(revealed);
  }, [revealed]);

  // TODO this logic is repeated across several hooks and should be refactored PHX-1115
  useEffect(() => {
    if (open) {
      addEventListeners();
    } else {
      removeEventListeners();
    }

    return () => {
      removeEventListeners();
    };
  }, [open]);

  return (
    <div {...getProps()}>
      {showPointer ? <Caption {...getCaptionProps()}>{renderTrigger()}</Caption> : renderArea()}
    </div>
  );

  function renderArea() {
    return (
      <>
        {renderTrigger()}
        <Area animate={animate} id={uniqueId} onTransitionEnd={onTransitionEnd} revealed={open}>
          {children}
        </Area>
      </>
    );
  }

  function renderTrigger() {
    return (
      <Button {...getButtonProps()}>
        {triggerContent}
        <Icon {...getIconProps()} />
      </Button>
    );
  }

  function getProps() {
    return {
      className: classNames(className),
      "data-test": props["data-test"],
      ref: containerRef,
    };
  }

  function getCaptionProps() {
    return {
      adjoiningContent: children,
      alignment: "center",
      bestFit: false,
      location: open ? "below" : "hidden",
      onTransformationEnd: onTransitionEnd,
    };
  }

  function getButtonProps() {
    return {
      "aria-controls": uniqueId,
      "aria-expanded": !!open,
      className: getButtonClass(),
      onClick: handleClick,
      ref: triggerRef,
      styleType: "no-style",
    };
  }

  function getIconProps() {
    return {
      className: styles.indicator,
      color: "link",
      custom: { type: open ? "inactive" : "active" },
      name: "ArrowThin",
    };
  }

  function getButtonClass() {
    return classNames(styles.trigger, triggerClass, { [styles.alignedRight]: alignedRight });
  }

  function handleMouseDown(event) {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      event.stopImmediatePropagation();
      setOpen(false);
      setTimeout(() => triggerRef.current.focus(), DELAY_FOR_BROWSER);
    }
  }

  function handleKeyDown(event) {
    if (event.key === keyCodes.KEY_ESCAPE) {
      setOpen(false);
      setTimeout(() => triggerRef.current.focus(), DELAY_FOR_BROWSER);
    }
  }

  function handleClick(event) {
    event.stopPropagation();
    setOpen(!open);
    onRevealChange?.(!open);
  }

  function addEventListeners() {
    window.addEventListener("mousedown", handleMouseDown, true); // NOSONAR
    window.addEventListener("keydown", handleKeyDown); // NOSONAR
  }

  function removeEventListeners() {
    window.removeEventListener("mousedown", handleMouseDown, true);
    window.removeEventListener("keydown", handleKeyDown);
  }
});

Drawer.propTypes = {
  /**
   * Drawer is set to 100% width. triggerContent will be aligned to the right edge of drawer when this props is true.
   */
  alignedRight: PropTypes.bool,

  /** The transition to and from concealed/revealed states will be animated by default. */
  animate: areaPropTypes.animate,

  /** Content to be rendered. This is the content that Transform will act upon. */
  children: PropTypes.node.isRequired,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name to use for the data-test attribute used for automated testing. */
  "data-test": PropTypes.string,

  /** ID to be added to the element. */
  id: PropTypes.string,

  /** Function called when drawer open/close animation is complete. */
  onTransitionEnd: PropTypes.func,

  /** Indicates if the area is initially opened or closed. */
  revealed: areaPropTypes.revealed,

  /**
   * When showPointer is set, then Drawer will show the child content as a Caption. When false, the
   * content will be revealed using Area. The animate prop has no effect when showPointer is true.
   */
  showPointer: PropTypes.bool,

  /** Class name to be applied to trigger element. */
  triggerClass: PropTypes.string,

  /** Text or JSX content, when clicked, will open/close the drawer. */
  triggerContent: PropTypes.node,
};

Drawer.defaultProps = {
  alignedRight: true,
  showPointer: false,
};
