import PropTypes from "prop-types";
import React from "react";

import { useDeviceInfo } from "@swa-ui/browser";
import { ListScroller } from "@swa-ui/core";
import i18n from "@swa-ui/locale";

import { applyOverrides } from "../applyOverrides";
import { ListCallToAction } from "./ListCallToAction";
import { ListHeading } from "./ListHeading";
import listItemTypeDefines from "./listItemTypeDefines";
import styles from "./ListPlacements.module.scss";

export const CarouselPlacement = (props) => {
  const { placement } = props;
  const { callToAction, items, listItemType, settings, title } = placement;
  const { screenSize } = useDeviceInfo();

  return (
    <>
      <ListHeading title={title} />
      <ListScroller {...getListScrollerProps()} />
      <ListCallToAction callToAction={callToAction} />
    </>
  );

  function getListScrollerProps() {
    const { center, numberOfColumns, showPositionIndicator } = applyOverrides(settings, screenSize);

    return {
      "aria-label-current-page": i18n("CarouselPlacement__ARIA_LABEL__CURRENT_PAGE"),
      "aria-label-next": i18n("CarouselPlacement__ARIA_LABEL__NEXT"),
      "aria-label-page-count": i18n("CarouselPlacement__ARIA_LABEL__PAGE_COUNT"),
      "aria-label-previous": i18n("CarouselPlacement__ARIA_LABEL__PREVIOUS"),
      center,
      className: styles.content,
      items: getItems(),
      numberOfColumns,
      showPositionIndicator,
    };
  }

  function getItems() {
    const Component = listItemTypeDefines[listItemType];

    return items.map((item, index) => <Component key={index} {...item} />);
  }
};

CarouselPlacement.propTypes = {
  placement: PropTypes.shape({
    /** List of items to show in Carousel, object shape depends on item type. */
    items: PropTypes.arrayOf(PropTypes.object),

    /** Name of the item component to render. */
    listItemType: PropTypes.string,

    /** Placement configuration. */
    settings: PropTypes.shape({
      /** Center the carousel when possible, pass-through to ListScroller. */
      center: PropTypes.bool,

      /** Number of items show in Carousel, pass-through to ListScroller. */
      numberOfColumns: PropTypes.number,

      /** Override for all properties in settings by device screen size. */
      overrides: PropTypes.object,

      /** Wether or not to show the pages indicator, pass-through to ListScroller. */
      showPositionIndicator: PropTypes.bool,
    }),
  }),
};
