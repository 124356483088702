import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { classNames } from "@swa-ui/string";

import { Background } from "../Background";
import { Button } from "../Button";
import { ControlledPlayList } from "../ControlledPlayList";
import { keyCodes } from "../defines/keyCodes";
import { Icon, iconPropTypes } from "../Icon";
import { Svg, svgPropTypes } from "../Svg";
import { Transform } from "../Transform";
import { createHashCode } from "../utils";
import styles from "./Input.module.scss";

const DELAY_FOR_BLUR = 16;

/**
 * Input instantiates the standard DOM input element with styling appropriate for SWA. Additionally,
 * it supports showing a suffix icon and showing an error state. Input offers many options to
 * enhance application's experience such as restricting input to specific values, allow for
 * different states such as disabled or readonly and many other options.
 */

export const Input = React.forwardRef((props, ref) => {
  const {
    acceptableCharacters,
    autoComplete,
    className,
    defaultValue,
    disabled,
    error,
    id,
    inputClassName,
    inputMode,
    max,
    maxLength,
    min,
    minLength,
    name,
    onBlur,
    onChange,
    onClick,
    onFocus,
    onKeyDown,
    onKeyUp,
    onInput,
    onMouseDown,
    onMouseUp,
    option,
    placeholder,
    readOnly,
    required,
    role,
    size,
    styleType,
    suffixIcon,
    svg,
    textAlign,
    type,
    value,
  } = props;
  const [actionList, setActionlist] = useState([]);
  const [currentlyFocused, setCurrentlyFocused] = useState(undefined);
  const [initialRender, setInitialRender] = useState(true);
  const inputRef = ref || useRef();
  const previousError = useRef(error);
  const previousFocused = useRef();
  const previousOption = useRef(option?.text);

  const STEP_DISPLAY = [
    {
      step: {
        delay: 0.1,
        duration: 0.0001,
        transformations: ["fadeReset", "rotateReset"],
      },
    },
  ];
  const STEP_DISPLAY_FADED = [
    {
      step: {
        duration: 0.0001,
        transformations: ["fade", "rotateReset"],
      },
    },
  ];
  const STEP_DISPLAY_FADED_ERROR = [
    {
      step: {
        duration: 0.0001,
        transformations: ["fade", "rotate45"],
      },
    },
  ];
  const STEP_FADE = [
    {
      step: {
        duration: 250,
        transformations: ["fade", "rotateReset"],
      },
    },
  ];
  const STEP_FADE_IN_CONTENT = [
    {
      step: {
        duration: 0.0001,
        transformations: ["fade", "rotateReset"],
      },
    },
    {
      step: {
        delay: 0.1,
        duration: 200,
        transformations: ["fadeReset", "rotateReset"],
      },
    },
  ];
  const STEP_FADE_IN_ERROR = [
    {
      step: {
        duration: 0.0001,
        transformations: ["fade", "rotate45"],
      },
    },
    {
      step: {
        duration: 200,
        transformations: ["fadeReset", "rotateReset"],
      },
    },
  ];
  const steps = {
    STEP_DISPLAY,
    STEP_DISPLAY_FADED,
    STEP_DISPLAY_FADED_ERROR,
    STEP_FADE,
    STEP_FADE_IN_CONTENT,
    STEP_FADE_IN_ERROR,
  };

  useEffect(() => {
    setActionlist(shouldDisplayOptionOrGraphic() || error ? "STEP_DISPLAY" : "STEP_DISPLAY_FADED");
  }, []);

  useEffect(() => {
    if (!initialRender) {
      if (shouldSomethingTransitionOut()) {
        setActionlist("STEP_FADE");
      } else {
        if (shouldSomethingTransitionIn()) {
          setupFadeIn();
        } else {
          setActionlist(
            shouldDisplayOptionOrGraphic() ? "STEP_DISPLAY" : "STEP_DISPLAY_FADED_ERROR"
          );
        }
      }
    } else {
      setInitialRender(false);
    }
  }, [currentlyFocused, error, option, suffixIcon]);

  return (
    <span className={getClass()} onClick={handleClick}>
      <input {...getInputProps()} />
      {renderGraphic()}
    </span>
  );

  function renderGraphic() {
    return actionList?.length ? (
      <Button {...getButtonProps()}>
        <ControlledPlayList {...getControlledPlayListProps()} />
      </Button>
    ) : null;
  }

  function renderOption() {
    let content;

    if (previousError.current && !previousFocused.current) {
      content = renderGraphicError();
    } else {
      if (shouldDisplayOptionOrGraphic()) {
        content = renderOptionContent();
      } else if (!currentlyFocused && error) {
        content = renderGraphicError();
      }
    }

    return content;
  }

  function renderOptionContent() {
    return option?.text ? renderOptionText() : suffixIcon || svg ? renderLegacyGraphic() : null;
  }

  function renderGraphicError() {
    return (
      <Background shape="circle" color={disabled ? "disabled" : "error"}>
        <Transform {...getErrorTransformationProps()}>
          <Icon
            className={styles.contollerIcon}
            color={disabled ? "standard-disabled-background" : "white"}
            name="Exclamation"
          />
        </Transform>
      </Background>
    );
  }

  function renderOptionText() {
    return <span className={styles.optionText}>{previousOption.current}</span>;
  }

  function renderLegacyGraphic() {
    return suffixIcon ? <Icon {...getSuffixIconProps()} /> : <Svg {...getSvgImageProps()} />;
  }

  function getControlledPlayListProps() {
    const playList = steps[actionList];

    playList.forEach((listItem) => (listItem.step.children = renderOption));

    return {
      animationToken: `${actionList}-${disabled}-${createHashCode(JSON.stringify(suffixIcon))}`,
      onPlayListComplete: handlePlayListComplete,
      playList,
      transformOrigin: "center",
    };
  }

  function getErrorTransformationProps() {
    return { transformation: ["shrinkX", "shrinkY"] };
  }

  function getInputProps() {
    return {
      "aria-activedescendant": props["aria-activedescendant"],
      "aria-controls": props["aria-controls"],
      "aria-describedby": props["aria-describedby"],
      "aria-disabled": disabled,
      "aria-expanded": props["aria-expanded"],
      "aria-invalid": error,
      "aria-label": props["aria-label"],
      "aria-labelledby": props["aria-labelledby"],
      "aria-owns": props["aria-owns"],
      "aria-required": required,
      autoComplete,
      className: getInputClass(),
      "data-test": props["data-test"],
      defaultValue,
      disabled,
      id,
      inputMode,
      max,
      maxLength,
      min,
      minLength,
      name,
      onBlur: handleBlur,
      onChange,
      onClick,
      onFocus: handleFocus,
      onInput: handleInput,
      onKeyDown,
      onKeyUp,
      onMouseDown,
      onMouseUp,
      placeholder,
      readOnly: readOnly && currentlyFocused,
      ref: inputRef,
      required,
      role,
      type,
      value,
    };
  }

  function getSvgImageProps() {
    return {
      className: getSvgClass(),
      ...svg,
      disabled,
    };
  }

  function getButtonProps() {
    return {
      "aria-hidden": (currentlyFocused && error) || (!error && !suffixIcon && !option?.text),
      "aria-label": option?.["aria-label"],
      className: classNames(styles.button, {
        [styles.passMouseEventsThrough]: !option?.onClick,
      }),
      "data-test": `${props["data-test"]}Button`,
      onKeyDown: handleKeyDown,
      styleType: "no-style",
      tabIndex: shouldDisableFocus() ? -1 : undefined,
    };
  }

  function getSuffixIconProps() {
    return {
      ...getCommonIconProps(),
      ...suffixIcon,
      disabled,
    };
  }

  function getCommonIconProps() {
    return {
      className: styles.icon,
      size: "size24",
    };
  }

  function getClass() {
    return classNames({
      [className]: className,
      [styles.disabled]: disabled,
      [styles.input]: true,
      [styles.secondary]: styleType === "secondary",
      [styles.small]: size === "small",
    });
  }

  function getInputClass() {
    return classNames({
      [inputClassName]: inputClassName,
      [styles.center]: textAlign === "center",
      [styles.error]: error,
      [styles.iconAllowance]: suffixIcon || (error && !currentlyFocused) || option?.text,
      [styles.inputElement]: true,
      [styles.left]: textAlign === "left",
      [styles.right]: textAlign === "right",
    });
  }

  function getSvgClass() {
    return classNames(svg.className, styles.svg);
  }

  function handleBlur(event) {
    setTimeout(() => setCurrentlyFocused(false), DELAY_FOR_BLUR);
    onBlur && onBlur(event);
  }

  function handleInput(event) {
    if (onInput) {
      onInput(event);
    } else if (acceptableCharacters?.length) {
      const acceptable = acceptableCharacters.split("");
      const text = event.target.value.split("");

      event.target.value = text
        .filter((character) => acceptable.indexOf(character) !== -1)
        .join("");
    }
  }

  function handleFocus(event) {
    setCurrentlyFocused(true);
    onFocus && onFocus(event);
  }

  function handlePlayListComplete() {
    if (shouldSomethingTransitionIn()) {
      setupFadeIn();
    }

    previousError.current = error;
    previousFocused.current = currentlyFocused;
    previousOption.current = option?.text;
  }

  function handleClick(event) {
    if (!option?.onClick) {
      inputRef?.current?.focus && inputRef.current.focus();
    } else if (event.target.classList.contains(styles.optionText)) {
      option.onClick(event);
    }
  }

  function handleKeyDown(event) {
    const { key } = event;

    if (key === keyCodes.KEY_SPACE || key === keyCodes.KEY_ENTER) {
      event.preventDefault();
      event.stopPropagation();

      option?.onClick?.();
    }
  }

  function setupFadeIn() {
    let actions;

    if (error) {
      if (currentlyFocused) {
        actions = fadeIn();
      } else {
        actions = "STEP_FADE_IN_ERROR";
      }
    } else {
      actions = fadeIn();
    }

    setActionlist(actions);
  }

  function fadeIn() {
    return shouldDisplayOptionOrGraphic() ? "STEP_FADE_IN_CONTENT" : "STEP_DISPLAY_FADED";
  }

  function shouldSomethingTransitionOut() {
    return (
      (wasContentDisplayed() && shouldDisplayError()) ||
      (wasErrorDisplayed() && !shouldDisplayError()) ||
      previousOption.current !== option?.text
    );
  }

  function shouldSomethingTransitionIn() {
    return (
      (!previousError.current && shouldDisplayError()) ||
      (previousFocused.current && shouldDisplayError()) ||
      (previousError.current && shouldDisplayOptionOrGraphic()) ||
      previousOption.current !== option?.text
    );
  }

  function wasContentDisplayed() {
    return shouldDisplayOptionOrGraphic() && (!previousError.current || previousFocused.current);
  }

  function wasErrorDisplayed() {
    return previousError.current && !previousFocused.current;
  }

  function shouldDisplayError() {
    return error && !currentlyFocused;
  }

  function shouldDisableFocus() {
    return !option || isFieldEmptyWithEmptyOptionText() || error ? -1 : undefined;
  }

  function isFieldEmptyWithEmptyOptionText() {
    return value?.length === 0 && option?.text?.length === 0;
  }

  function shouldDisplayOptionOrGraphic() {
    return option !== undefined || suffixIcon !== undefined || svg !== undefined;
  }
});

export const inputPropTypes = {
  /**
   * List of keys that will be accepted as valid input. This array should be single characters strings
   * like: '0123456789'. For the sake of simplicity, there is no logic to determine if "invalid"
   * characters are pasted into the field.
   */
  acceptableCharacters: PropTypes.string,

  /** aria-activedescendant to manage focus by referring to the current active element. */
  "aria-activedescendant": PropTypes.string,

  /** aria-controls is the option id when Input controls another element like the DropDown component. */
  "aria-controls": PropTypes.string,

  /** aria-describedby id to element which provides additional accessibility description of input element. */
  "aria-describedby": PropTypes.string,

  /** aria-expanded to indicate whether the controlled element is expanded or collapsed. */
  "aria-expanded": PropTypes.bool,

  /** aria-label text to provide additional accessibility description of input element. */
  "aria-label": PropTypes.string,

  /** aria-labelledby id to element which provides additional accessibility description of input element. */
  "aria-labelledby": PropTypes.string,

  /** aria-owns defines a parent/child relationship when the DOM hierarchy cannot provide the relationship. */
  "aria-owns": PropTypes.string,

  /** HTML input element attribute for autoComplete. */
  autoComplete: PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component
   * for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Name to use for the data-test attribute used for automated testing. */
  "data-test": PropTypes.string,

  /**
   * Initial value for input. If given, Input assumes that the component will not be "controlled" and
   * the value will be maintained in the DOM.
   */
  defaultValue: PropTypes.string,

  /** Indicates Input should apply disabled styling and ignore mouse and key events. */
  disabled: PropTypes.bool,

  /** Indicates Input should apply error styling and apply aria-invalid attribute. */
  error: PropTypes.bool,

  /** ID to be added to input element. */
  id: PropTypes.string,

  /**
   * Optional class to be added to input element. The className prop is added to the input element's
   * container.
   */
  inputClassName: PropTypes.string,

  /**
   * Optional string to tell mobile device browsers to display an appropriate keyboard for the type
   * of input.
   */
  inputMode: PropTypes.oneOf(["email", "none", "numeric", "tel", "text"]),

  /** Maximum number allowed in number type input element. */
  max: PropTypes.string,

  /** Maximum number of characters allowed in input element. */
  maxLength: PropTypes.number,

  /** Minimum number allowed in number type input element. */
  min: PropTypes.string,

  /** Minimum number of characters allowed in input element. */
  minLength: PropTypes.number,

  /** Name to be added to input element. */
  name: PropTypes.string,

  /** Optional event handler to learn when focus is lost from input element. */
  onBlur: PropTypes.func,

  /** Optional event handler to learn when input element's content is changed. */
  onChange: PropTypes.func,

  /** Optional event handler to learn when input element is clicked. */
  onClick: PropTypes.func,

  /** Optional event handler to learn when focus is given to input element. */
  onFocus: PropTypes.func,

  /** Optional event handler to service Input event. */
  onInput: PropTypes.func,

  /** Optional event handler to learn when a key is pressed. */
  onKeyDown: PropTypes.func,

  /** Optional event handler to learn when a key is released. */
  onKeyUp: PropTypes.func,

  /** Optional event handler to learn when a mouse button pressed. */
  onMouseDown: PropTypes.func,

  /** Text to display for button "overlaid" on right side of input. */
  option: PropTypes.shape({
    "aria-label": PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.string,
  }),

  /** Optional value to show when field is empty. */
  placeholder: PropTypes.string,

  /** Indicates Input should be immutable and ignore mouse and key events. */
  readOnly: PropTypes.bool,

  /** Indicates Input should apply aria-required attribute. */
  required: PropTypes.bool,

  /** Indicates the ARIA role. */
  role: PropTypes.string,

  /** When the input is focused, the entire input content is selected. */
  // TODO -- currently not implemented
  selectAllOnFocus: PropTypes.bool,

  /** Indicates the height and padding of the input element. */
  size: PropTypes.oneOf(["large", "small"]),

  /** Indicates the style of the input element. */
  styleType: PropTypes.oneOf(["primary", "secondary"]),

  /** Icon that can be placed on right side of Input. See Icon for more details. */
  suffixIcon: PropTypes.shape({
    actions: iconPropTypes.actions,
    background: iconPropTypes.background,
    border: iconPropTypes.border,
    color: iconPropTypes.color,
    custom: iconPropTypes.custom,
    name: iconPropTypes.name,
    shrink: iconPropTypes.shrink,
    size: iconPropTypes.size,
  }),

  /**
   * SVG that can be placed on right side of Input. See Svg for more details. Note, that because Svgs
   * are different sizes, the caller will need to set the SVG's class or style to position it. The
   * caller must also set right-padding on the input to ensure the SVG doesn't block the text content
   * rendered below in the input field.
   */
  svg: PropTypes.shape({
    actions: svgPropTypes.actions,
    background: svgPropTypes.background,
    border: svgPropTypes.border,
    name: svgPropTypes.name,
    size: svgPropTypes.size,
  }),

  /** Indicates how the value is positioned inside the input element. */
  textAlign: PropTypes.oneOf(["center", "left", "right"]),

  /** Translates characters as they are typed. */
  // TODO -- currently not implemented
  translate: PropTypes.string,

  /** Type to be added to input element, such as 'text' or 'email'. */
  type: PropTypes.string,

  /** Value to be maintained by caller. This will define Input as a controlled component. */
  value: PropTypes.string,
};

Input.propTypes = inputPropTypes;

Input.defaultProps = {
  autoComplete: "off",
  size: "large",
  styleType: "primary",
  type: "text",
};
