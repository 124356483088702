import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { classNames } from "@swa-ui/string";

import styles from "./ProgressBar.module.scss";

const DELAY_FOR_BROWSER = 16;

/** Displays progress bar as a percentage of completion, as well as a label. */

export const ProgressBar = (props) => {
  const { className, completeness, label, styleType } = props;
  const [completion, setCompletion] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setCompletion(completeness >= 100 ? 100 : completeness);
    }, DELAY_FOR_BROWSER);
  }, [completeness]);

  return (
    <div {...getProps()}>
      <progress {...getProgressBarProps()}>{completion}</progress>
      {label && renderLabel()}
    </div>
  );

  function renderLabel() {
    return typeof label === "string" ? <span className={styles.label}>{label}</span> : label;
  }

  function getProps() {
    return {
      className: classNames(className),
    };
  }

  function getProgressBarProps() {
    return {
      "aria-describedby": props["aria-describedby"],
      "aria-label": props["aria-label"],
      "aria-labelledby": props["aria-labelledby"],
      className: classNames(styles.filledBar, {
        [styles.primary]: styleType === "primary",
        [styles.secondary]: styleType === "secondary",
      }),
      max: 100,
      value: completion,
    };
  }
};

ProgressBar.propTypes = {
  /** aria-describedby id to element which provides additional accessibility description of ProgressBar element. */
  "aria-describedby": PropTypes.string,

  /** aria-label text to provide additional accessibility description of ProgressBar element. */
  "aria-label": PropTypes.string,

  /** aria-labelledby id to element which provides additional accessibility description of ProgressBar element. */
  "aria-labelledby": PropTypes.string,

  /** Class name that can be added to the outermost container. */
  className: PropTypes.string,

  /**
   * Numeric value to indicate how far the bar will be shown. This value should be between zero and one hundred.
   */
  completeness: PropTypes.number,

  /** Text to be displayed beneath progress bar. Can take a string or React node. */
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),

  /**
   * Defines which of the two predefined colors that should be used for the progress bar.
   */
  styleType: PropTypes.oneOf(["primary", "secondary"]),
};

ProgressBar.defaultProps = {
  completeness: 0,
  styleType: "primary",
};
