import PropTypes from "prop-types";
import React from "react";

import { Grid, Layout, List, Message } from "@swa-ui/core";

import { HtmlValue } from "../HtmlValue";
import styles from "./MessageBlock.module.scss";

/**
 * MessageBlock renders the Message core component, and applies WCM placement data overrides
 * for various props based on screen size.
 */

export const MessageBlock = (props) => {
  const { placement } = props;
  const { fullWidth = false, messages } = placement ?? {};
  const Container = fullWidth ? React.Fragment : Grid;

  return (
    messages?.length && (
      <Container>
        <List
          className={styles.messageBlock}
          items={messages?.map((message, messageIndex) => renderMessage(message, messageIndex))}
        />
      </Container>
    )
  );

  function renderMessage(messageData, messageIndex = 0) {
    const {
      text: { value },
    } = messageData;
    const isLastItem = messageIndex === (messages?.length ?? -1) + 1;

    return (
      <Layout bottom={isLastItem ? "none" : "medium"}>
        <Message {...getMessageProps(messageData)}>{<HtmlValue htmlValue={value} />}</Message>
      </Layout>
    );
  }

  function getMessageProps(messageProps) {
    const { bannerType: styleType, heading: headingProps } = messageProps;

    return {
      ...props,
      headingProps: {
        ...headingProps,
        children: headingProps?.value,
      },
      styleType,
    };
  }
};

MessageBlock.propTypes = {
  /** Placement object with message implementation details. */
  placement: PropTypes.shape({
    /** Array of messages to render, or single message object.  */
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        /** What kind of Message style is rendered. See @swa-ui/core Message component for details. */
        bannerType: PropTypes.string,

        /** Props to be passed to the Heading component inside of Message. */
        heading: PropTypes.object,

        /** Text value to be used as the Message component's body. */
        text: PropTypes.object,
      })
    ),
  }),
};
