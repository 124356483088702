import React, { createContext, useEffect, useRef, useState } from "react";

import { getBootstrapData } from "@swa-ui/bootstrap";
import { FetchOptions, isResponseOk } from "@swa-ui/fetch";

const LOGIN_FORM_TYPE = "login";
const RE_AUTHENTICATION_FORM_TYPE = "reAuthentication";

export const AuthResponseHandlerContext = createContext();

/**
 * Intercepts responses that require action based on error code. Provider values to be used by AuthResponseDialog.
 */

export const AuthResponseHandler = (props) => {
  const { children } = props;
  const [activeForm, setActiveForm] = useState();
  const [shouldCleanUpSession, setShouldCleanUpSession] = useState(false);
  const [hasExistingAccountInfo, setHasExistingAccountInfo] = useState(false);
  const errorRules = useRef(getErrorRules());

  const fetchOptions = {
    retries: 1,
    retryOn: handleShouldRetry,
  };
  const pendingRetry = useRef(null);

  useEffect(() => {
    errorRules.current = getErrorRules();
  }, [hasExistingAccountInfo]);

  return (
    <FetchOptions options={fetchOptions}>
      <AuthResponseHandlerContext.Provider value={getContextValue()}>
        {children}
      </AuthResponseHandlerContext.Provider>
    </FetchOptions>
  );

  function getContextValue() {
    return {
      activeForm,
      pendingRetry: pendingRetry.current,
      setHasExistingAccountInfo,
      shouldCleanUpSession,
    };
  }

  async function handleShouldRetry(retryOps) {
    const { attempt, response } = retryOps;
    const errorCode = await getErrorCode(response);
    const matchingAction = errorRules.current.find(({ shouldHandle }) =>
      shouldHandle(errorCode)
    )?.action;
    let retry = false;

    if (matchingAction && attempt < 1) {
      retry = true;

      try {
        if (!pendingRetry.current) {
          pendingRetry.current = matchingAction(response?.url);
        }

        await pendingRetry.current.pendingAction;
      } catch (error) {
        retry = false;
      } finally {
        setActiveForm(null);
        pendingRetry.current = null;
      }
    }

    return retry;
  }

  function getErrorRules() {
    const { errorsTriggeringRelogin, errorsTriggeringRetry, errorsTriggeringReauthentication } =
      getBootstrapData("api-gateway-errors") ?? {};

    return [
      {
        action: displayReAuthDialogAsync,
        shouldHandle: matchesOneOf(errorsTriggeringReauthentication),
      },
      {
        action: hasExistingAccountInfo ? displayReAuthDialogAsync : displayLoginDialogAsync,
        shouldHandle: matchesOneOf(errorsTriggeringRelogin),
      },
      {
        action: cleanUpSessionAsync,
        shouldHandle: matchesOneOf(errorsTriggeringRetry),
      },
    ];
  }

  async function getErrorCode(response) {
    return isResponseOk(response) ? null : (await getJson())?.code;

    async function getJson() {
      let json;

      try {
        json = await response.clone().json();
      } catch (e) {
        json = {};
      }

      return json;
    }
  }

  function matchesOneOf(errorCodes) {
    return (errorCode) => errorCodes?.some((code) => code === errorCode);
  }

  function displayReAuthDialogAsync(url) {
    const newRetryState = {};

    newRetryState.pendingAction = new Promise((resolve, reject) => {
      newRetryState.next = resolve;
      newRetryState.cancel = reject;
      newRetryState.url = url;
      setActiveForm({ type: RE_AUTHENTICATION_FORM_TYPE });
    });

    return newRetryState;
  }

  function displayLoginDialogAsync(url) {
    const newRetryState = {};

    newRetryState.pendingAction = new Promise((resolve, reject) => {
      newRetryState.next = resolve;
      newRetryState.cancel = reject;
      newRetryState.url = url;
      setActiveForm({ type: LOGIN_FORM_TYPE });
    });

    return newRetryState;
  }

  function cleanUpSessionAsync() {
    setShouldCleanUpSession(true);

    return {
      pendingAction: Promise.resolve(),
    };
  }
};

AuthResponseHandlerContext.displayName = "AuthResponseHandlerContext";
