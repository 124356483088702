import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import { classNames } from "@swa-ui/string";

import { Icon } from "../Icon";
import styles from "./Calendar.module.scss";
import { Month } from "./Month";

const DELAY_FOR_RENDER_WITH_NEW_PROPS = 150;
const DATESELECTOR_DISABLED_DESIGN_TOKEN = "cmp-core-color-date-selector-disabled";
const DATESELECTOR_MONTH_NAVIGATION_DESIGN_TOKEN = "cmp-core-color-date-selector-month-navigation";

/**
 * Component to display calender months. Currently not intended to be used outside of DateSelector.
 */

export const Calendar = (props) => {
  const {
    className,
    dateEnd,
    dateStart,
    daysOfWeekInitials,
    focusField,
    lastBookableDate,
    numberCalendars,
    numberDates,
    onClick,
  } = props;
  const [forceUpdate, setForceUpdate] = useState(false);
  const [hoverDate, setHoverDate] = useState();
  const [lastDirectionChange, setLastDirectionChange] = useState("next");
  const visibleMonthYearRef = useRef();

  useEffect(() => {
    visibleMonthYearRef.current = undefined;
    setTimeout(() => {
      setForceUpdate(!forceUpdate);
    }, DELAY_FOR_RENDER_WITH_NEW_PROPS);
  }, [dateEnd, dateStart]);

  return (
    <div className={classNames(className, styles.calendar)}>
      <div {...getPreviousMonthProps()}>
        <Icon {...getPreviousMonthIconProps()} />
      </div>
      <div className={styles.monthContainer}>
        <Month {...getMonthProps(0)} />
        {numberCalendars === 2 && <Month {...getMonthProps(1)} />}
      </div>
      <div {...getNextMonthProps()}>
        <Icon {...getNextMonthIconProps()} />
      </div>
    </div>
  );

  function getPreviousMonthProps() {
    return {
      className: classNames(styles.previousOption, {
        [styles.disabled]: !isPreviousMonthViewable(),
      }),
      onMouseDown: handlePreviousMouseDown,
    };
  }

  function getPreviousMonthIconProps() {
    return {
      actions: ["rotate270"],
      color: isPreviousMonthViewable()
        ? DATESELECTOR_MONTH_NAVIGATION_DESIGN_TOKEN
        : DATESELECTOR_DISABLED_DESIGN_TOKEN,
      name: "ArrowThin",
    };
  }

  function getNextMonthProps() {
    return {
      className: classNames(styles.nextOption, {
        [styles.disabled]: !isNextMonthViewable(),
      }),
      onMouseDown: handleNextMouseDown,
    };
  }

  function getNextMonthIconProps() {
    return {
      actions: ["rotate90"],
      color: isNextMonthViewable()
        ? DATESELECTOR_MONTH_NAVIGATION_DESIGN_TOKEN
        : DATESELECTOR_DISABLED_DESIGN_TOKEN,
      name: "ArrowThin",
    };
  }

  function getMonthProps(calendarIndex) {
    let month;
    let year;

    if (getVisibleMonthYearRef()) {
      ({ month, year } = getVisibleMonthYearRef());
    } else {
      setupVisibleMonthYear();

      if (!visibleMonthYearRef.current) {
        ({ month, year } = getTodayMonthYear());
      } else {
        ({ month, year } = getVisibleMonthYearRef());
      }
    }

    if (calendarIndex === 1) {
      ({ month, year } = getNextMonth({ month, year }));
    }

    return {
      className: getMonthClass(calendarIndex),
      dateEnd,
      dateStart,
      daysOfWeekInitials,
      directionChange: lastDirectionChange,
      focusField,
      hoverDate,
      lastBookableDate,
      minimumRows: 6,
      month,
      numberDates,
      onClick,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
      year,
    };
  }

  function getMonthClass(calendarIndex) {
    return classNames(styles.month, {
      [styles.separator]: calendarIndex === 0 && numberCalendars === 2,
    });
  }

  function handleNextMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();

    if (isNextMonthViewable()) {
      setVisibleMonthYearRef(getNextMonth(getVisibleMonthYearRef() || getTodayMonthYear()));
      setForceUpdate(!forceUpdate);
      setLastDirectionChange("next");
    }
  }

  function handlePreviousMouseDown(event) {
    event.preventDefault();
    event.stopPropagation();

    if (isPreviousMonthViewable()) {
      setVisibleMonthYearRef(getPreviousMonth(getVisibleMonthYearRef() || getTodayMonthYear()));
      setForceUpdate(!forceUpdate);
      setLastDirectionChange("previous");
    }
  }

  function handleMouseEnter(date) {
    setHoverDate(date);
  }

  function handleMouseLeave() {
    setHoverDate(undefined);
  }

  function setupVisibleMonthYear() {
    if (!focusField || focusField === "start") {
      if (isValid(dateStart)) {
        setupStartMonth();
      } else if (isValid(dateEnd)) {
        setupEndMonth();
      }
    } else {
      if (isValid(dateEnd)) {
        setupEndMonth();
      } else {
        if (isValid(dateStart)) {
          setupStartMonth();
        }
      }
    }
  }

  function isValid(date) {
    let valid = false;

    if (date?.length) {
      const { month, year } = getMonthYear(date);

      if (!isNaN(month) && !isNaN(year)) {
        valid = !isBeforeBookableDate({ month, year }) && !isAfterBookableDate({ month, year });
      }
    }

    return valid;
  }

  function setupStartMonth() {
    const { month, year } = getMonthYear(dateStart);

    if (numberCalendars === 2 && isSameLastBookableDate({ month, year })) {
      setVisibleMonthYearRef(getPreviousMonth({ month, year }));
    } else {
      setVisibleMonthYearRef({ month, year });
    }
  }

  function setupEndMonth() {
    const { month, year } = getMonthYear(dateEnd);

    if (numberCalendars === 1) {
      setVisibleMonthYearRef({ month, year });
    } else {
      const previous = getPreviousMonth({ month, year });

      if (isBeforeBookableDate({ month: previous.month, year: previous.year })) {
        setVisibleMonthYearRef({ month, year });
      } else {
        setVisibleMonthYearRef({ month: previous.month, year: previous.year });
      }
    }
  }

  function getVisibleMonthYearRef() {
    return visibleMonthYearRef.current;
  }

  function setVisibleMonthYearRef(monthYear) {
    return (visibleMonthYearRef.current = monthYear);
  }

  function isNextMonthViewable() {
    const { month, year } = getVisibleMonthYearRef()
      ? getVisibleMonthYearRef()
      : getTodayMonthYear();
    let workingDate = getNextMonth({ month, year });

    if (numberCalendars === 2) {
      workingDate = getNextMonth({
        month: workingDate.month,
        year: workingDate.year,
      });
    }

    return !isAfterBookableDate({
      month: workingDate.month,
      year: workingDate.year,
    });
  }

  function isPreviousMonthViewable() {
    const { month, year } = getVisibleMonthYearRef()
      ? getVisibleMonthYearRef()
      : getTodayMonthYear();
    const workingDate = getPreviousMonth({ month, year });

    return !isBeforeBookableDate({
      month: workingDate.month,
      year: workingDate.year,
    });
  }

  function getTodayMonthYear() {
    const todayDate = new Date();

    return {
      month: `${todayDate.getMonth() + 1}`,
      year: `${todayDate.getFullYear()}`,
    };
  }

  function getMonthYear(date) {
    const workingDate = new Date(date);

    return {
      month: zeroPad(workingDate.getMonth() + 1),
      year: `${workingDate.getFullYear()}`,
    };
  }

  function getNextMonth(workingMonthYear) {
    let { month, year } = workingMonthYear;

    month = parseInt(month);
    year = parseInt(year);

    if (month + 1 > 12) {
      month = 1;
      year += 1;
    } else {
      month += 1;
    }

    return {
      month: `${month}`,
      year: `${year}`,
    };
  }

  function getPreviousMonth(workingMonthYear) {
    let { month, year } = workingMonthYear;

    month = parseInt(month);
    year = parseInt(year);

    if (month <= 1) {
      month = 12;
      year -= 1;
    } else {
      month -= 1;
    }

    return {
      month: `${month}`,
      year: `${year}`,
    };
  }

  function zeroPad(value) {
    return `${value}`.padStart(2, "0");
  }

  function isBeforeBookableDate(workingMonthYear) {
    const workingDate = new Date(`${workingMonthYear.month}/1/${workingMonthYear.year}`);
    const first = new Date();
    const firstDate = new Date(`${first.getMonth() + 1}/1/${first.getFullYear()}`);

    return workingDate.getTime() < firstDate.getTime();
  }

  function isAfterBookableDate(workingMonthYear) {
    const workingDate = new Date(`${workingMonthYear.month}/1/${workingMonthYear.year}`);
    const last = new Date(lastBookableDate);
    const lastDate = new Date(`${last.getMonth() + 1}/1/${last.getFullYear()}`);

    return workingDate.getTime() > lastDate.getTime();
  }

  function isSameLastBookableDate(workingMonthYear) {
    const workingDate = new Date(`${workingMonthYear.month}/1/${workingMonthYear.year}`);
    const last = new Date(lastBookableDate);
    const lastDate = new Date(`${last.getMonth() + 1}/1/${last.getFullYear()}`);

    return workingDate.getTime() === lastDate.getTime();
  }
};

Calendar.propTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** End date if given should be in this format: MM/DD/YYYY (02/02/2020). */
  dateEnd: PropTypes.string,

  /** Start date if given should be in this format: MM/DD/YYYY (02/02/2020). */
  dateStart: PropTypes.string,

  /** Initial letters of each day of the week: Sunday, Monday. */
  daysOfWeekInitials: PropTypes.string,

  /** Indicates which date the traveler is selecting. */
  focusField: PropTypes.oneOf(["end", "start"]),

  /** Last date that can be booked. Should be in this format: MM/DD/YYYY. */
  lastBookableDate: PropTypes.string,

  /**
   * One or two calendars can be displayed at a time. This value is ignored for mobile devices as for phones, all
   * calendar months are displayed.
   */
  numberCalendars: PropTypes.number,

  /** One or two dates can be selected so the calendar will allow for start and end dates. */
  numberDates: PropTypes.number,

  /** Event handler to learn when an end date is selected. The event handler will be passed a date string. */
  onClick: PropTypes.func,
};

Calendar.defaultProps = {
  daysOfWeekInitials: "SMTWTFS",
  numberCalendars: 1,
};
