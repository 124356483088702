import { getBootstrapData } from "@swa-ui/bootstrap";
import { window } from "@swa-ui/browser";

import { fieldNames, separators } from "../defines";
import { getClosestPoints } from "../getClosestPoints";
import { getLocationDataSchema } from "../schema";

export const getGeolocation = () => {
  const geolocationObject = {};
  const locationDataSchema = getLocationDataSchema();
  const rawLocationData = getLocationDataFromWindow();

  if (rawLocationData) {
    const locationData = locationDataSchema.cast(rawLocationData);
    const nearestStation = getNearestStation(locationData);
    const inflightData = window?.swa?.inflight;

    geolocationObject.locationData =
      (locationData && { ...locationData, inflight: inflightData ?? false }) ?? {};
    geolocationObject.nearestStation = nearestStation?.id;
    geolocationObject.isInternationalStation = nearestStation?.internationalStation;
    geolocationObject.countryCode = inflightData ? "INFLIGHT" : nearestStation?.countryCode;
  }

  return geolocationObject;

  function getLocationDataFromWindow() {
    const locationDataFromWindow = window?.swa?.geolocation;
    const { LOCATION_DATA_FIELD_SEPARATOR, LOCATION_DATA_KEY_VALUE_SEPARATOR } = separators;

    return (
      locationDataFromWindow &&
      Object.fromEntries(
        locationDataFromWindow
          .split(LOCATION_DATA_FIELD_SEPARATOR)
          .map((geolocationAttribute) =>
            geolocationAttribute.split(LOCATION_DATA_KEY_VALUE_SEPARATOR)
          )
          .map(([geolocationKey, geolocationValue]) => [
            fieldNames[geolocationKey] ?? geolocationKey,
            geolocationValue,
          ])
      )
    );
  }

  function getNearestStation(locationData = {}) {
    const stationsData = getBootstrapData("air/stations-data");
    const closestStations = getClosestPoints(locationData, stationsData);
    const nearestStations = closestStations.sort(sortStationsBySeatShare);
    const nearestStationsInCountry = nearestStations.filter((station) =>
      filterStationByCountryCode(locationData, station)
    );

    return nearestStationsInCountry[0];
  }

  function filterStationByCountryCode(locationData, station) {
    return station?.countryCode === locationData?.country_code;
  }

  function sortStationsBySeatShare(stationA, stationB) {
    stringSort(stationA.weight, stationB.weight, stringSort(stationA.id, stationB.id));
  }

  function stringSort(stringA, stringB, equalityValue = 0) {
    return stringA < stringB ? -1 : stringA === stringB ? equalityValue : 1;
  }
};
