import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import colors from "../../defines/colors";

/**
 * Image of the back of a gift card or credit card.
 */

const SecurityCode = (props) => {
  const { className, fillColor, height, role, strokeColor, style, width } = props;

  return (
    <svg {...getProps()}>
      <rect fill="#fff" x="0.5" y="0.5" width="38" height="21" rx="2.5" />
      <path d="M36.67,0H2.33A2.33,2.33,0,0,0,0,2.33V19.67A2.33,2.33,0,0,0,2.33,22H36.67A2.33,2.33,0,0,0,39,19.67V2.33A2.33,2.33,0,0,0,36.67,0ZM38,19.67A1.34,1.34,0,0,1,36.67,21H2.33A1.34,1.34,0,0,1,1,19.67V18H38ZM38,16H1V2.33A1.34,1.34,0,0,1,2.33,1H36.67A1.34,1.34,0,0,1,38,2.33Z" />
      <path d="M4,12.8l.56-.8h.25l-.69,1,.72,1H4.55L4,13.14,3.38,14H3.13l.7-1-.7-1h.25Z" />
      <path d="M5.69,12.8l.57-.8h.25l-.69,1,.72,1H6.28l-.59-.82L5.11,14H4.86l.7-1-.7-1h.25Z" />
      <path d="M7.42,12.8,8,12h.25l-.69,1,.72,1H8l-.59-.82L6.84,14H6.58l.71-1-.71-1h.26Z" />
      <path d="M9.15,12.8l.57-.8H10l-.69,1L10,14H9.74l-.6-.82L8.57,14H8.31L9,13l-.71-1h.25Z" />
      <path d="M10.88,12.8l.57-.8h.25L11,13l.71,1h-.25l-.6-.82L10.3,14H10l.7-1L10,12h.25Z" />
      <path d="M12.6,12.8l.57-.8h.25l-.69,1,.72,1h-.26l-.59-.82L12,14h-.25l.7-1-.7-1H12Z" />
      <path d="M14.33,12.8l.57-.8h.25l-.69,1,.72,1h-.26l-.59-.82-.58.82H13.5l.7-1-.7-1h.25Z" />
      <path d="M16.06,12.8l.57-.8h.25l-.69,1,.71,1h-.25l-.59-.82-.58.82h-.26l.71-1-.71-1h.25Z" />
      <path d="M17.79,12.8l.57-.8h.25l-.69,1,.71,1h-.25l-.6-.82-.57.82H17l.7-1L17,12h.25Z" />
      <path d="M19.52,12.8l.56-.8h.26l-.69,1,.71,1h-.25l-.6-.82-.58.82h-.25l.7-1-.7-1h.25Z" />
      <path d="M21.24,12.8l.57-.8h.25l-.69,1,.72,1h-.26l-.59-.82-.58.82h-.25l.7-1-.7-1h.25Z" />
      <path d="M23,12.8l.57-.8h.25l-.69,1,.72,1h-.26L23,13.14l-.58.82h-.25l.7-1-.7-1h.25Z" />
      <path d="M24.7,12.8l.57-.8h.25l-.69,1,.71,1h-.25l-.59-.82-.58.82h-.26l.71-1-.71-1h.25Z" />
      <circle fill="#fff" cx="30" cy="16" r="9.5" />
      <path
        fill="#d5152e"
        d="M30,7a9,9,0,1,1-9,9,9,9,0,0,1,9-9m0-1A10,10,0,1,0,40,16,10,10,0,0,0,30,6Z"
      />
      <path d="M25.78,15.53l.57-.84H27L26.11,16,27,17.31h-.66l-.61-.86-.59.86h-.66L25.43,16l-.91-1.29h.66Z" />
      <path d="M28.6,15.53l.57-.84h.65L28.93,16l.92,1.31h-.66l-.61-.86-.59.86h-.66L28.25,16l-.91-1.29H28Z" />
      <path d="M31.42,15.53l.57-.84h.65L31.75,16l.92,1.31H32l-.61-.86-.59.86h-.66L31.07,16l-.91-1.29h.66Z" />
      <path d="M34.23,15.53l.58-.84h.65L34.57,16l.92,1.31h-.66l-.61-.86-.59.86H33L33.89,16,33,14.69h.66Z" />
    </svg>
  );

  function getProps() {
    return {
      "aria-label": props["aria-label"],
      className: classNames(className),
      fill: fillColor,
      height,
      role,
      stroke: strokeColor,
      style,
      viewBox: "0 0 40 26",
      width,
    };
  }
};

SecurityCode.propTypes = {
  /** aria-label text to provide accessibility description for this SVG element. */
  "aria-label": PropTypes.string,

  /**
   * Additional classes for positioning the component. Given classes may only position this component for layout
   * purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** Color name from color palette or name of an entry in color theme file, themes.js. */
  fillColor: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(colors)), PropTypes.string]),

  /** Indicates SVG's height. The width, if not given, will be sized according to SVG's aspect ratio. */
  height: PropTypes.string,

  /** Name used for aria role. */
  role: PropTypes.string,

  /** Color name from color palette or name of an entry in color theme file, themes.js. */
  strokeColor: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(colors)), PropTypes.string]),

  /** Indicates SVG's width. The height, if not given, will be sized according to SVG's aspect ratio. */
  width: PropTypes.string,
};

SecurityCode.defaultProps = {
  fillColor: colors["blue-1"],
  height: "32",
  role: "img",
};

export { SecurityCode };
