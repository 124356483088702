import PropTypes from "prop-types";
import React from "react";

import { classNames } from "@swa-ui/string";

import colors from "../defines/colors";
import { Fade, fadePropTypes } from "../Fade";
import { getColorVariable } from "../getColorVariable";
import styles from "./FadingDot.module.scss";

/**
 * The FadingDot component shows a fading circle on the page that last less than a second. FadingDot
 * requires the css position property of its parent be set to: absolute, fixed, relative or sticky.
 */
export const FadingDot = React.memo((props) => {
  const { className, color, delay, duration, focalPoint, fullWidth, onTransformationEnd, to } =
    props;

  return (
    <Fade {...getProps()}>
      <div className={styles.dot} style={{ backgroundColor: getColorVariable(color) }} />
    </Fade>
  );

  function getProps() {
    return {
      className: classNames(className, styles.container),
      delay,
      duration,
      focalPoint,
      fullWidth,
      onTransformationEnd,
      to,
    };
  }
});

export const fadingDotPropTypes = {
  /**
   * Additional classes for positioning the component. Given classes may only position this component
   *  for layout purposes, and cannot change how the component renders in any way.
   */
  className: PropTypes.string,

  /** The dot can use any of the predefined colors in the SWA palette. */
  color: PropTypes.oneOfType([PropTypes.oneOf(Object.keys(colors)), PropTypes.string]),

  /** Milliseconds value to defer the dot's animation start. */
  delay: fadePropTypes.delay,

  /** Length of animation in milliseconds. See Transform for details. */
  duration: fadePropTypes.duration,

  /** Position where center of child will render in pixels. */
  focalPoint: fadePropTypes.focalPoint,

  /**
   * By default, the dot will be sized to 6rem, but instead, the application can indicate the dot
   * fill its container.
   */
  fullWidth: PropTypes.bool,

  /** Callback that will be called when fade animation is complete. No arguments will be returned. */
  onTransformationEnd: fadePropTypes.onTransformationEnd,

  /**
   * Type of animation to be preformed. These "actions" can update the child content's opacity and
   * scale.
   */
  to: fadePropTypes.to,
};

FadingDot.displayName = "FadingDot";
FadingDot.propTypes = fadingDotPropTypes;
FadingDot.defaultProps = {
  color: "blue-1",
  fullWidth: false,
};
